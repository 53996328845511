// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./ExtraIdentityTabInAddUser.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getAsyncAllIdentityAttributeWithOptions,
  userIdentitiesSlice,
} from "../../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Redux

// Models
import { CustomFormData } from "../../../../Models/types";
// Models

// Utils
import CustomInput2 from "../../../../Utils/CustomInput2/CustomInput2";
// Utils

// Urls
import { mainUrl } from "../../../../Services/urls";
// Urls

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { UserGroupsContext } from "../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { addNewAsyncUser } from "../../../../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
import { Spinner } from "reactstrap";
import { permitionCheckerWithoutToast } from "../../../../Modules/permitionCheckerWithoutToast";
// Contexts

type ExtraIdentityTabInAddUserProps = {
  setNewUserData: React.Dispatch<
    React.SetStateAction<{
      tenantId: string;
      firstname: string;
      lastname: string;
      email: string;
      username: string;
      password: string;
      retypePassword?: string;
      extraIdentityInformation: {
        [key: string]: any;
      };
      groupMembershipToAdd: {
        groupId: string;
      }[];
    }>
  >;

  userData: {
    tenantId: string;
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    password: string;
    retypePassword?: string;
    extraIdentityInformation: {
      [key: string]: any;
    };
    groupMembershipToAdd: {
      groupId: string;
    }[];
  };

  groupsForChecking: string[];
};

const ExtraIdentityTabInAddUser: React.FunctionComponent<
  ExtraIdentityTabInAddUserProps
> = ({ setNewUserData, userData, groupsForChecking }) => {
  const dispatch = useAppDispatch();

  const isNightModeOn = useContext(IsNightModeOnContext);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const { currLanguage } = useAppSelector((state) => state.currLanguage);

  const [additionalFormData, setAdditionalFormData] = useState<CustomFormData>(
    {}
  );

  const userIdentities = useAppSelector(
    (state) => state.userIdentities.userIdentitiesWithOptions
  );

  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const { isUserAddingPending } = useAppSelector(
    (state) => state.accessibleUsers
  );

  useEffect(() => {
    dispatch(
      getAsyncAllIdentityAttributeWithOptions({
        userToken,
        tenantId: selectedTenant.tenantId,
      })
    );
  }, [dispatch, userToken, selectedTenant]);

  useEffect(() => {
    const identityKeys = userIdentities.data.map((item) => item.key);
    const resultObject: CustomFormData = {};

    for (let i = 0; i <= identityKeys.length - 1; i++) {
      const selcetedItem = userIdentities.data.find(
        (item) => item.key === identityKeys[i]
      );
      resultObject[String(identityKeys[i])] = {
        type: String(selcetedItem?.type?.value),
        persianName: String(selcetedItem?.title),
        value: "",
        objectName: identityKeys[i],
        otherOptions: selcetedItem?.identityOptions
          ? selcetedItem?.identityOptions?.map((item) => ({
              id: String(item?.id),
              title: String(item?.title),
            }))
          : [],
        helperData: new Blob(),
        isTouched: false,
        ref: React.createRef(),
      };
    }

    setAdditionalFormData(resultObject);
  }, [userIdentities]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetUserIdentitiesWithOptions());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.extraIdentityOfAddingUserContainer} ${
        isNightModeOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center`}
    >
      <div
        className="w-100 d-flex flex-row align-items-center justify-content-between flex-wrap"
        style={{
          direction: currLanguage === "fa" ? "rtl" : "ltr",
        }}
      >
        {(() => {
          const keys = Object.keys(additionalFormData);
          return keys.map((item) => {
            const selectedItem = additionalFormData[item];
            if (selectedItem.type === "IMAGE") {
              return (
                <React.Fragment key={selectedItem.objectName}>
                  <CustomInput2
                    onChange={(e) => {
                      if (!e.target.files) return;
                      const fileReader = new FileReader();

                      setAdditionalFormData((prevState) => ({
                        ...prevState,
                        [item]: {
                          ...prevState[item],
                          helperData: e.target.files
                            ? e.target.files[0]
                            : prevState[item].helperData,
                          isTouched: true,
                        },
                      }));
                      fileReader.onload = () => {
                        setAdditionalFormData((prevState) => ({
                          ...prevState,
                          [item]: {
                            ...prevState[item],
                            value: fileReader.result,
                            isTouched: true,
                          },
                        }));
                      };
                      fileReader.readAsDataURL(e.target?.files[0]);
                    }}
                    plaeHolder={selectedItem.persianName}
                    title={selectedItem.persianName}
                    type="file"
                    containerClassName="mt-2 "
                    imageRenderingOptions={{
                      src: selectedItem.isTouched
                        ? URL.createObjectURL(selectedItem.helperData)
                        : `${mainUrl}${selectedItem.value}`,
                      buttonForChangeOnClick: () => {
                        if (!selectedItem.ref.current) return;
                        selectedItem.ref.current.click();
                      },
                      buttonInnerText: `${selectedItem.persianName} تعویض عکس `,
                    }}
                    fileInputReference={selectedItem.ref}
                    containerStyle={{
                      direction: currLanguage !== "fa" ? "rtl" : "ltr",
                    }}
                  />
                </React.Fragment>
              );
            } else if (
              selectedItem.type === "TEXT" ||
              selectedItem.type === "DOUBLE" ||
              selectedItem.type === "INTEGER"
            ) {
              return (
                <React.Fragment key={selectedItem.objectName}>
                  <CustomInput2
                    onChange={(e) => {
                      setAdditionalFormData((prevState) => ({
                        ...prevState,
                        [item]: {
                          ...prevState[item],
                          isTouched: true,
                          value: e.target.value,
                        },
                      }));
                    }}
                    defaultValue={selectedItem.value}
                    plaeHolder={selectedItem.persianName}
                    title={selectedItem.persianName}
                    type="text"
                    containerClassName="mt-2"
                  />
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={selectedItem.objectName}></React.Fragment>
            );
          });
        })()}
      </div>

      <button
        className="operationEvent submitBtn align-self-start mt-5"
        onClick={() => {
          setNewUserData((prevState) => ({
            ...prevState,
            extraIdentityInformation: additionalFormData,
          }));
          if (
            !permitionCheckerWithoutToast(
              allGroups,
              [
                "Full_Manager",
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "User_Editor",
              ],
              isFullRenderd
            )
          )
            return;

          if (userData.password !== userData.retypePassword) {
            customizedToast("رمز عبور و تکرار آن را بررسی کنید", "error");
            return;
          }

          dispatch(
            addNewAsyncUser({
              _data: {
                email: userData.email,
                firstname: userData.firstname,
                lastname: userData.lastname,
                password: userData.password,
                extraIdentityInformation: (() => {
                  const keys = Object.keys(additionalFormData);
                  let result: { [key: string]: any } = {};
                  for (let i = 0; i < keys.length; i++) {
                    if (additionalFormData[keys[i]].isTouched) {
                      result[additionalFormData[keys[i]].objectName] =
                        additionalFormData[keys[i]].value;
                    }
                  }
                  return result;
                })(),
                groupMembershipToAdd: groupsForChecking.map((item) => ({
                  groupId: item,
                })),
                tenantId: userData.tenantId,
                username: userData.username,
              },
              userToken,
            })
          );
        }}
      >
        {isUserAddingPending ? (
          <>
            <Spinner style={{ width: "16px", height: "16px" }} />
          </>
        ) : (
          <>ثبت کاربر جدید</>
        )}
      </button>
    </div>
  );
};

export default ExtraIdentityTabInAddUser;
