// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./StepsTab.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getAsyncSingleActivity,
  processInstanceHistorySlice,
} from "../../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
// Redux

// Utils
import PendingManager from "../../../PendingManager/PendingManager";
import Pagination from "../../../../Utils/Pagination/Pagination";
import { copyToClipboard } from "../../../../Utils/copyToClipboard";
// Utils

// Models
import {
  I_SingleActivity,
  I_SingleActivityContent,
} from "../../../../Models/interfaces";
// Models

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { SetProcessHistoryContext } from "../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Contexts

// Models
import { T_TableTab, tabs } from "../StepsAndHistoryViewer";
// Models

type StepsTabProps = {
  instanceId: string;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
  selectedStep: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<T_TableTab>>;
};

const StepsTab: React.FunctionComponent<StepsTabProps> = ({
  instanceId,
  setSelectedStep,
  selectedStep,
  setSelectedTab,
}) => {
  const dispatch = useAppDispatch();

  const isNightOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const { isDone, isError, isPending } = useAppSelector(
    (state) => state.processInstanceHistorySlice.activitySteps
  );

  const [allSteps, setAllSteps] = useState<I_SingleActivityContent[]>([]);

  const [paginationOptions, setPaginationOptions] = useState<{
    size: number;
    page: number;
  }>({
    size: 5,
    page: 1,
  });

  const setProcessHistoryData = useContext(SetProcessHistoryContext);

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncSingleActivity({
        userToken,
        _data: {
          processInstanceId: instanceId,
          size: paginationOptions.size,
          page: paginationOptions.page,
          activitySchemaId: selectedStep,
          onBeforeRequest: () => {
            dispatch(processInstanceHistorySlice.actions.resetActivitySteps());
          },
          onDone: (data: any) => {
            const __data: I_SingleActivity = data;
            setProcessHistoryData((prevState) => ({
              ...prevState,
              allSteps: __data.content.map((_d) => ({
                ..._d,
                activities: [
                  {
                    activityType: _d.activityType,
                    schemaId: _d.schemaId,
                    title: _d.title,
                  },
                ],
              })),
            }));
            setAllSteps(
              [...__data.content].sort((a, b) => a.stepNumber - b.stepNumber)
            );

            if (__data.content.length === 1) {
              // customizedToast(
              //   "فقط 1 استپ وجود دارد و شما به سمت آن هدایت میشوید",
              //   "info"
              // );
              const schemaId = __data.content[0].schemaId;
              console.log(schemaId);
              console.log(selectedStep);
              setSelectedStep(schemaId);
              setSelectedTab(tabs.find((_i) => _i.name === "History")!);
            }
          },
        },
      })
    );
  }, [
    dispatch,
    userToken,
    instanceId,
    paginationOptions,
    selectedStep,
    setProcessHistoryData,
    setSelectedTab,
    setSelectedStep,
  ]);

  useEffect(() => {
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter]);

  useEffect(() => {
    return () => {
      dispatch(processInstanceHistorySlice.actions.resetStepsTab());
    };
  }, [dispatch]);

  return (
    <div className={`${styles.stepsTabContainer} w-100`}>
      <PendingManager
        isDone={isDone}
        isError={isError}
        isPending={isPending}
        onError={dataGetter}
        showAfterDone={
          <>
            <div
              className={`${styles.myTable} ${
                isNightOn ? "nightBg2 pcNight" : ""
              }  w-100 d-flex mt-3 flex-column align-items-center align-items-center`}
            >
              <div
                className={`${styles.tableHead} w-100 d-flex flex-row-reverse align-items-center justify-content-between align-items-center`}
              >
                <p className={`${styles.stepIndex}`}>شماره مرحله</p>
                <p className={`${styles.stepName}`}>نام مرحله</p>
                <p className={`${styles.activityType}`}>نوع فعالیت</p>
                <p className={`${styles.schemaId}`}>کد ساختار</p>
                <p className={`${styles.status}`}>وضعیت </p>
              </div>
              <div
                className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
              >
                {allSteps.length === 0 ? (
                  <p className="w-100 d-flex flex-row align-items-center p-0 m-0 mt-2 justify-content-center">
                    موردی وجود ندارد
                  </p>
                ) : (
                  <>
                    {allSteps.map((item) => (
                      <div
                        key={item.schemaId}
                        className={`${styles.tableData} w-100 d-flex ${
                          item.schemaId?.length > 1 ? "pointer" : ""
                        } flex-row-reverse ${
                          selectedStep === item.schemaId
                            ? styles.activeStep
                            : ""
                        } align-items-center justify-content-between align-items-center`}
                        onClick={() => {
                          const schemaId = item.schemaId;
                          console.clear();
                          console.log(schemaId);
                          console.log(selectedStep);
                          setSelectedStep(schemaId);
                          setSelectedTab(
                            tabs.find((_i) => _i.name === "History")!
                          );
                        }}
                      >
                        <p className={`${styles.stepIndex}`}>
                          {item.stepNumber}
                        </p>
                        <p className={`${styles.stepName}`}>{item?.title}</p>
                        <p className={`${styles.activityType}`}>
                          {item?.activityType?.title}
                        </p>
                        <p
                          className={`${styles.schemaId}`}
                          onDoubleClick={() => {
                            copyToClipboard(item?.schemaId);
                          }}
                        >
                          {item?.schemaId}
                        </p>
                        <p className={`${styles.status}`}>
                          {item.finished ? "خاتمه یافته" : "درحال اجرا"}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className={`${styles.tableFooter}`}></div>
            </div>
            <Pagination
              dataForPagination={{
                currPage: 1,
                size: paginationOptions.size,
                totalPages: 1,
              }}
              onPageNumberChange={(page: number) => {
                setPaginationOptions((prevState) => ({
                  ...prevState,
                  page,
                }));
              }}
              onPageNumbersClick={(page: number) => {
                setPaginationOptions((prevState) => ({
                  ...prevState,
                  page,
                }));
              }}
              onPageSizeChange={(size: number) => {
                setPaginationOptions((prevState) => ({
                  ...prevState,
                  size,
                }));
              }}
              search={""}
              contaienrClassName="w-100"
            />
          </>
        }
      />
    </div>
  );
};

export default StepsTab;
