// React
import React, { useCallback, useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./HamburgerMenu.module.css";
// CSS

// Contexts
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Modules
import { NavLink } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Modules

// Icons
import { separator } from "../../Images/images";
// Icons

// Hooks
import useSidebarItems from "../../Hooks/SidebarHooks/useSidebarItems";
import { useAppSelector } from "../../app/store";
// Hooks

type HamburgerMenuProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HamburgerMenu: React.FunctionComponent<HamburgerMenuProps> = ({
  setIsOpen,
}) => {
  const { currLanguage } = useAppSelector((state) => state.currLanguage);

  const innerRef = useRef<HTMLDivElement>(null);

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const {
    selectedTenant: { tenantTitle, tenantImage },
  } = useAppSelector((state) => state.tenants);

  const sidebarItems = useSidebarItems(currLanguage);

  const eventFunction = useCallback(() => {
    if (!innerRef.current) return;
    innerRef.current.style.opacity = "0";

    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  }, [setIsOpen]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!innerRef.current) return;
      innerRef.current.style.opacity = "1";
      document.addEventListener("click", eventFunction);
    }, 10);

    return () => {
      clearTimeout(timeOut);
    };
  }, [eventFunction]);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", eventFunction);
    };
  }, [eventFunction]);

  return (
    <div
      className={`${styles.hamburgerMenuContainer} ${
        isNightModeOn ? "nightBg2" : ""
      }`}
      ref={innerRef}
    >
      <div className={`${styles.logoContainer} `}>
        <img src={tenantImage} alt="" />
        <span
          style={{
            fontSize: "20px",
          }}
          className={`${styles.samfonaName}`}
        >
          سمفونا
        </span>
      </div>

      <p className={`${styles.tenantTitle}`}>{tenantTitle}</p>

      <img src={separator} alt="" className={`${styles.seprator}`} />

      {sidebarItems
        .filter((item) => {
          return permitionCheckerWithoutToast(
            allGroups,
            item.requierdPerimitions,
            isFullRenderd
          );
        })
        .map((item) => (
          <NavLink
            to={item.path}
            key={item.path}
            className={`${styles.sidebarItem}`}
            style={({ isActive }) => ({
              background: isActive
                ? isNightModeOn
                  ? "#606060"
                  : "#F6F9FC"
                : "transparent",
            })}
            onClick={eventFunction}
          >
            {item.logo}
            <span>{item.title}</span>
          </NavLink>
        ))}
    </div>
  );
};

export default HamburgerMenu;
