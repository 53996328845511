// React
import React from "react";
// React

// CSS
import styles from "./UserIdentities.module.css";
// CSS

// Components
import FullUserAttributes from "./FullUserAttributes/FullUserAttributes";
// Components

const UserIdentities = () => {
  return (
    <div className={`${styles.userIdentitiesContainer}`}>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <FullUserAttributes />
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
    </div>
  );
};

export default UserIdentities;
