// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./SubmitStartingForm.module.css";
// CSS

// Utils
import { Spinner } from "reactstrap";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { processesSlice } from "../../../Features/ProcessesSlice/ProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// FormIo
import { Form } from "@formio/react";
// FormIo

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Modals
import ProcessStartModal from "../../Forms/ProcessStartModal/ProcessStartModal";
// Modals

// Urls
import { baseUrl, mainUrl } from "../../../Services/urls";
// Urls

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Hooks
import usePersianDatePickerEventHandler from "../../../Hooks/usePersianDatePickerEventHandler";
import useReRenderMap from "../../../Hooks/useReRenderMap";
// Hooks

// Themes
import { themeColor } from "../../../Constants/constants";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { isCookieMode } from "../../../Services/Base_Axios_Config/_axios";
// Themes

const SubmitStartingForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const startingForm = useAppSelector((state) => state.processes.startingForm);
  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [form, setForm] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  console.log(setFormData);
  const dataRef = useRef<any>();
  const [isFormPending, setIsFormPending] = useState<boolean>(true);
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);

  const isNightOn = useContext(IsNightModeOnContext);

  const { processPage } = useAppSelector((state) => state.searchAndBack);

  useEffect(() => {
    fetch(
      `${baseUrl}/processes/${params.processKey}/starting-form?tenant=${
        params.tenant ? params.tenant : ""
      }`,
      {
        method: "GET",
        headers: isCookieMode
          ? {}
          : {
              Authorization: `Bearer ${userToken}`,
            },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        fetch(`${mainUrl}${res.result.url}`)
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            setForm({ display: "form", components: res.components });
          })
          .then(() => setIsFormPending(false))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        customizedToast("در فرآیند بارگذاری فرم مشکلی به پیش آمده", "error");
        customizedToast("مجدد تلاش کنید", "error");
        navigate(
          `/processes?page=${processPage.pageNumber}&size=${processPage.size}`
        );
        console.log(err);
      });
  }, [userToken, params, navigate, processPage]);

  useEffect(() => {
    if (startingForm.isDone) {
      navigate(
        `/processes?page=${processPage.pageNumber}&size=${processPage.size}`
      );
      return;
    }

    return () => {
      dispatch(processesSlice.actions.resetSendingFormStatus());
      dispatch(processesSlice.actions.resetIsAddingProcessDone());
    };
  }, [startingForm.isDone, navigate, dispatch, processPage]);

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("ارسال فرم شروع"));
  }, [dispatch]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  usePersianDatePickerEventHandler(isFormPending);

  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormPending,
  });

  useMapRenderInLittleModal(isFormPending);

  return (
    <div
      className={`${styles.submitFormContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100`}
    >
      {isModalInScreen ? (
        <ProcessStartModal
          formsData={dataRef.current.data}
          processKey={String(params.processKey)}
          setIsModalInScreen={setIsModalInScreen}
          tenant={selectedTenant.tenantId}
          formIoData={dataRef.current.data}
          persianName={
            params.persianProcessName || params.persianProcessKey || "بدون نام"
          }
        />
      ) : null}
      <p
        className="w-100 "
        style={{
          textAlign: "right",
          fontSize: "20px",
        }}
      >
        برای شروع فرآیند
        <span style={{ color: themeColor, fontSize: "20px" }}>
          &nbsp; {`< ${params.persianProcessName} >`} &nbsp;
        </span>
        فرم زیر را تکمیل کنید
      </p>
      <p
        className="w-100 "
        style={{
          textAlign: "right",
          fontSize: "20px",
        }}
      >
        وضعیت : &nbsp;
        {isFormPending ? (
          <> بارگذاری فرم </>
        ) : (
          <>
            {!startingForm.isDone ? (
              <span style={{ color: themeColor, fontSize: "20px" }}>
                هنوز ارسال نشده
              </span>
            ) : (
              <span style={{ color: themeColor, fontSize: "20px" }}>
                ارسال شده
              </span>
            )}
          </>
        )}
      </p>
      {isFormPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          <Form
            src={form}
            onError={() => {
              customizedToast("مشکلی در هنگام دریافت فرم به وجود آمد", "error");
            }}
            onChange={(data: any) => {
              dataRef.current = data;
            }}
            onFormLoad={() => {
              customizedToast("فرم با موفقیت بارگذاری شد", "success");
            }}
            onSubmit={(data: any) => {
              console.log("submited !", dataRef.current.data.submit);
              console.log(dataRef.current.data.submit);
              setIsModalInScreen(true);
            }}
            onSubmitDone={() => {
              customizedToast("فرم ارسال شد", "info");
            }}
          />
        </>
      )}
    </div>
  );
};

export default SubmitStartingForm;
