// React
import React, { useContext, useEffect } from "react";
// React

// CSS
import styles from "./SingleCallOfRegistrationAttributes.module.css";

// CSS

// Modules
import { useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  callOfRegistrationSlice,
  getAsyncAllRelatedAttributesOfSingleCallOfRegistration,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
// Redux

// Utils
// Utils

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
// Icons

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import Pending from "../../../Components/Pending/Pending";
// Contexts

const SingleCallOfRegistrationAttributes = () => {
  const { callOfRegistrationId } = useParams();
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const singleCallOfRegistrationData = useAppSelector(
    (state) => state.callOfRegistration.singleCallOfRegistrationAttributes
  );

  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllRelatedAttributesOfSingleCallOfRegistration({
          _data: {
            callOfRegistrationId: String(callOfRegistrationId),
          },
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, callOfRegistrationId, userToken]);

  useEffect(() => {
    return () => {
      dispatch(
        callOfRegistrationSlice.actions.resetRecevedAttributesOfSingleCallOfRegistration()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${
        styles.singleCallOfRegistrationContainer
      } w-100 d-flex flex-column align-items-center ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <p className="w-100 d-flex flex-row align-items-center justify-content-end mb-4">
        شناسه ها برای فراخوان شماره {callOfRegistrationId}
      </p>
      {singleCallOfRegistrationData.isPending &&
      !singleCallOfRegistrationData.isError ? (
        <Pending className="mt-4" />
      ) : null}
      {!singleCallOfRegistrationData.isPending &&
      !singleCallOfRegistrationData.isError ? (
        <>
          {window.innerWidth > 1000 ? (
            <div
              className={`${styles.myTable} ${
                isNightOn ? "nightBg2 pcNight" : ""
              } w-100 d-flex flex-column align-items-center align-items-center`}
              style={{ direction: "rtl" }}
            >
              <div
                className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
              >
                <p className={`${styles.id}`}>شمارنده</p>
                <p className={`${styles.title}`}>نام</p>
                <p className={`${styles.type}`}>نوع</p>
                <p className={`${styles.required}`}>اجباری</p>
                <p className={`${styles.unique}`}>خاص</p>
                <p className={`${styles.searchable}`}>قابل جستجو</p>
                <p className={`${styles.min}`}> حداقل </p>
                <p className={`${styles.max}`}> حداکثر </p>
              </div>
              <div
                className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
              >
                {singleCallOfRegistrationData.data.map((item) => (
                  <div
                    key={item.id}
                    className={`${styles.tableData} w-100 d-flex flex-row align-items-center justify-content-between`}
                  >
                    <p className={`${styles.id}`}>{item.id}</p>
                    <p className={`${styles.title}`}>{item.title}</p>
                    <p className={`${styles.type}`}>{item.type.title}</p>
                    <p className={`${styles.required}`}>
                      {item.required ? (
                        <MdDone
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      ) : (
                        <BsDashLg
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      )}
                    </p>
                    <p className={`${styles.unique}`}>
                      {item.unique ? (
                        <MdDone
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      ) : (
                        <BsDashLg
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      )}
                    </p>
                    <p className={`${styles.searchable}`}>
                      {item.searchable ? (
                        <MdDone
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      ) : (
                        <BsDashLg
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      )}
                    </p>
                    <p
                      className={`${styles.min}`}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        String(item.minimumValue).length >= 14
                          ? String(item.minimumValue)
                          : ""
                      }
                    >
                      {String(item.minimumValue).length >= 14
                        ? "..." + String(item.minimumValue).substring(0, 13)
                        : String(item.minimumValue)}
                    </p>
                    <p
                      className={`${styles.max}`}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        String(item.maximumValue).length >= 14
                          ? String(item.maximumValue)
                          : ""
                      }
                    >
                      {String(item.maximumValue).length >= 14
                        ? "..." + String(item.maximumValue).substring(0, 13)
                        : String(item.maximumValue)}
                    </p>
                  </div>
                ))}
              </div>
              <div className={`${styles.tableFooter}`}></div>
            </div>
          ) : (
            <div
              className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
            >
              {singleCallOfRegistrationData.data.map((item) => (
                <div key={item.id} className="w-100">
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                  >
                    <p>{item.id}</p>
                    <p>شمارنده </p>
                  </div>
                  <div
                    className={`${styles.tableRow} ${
                      isNightOn ? "nightBg2" : ""
                    } w-100 d-flex align-items-center justify-content-between `}
                  >
                    <p>
                      <span>نام</span>
                      <span>{item.title}</span>
                    </p>
                    <p>
                      <span>نوع</span>
                      <span>{item?.type?.title || "تعریف نشده"}</span>
                    </p>
                    <p>
                      <span>اجباری</span>
                      <span>
                        {item.required ? (
                          <MdDone
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        ) : (
                          <BsDashLg
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        )}
                      </span>
                    </p>
                    <p>
                      <span>خاص</span>
                      <span>
                        {item.unique ? (
                          <MdDone
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        ) : (
                          <BsDashLg
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        )}
                      </span>
                    </p>
                    <p>
                      <span>قابل جستجو</span>
                      <span>
                        {item.searchable ? (
                          <MdDone
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        ) : (
                          <BsDashLg
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        )}
                      </span>
                    </p>
                    <p>
                      <span>حداکثر</span>
                      <span>{String(item.maximumValue)}</span>
                    </p>
                    <p>
                      <span>حداقل</span>
                      <span>{item.minimumValue}</span>
                    </p>
                    <p></p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : null}
      {!singleCallOfRegistrationData.isPending &&
      singleCallOfRegistrationData.isError ? (
        <button
          className={`${styles.loadingContainer} mt-4`}
          onClick={() => {
            dispatch(
              getAsyncAllRelatedAttributesOfSingleCallOfRegistration({
                _data: {
                  callOfRegistrationId: String(callOfRegistrationId),
                },
                userToken,
              })
            );
          }}
        >
          تلاش دوباره
        </button>
      ) : null}
    </div>
  );
};

export default SingleCallOfRegistrationAttributes;
