// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_FormSamplesRecevedData } from "../../Models/interfaces";
import {
  I_AddNewFormSampleSendingData,
  I_EditFormSampleSendingData,
  I_SendingDataForGettingFormsSample,
} from "../../Models/sendingDataSchema";
// Models

// Services
import { getAllFormSamplesService } from "../../Services/FormSamples/get/getAllFormSamplesService";
import { removeSingleFormSampleService } from "../../Services/FormSamples/post/removeSingleFormSampleService";
import { addNewFormSampleService } from "../../Services/FormSamples/post/addNewFormSampleService";
import { editSingleFormSampleService } from "../../Services/FormSamples/post/editSingleFormSampleService";
// Services

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Utils

const initialState: {
  formSamples: {
    data: I_FormSamplesRecevedData;
    isPending: boolean;
    isError: boolean;
  };
} & {
  removingFormSamples: {
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
  };
} & {
  addingFormSample: {
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
  };
} & {
  editFormSample: {
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
  };
} = {
  formSamples: {
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 1,
      totalElements: 1,
      totalPages: 1,
    },
    isPending: true,
    isError: false,
  },
  removingFormSamples: {
    isDone: false,
    isError: false,
    isPending: false,
  },
  addingFormSample: {
    isDone: false,
    isError: false,
    isPending: false,
  },
  editFormSample: {
    isDone: false,
    isError: false,
    isPending: false,
  },
};

export const getAsyncFormSamples = createAsyncThunk(
  "formSample/getAllFormSamples",
  async (_data: {
    userToken: string;
    _data: I_SendingDataForGettingFormsSample;
  }) => {
    try {
      const { data } = await getAllFormSamplesService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const removeAsyncSingleFormSample = createAsyncThunk(
  "formSample/removeSingleFormSample",
  async (_data: {
    userToken: string;
    _data: {
      formSampleId: string | number;
    };
  }) => {
    try {
      const { data } = await removeSingleFormSampleService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const addAsyncFormSample = createAsyncThunk(
  "formSample/addNewFormSample",
  async (_data: {
    userToken: string;
    _data: I_AddNewFormSampleSendingData;
  }) => {
    try {
      const { data } = await addNewFormSampleService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncSingleFormSample = createAsyncThunk(
  "formSample/editSingleFormSample",
  async (_data: {
    userToken: string;

    _data: {
      formSampleId: string;
      _data: I_EditFormSampleSendingData;
    };
  }) => {
    try {
      const { data } = await editSingleFormSampleService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const formSamplesSlice = createSlice({
  name: "formSample",
  initialState,
  reducers: {
    resetRecevedFormSamplesState: (state) => {
      state.formSamples = resetReferenceOfObject(initialState.formSamples);
      state.formSamples.isPending = true;
    },
    resetRemovingTemplates: (state) => {
      state.removingFormSamples.isPending = false;
      state.removingFormSamples.isDone = false;
      state.removingFormSamples.isError = false;
    },
    resetAddingFormSamplesState: (state) => {
      state.addingFormSample.isPending = false;
      state.addingFormSample.isDone = false;
      state.addingFormSample.isError = false;
    },
    resetEditingFormSampleState: (state) => {
      state.editFormSample.isPending = false;
      state.editFormSample.isDone = false;
      state.editFormSample.isError = false;
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    builder.addCase(getAsyncFormSamples.pending, (state, action) => {
      state.formSamples.isPending = true;
      state.formSamples.isError = false;
    });
    builder.addCase(getAsyncFormSamples.fulfilled, (state, action) => {
      state.formSamples.isPending = false;
      state.formSamples.isError = false;

      state.formSamples.data = action.payload!.result;
      for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
    });
    builder.addCase(getAsyncFormSamples.rejected, (state, action) => {
      state.formSamples.isPending = false;
      state.formSamples.isError = true;
    });
    //
    //
    //
    //
    //
    builder.addCase(removeAsyncSingleFormSample.pending, (state, action) => {
      state.removingFormSamples.isPending = true;
      state.removingFormSamples.isDone = false;
      state.removingFormSamples.isError = false;
    });
    builder.addCase(removeAsyncSingleFormSample.fulfilled, (state, action) => {
      state.removingFormSamples.isPending = false;
      state.removingFormSamples.isDone = true;
      state.removingFormSamples.isError = false;
      for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
    });
    builder.addCase(removeAsyncSingleFormSample.rejected, (state, action) => {
      state.removingFormSamples.isPending = false;
      state.removingFormSamples.isDone = false;
      state.removingFormSamples.isError = true;
    });
    //
    //
    //
    //
    //
    builder.addCase(addAsyncFormSample.pending, (state, action) => {
      state.addingFormSample.isPending = true;
      state.addingFormSample.isDone = false;
      state.addingFormSample.isError = false;
    });
    builder.addCase(addAsyncFormSample.fulfilled, (state, action) => {
      state.addingFormSample.isPending = false;
      state.addingFormSample.isDone = true;
      state.addingFormSample.isError = false;
      for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
    });
    builder.addCase(addAsyncFormSample.rejected, (state, action) => {
      state.addingFormSample.isPending = false;
      state.addingFormSample.isDone = false;
      state.addingFormSample.isError = true;
    });
    //
    //
    //
    //
    //
    builder.addCase(editAsyncSingleFormSample.pending, (state) => {
      state.editFormSample.isPending = true;
      state.editFormSample.isDone = false;
      state.editFormSample.isError = false;
    });
    builder.addCase(editAsyncSingleFormSample.fulfilled, (state, action) => {
      state.editFormSample.isPending = false;
      state.editFormSample.isDone = true;
      state.editFormSample.isError = false;
      for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
    });
    builder.addCase(editAsyncSingleFormSample.rejected, (state) => {
      state.editFormSample.isPending = false;
      state.editFormSample.isDone = false;
      state.editFormSample.isError = true;
    });
  },
});
