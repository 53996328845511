// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
import { AxiosResponse } from "axios";
// Axios

// Models
import { I_SendingRunningInstanceData } from "../../../Models/sendingDataSchema";
import { I_Message, I_ProcessInstaceData } from "../../../Models/interfaces";
// Models

export const getRunningInsatncesService = async (_data: {
  userToken: string;
  _data: I_SendingRunningInstanceData;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_ProcessInstaceData;
  }>
> => {
  return _axios.get(
    `/processes/running-instances?page=${_data._data.page}&size=${_data._data.size}&tenant=${_data._data.tenant}&processKey=${_data._data.processKey}&activeInstancesOnly=${_data._data.activeInstancesOnly}&rootInstancesOnly=${_data._data.rootInstancesOnly}&search=${_data._data.search}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
