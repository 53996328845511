// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./NewReport.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  addAsyncNewReport,
  reportManagementSlice,
} from "../../../Features/ReportManagementSlice/reportManagementSlice";
// Redux

// Models
import { I_AddingNewReportSendingData } from "../../../Models/sendingDataSchema";
// Models

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

const NewReport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { processKey, persianProcessName } = useParams();

  const isNightOn = useContext(IsNightModeOnContext);

  const { isPending, isDone } = useAppSelector(
    (state) => state.reportManagement.addingReportData
  );

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [formsData, setFormsData] = useState<I_AddingNewReportSendingData>({
    readonly: false,
    reportIdentifier: "",
    tenantId: selectedTenant.tenantId,
  });

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  useEffect(() => {
    if (!isDone) return;

    navigate(`/processes/reports/${processKey}/${persianProcessName}`);

    return () => {
      dispatch(reportManagementSlice.actions.resetAddingStatus());
    };
  }, [isDone, navigate, processKey, persianProcessName, dispatch]);

  return (
    <div
      className={`${styles.newReportContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-start `}
    >
      <div
        className={`${styles.topHeader} w-100 d-flex flex-row-reverse  align-align-items-center`}
      >
        <p className={`${styles.processListName}`}>افزودن گزارش</p>
        &nbsp;
        {" : "}
        به فرآیند
        <p style={{ color: "gray" }}> {persianProcessName} </p>
      </div>

      <div
        className={`${styles.newReportGeneratorContainer} w-100 d-flex flex-row align-items-center justify-content-between mt-3 flex-wrap-reverse`}
      >
        <CustomCheckbox
          isChecked={formsData.readonly}
          onClick={() => {
            setFormsData((prevState) => ({
              ...prevState,
              readonly: !prevState.readonly,
            }));
          }}
          onChange={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              readonly: !prevState.readonly,
            }));
          }}
          title="فقط خواندنی"
          containerClassName="w-100 d-flex justify-between mt-4"
          titleClassName={"mb-0"}
        />

        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={String(formsData.reportIdentifier)}
          defaultValue={String(formsData.reportIdentifier)}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              reportIdentifier: e.target.value,
            }));
          }}
          placeHolder="شناسه گزارش"
          //  keyForShowOnTop="شناسه گزارش"
          dontShowSearchIcon
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {
            return false;
          }}
          onChange={(e) => {
            return false;
          }}
          placeHolder="نام سازمان"
          //   keyForShowOnTop="نام سازمان"
          dontShowSearchIcon
          value={selectedTenant.tenantTitle}
          defaultValue={selectedTenant.tenantTitle}
          disabled
        />
      </div>

      <button
        onClick={() => {
          dispatch(
            addAsyncNewReport({
              _data: formsData,
              processKey: String(processKey),
              userToken,
            })
          );
        }}
        className="operationEvent submitBtn mt-3"
      >
        {isPending ? <Spinner /> : <>تایید</>}
      </button>
    </div>
  );
};

export default NewReport;
