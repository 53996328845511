export const mailformat = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

export const latinLetterWithoutSpaceFormat = /^[a-zA-Z0-9_]+$/;

export const recevedDataPattern = /^\d{4}\/\d{2}\/\d{2}$/;

export const dateFormat = /\b\d{4}\/\d{2}\/\d{2}\b/g;

export const formioDateFormat = /\b\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}\b/g;

export const formioDateFormat2 = /\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\b/g;

export const formioDateFormat3 =
  /\b\d{4}[-\/]\d{2}[-\/]\d{2}T\d{2}:\d{2}:\d{2}\b/g;

export const scriptFormat = "script";

export const mapFinderFormat = /\{[^{]*"data-is-map"[^}]*\}/g;

export const latLonFormat =
  /\{"lat"\s*:\s*"-?\d+(\.\d+)?",\s*"lon"\s*:\s*"-?\d+(\.\d+)?"\}/;

export const latLonFormat2 =
  /\{"lat":"(-?\d+(\.\d+)?)","lon":"(-?\d+(\.\d+)?)","isCircleActive":(true|false),"circleRadius":\d+\}/;

export const customMapComponentPattern = /\b(customMapComponent\w*)\b/g;

export const userPasswordPattern =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
