// React
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// React

// CSS
import styles from "./TaskFormForShowInTaskDetail.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
import {
  postAsyncTaskForm,
  taskSlice,
} from "../../../Features/TasksSlice/tasksSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Components
import { Form } from "@formio/react";
import { Spinner } from "reactstrap";
// Components

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Hooks
import usePersianDatePickerEventHandler from "../../../Hooks/usePersianDatePickerEventHandler";
import useReRenderMap from "../../../Hooks/useReRenderMap";
// Hooks

// Utils
import { convertToPersianDateFromGeorgianDateInString } from "../../../Utils/FormioUtils/convertToPersianDateFromGeorgianDateInString";
import { resetReferenceOfObject } from "../../../Utils/resetReferenceOfObject";
import { convertToGeorgianDateFormPersianDateInString } from "../../../Utils/FormioUtils/convertToGeorgianDateFormPersianDateInString";
import { useMapRenderInLittleModalInTasks } from "../../../Hooks/MapHooks/useMapRenderInLittleModalInTasks";
// Utils

type TaskFormAndSubmitTaskFormByTasksProps = {
  taskId: string | number;
  formUrl: string;
  containerClassName: string;
  _recevedFormData:
    | {}
    | {
        [key: number | string]:
          | string
          | {
              [key: number | string]: string | {};
            };
      }
    | any;
};

const TaskFormForShowInTaskDetail: React.FunctionComponent<
  TaskFormAndSubmitTaskFormByTasksProps
> = ({ formUrl, taskId, containerClassName, _recevedFormData }) => {
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState<any>({});
  const [isFormShowPending, setIsFormShowPending] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const formData = useRef<any>({
    data: {
      formData: {},
    },
  });

  const isNightModeOn = useContext(IsNightModeOnContext);

  const currFormStatus = useAppSelector(
    (state) => state.tasks.submitTaskForm.isFormSubmited
  );

  useEffect(() => {
    fetch(`${mainUrl}${formUrl}`)
      .then((res) => res.json())
      .then((data) => setForm(data))
      .then(() => setIsFormShowPending(false))
      .catch((err) => {
        console.log(err);
        setIsFormShowPending(false);
        setHasError(true);
      });
  }, [navigate, formUrl]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.changeCurrLocation("ارسال فرم شروع با وظیفه")
    );
  }, [dispatch]);

  useEffect(() => {
    if (!currFormStatus) return;
    navigate("/tasks");
    return () => {
      dispatch(taskSlice.actions.resetFormSubmitStatus());
    };
  }, [dispatch, navigate, currFormStatus]);

  usePersianDatePickerEventHandler(isFormShowPending);
  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormShowPending,
  });

  useMapRenderInLittleModalInTasks(isFormShowPending);

  const __form__ = useMemo(
    () => (
      <>
        {Object.keys(_recevedFormData || {}).length > 0 ? (
          <Form
            src={form}
            onChange={(data: any) => {
              formData.current = data;
            }}
            onFormLoad={() => {
              console.log("loaded");
            }}
            onSubmit={(data: any) => {
              Boolean(
                convertToGeorgianDateFormPersianDateInString(
                  formData.current.data
                )
              )
                ? dispatch(
                    postAsyncTaskForm({
                      userToken,
                      formData: JSON.parse(
                        convertToGeorgianDateFormPersianDateInString(
                          JSON.stringify(formData.current.data)
                        )
                      ),
                      taskId: String(taskId),
                    })
                  )
                : dispatch(
                    postAsyncTaskForm({
                      userToken,
                      formData: formData.current.data,
                      taskId: String(taskId),
                    })
                  );
            }}
            onError={() => {
              console.log("errrrrrrrrrrrrrrrrrr");
            }}
            submission={{
              data: Boolean(
                convertToPersianDateFromGeorgianDateInString(
                  resetReferenceOfObject(_recevedFormData)
                )
              )
                ? convertToPersianDateFromGeorgianDateInString(
                    resetReferenceOfObject(_recevedFormData)
                  )
                : _recevedFormData,
            }}
          />
        ) : (
          <Form
            src={form}
            onChange={(data: any) => {
              formData.current = data;
            }}
            onFormLoad={() => {
              console.log("loaded");
            }}
            onSubmit={(data: any) => {
              dispatch(
                postAsyncTaskForm({
                  formData: convertToGeorgianDateFormPersianDateInString(
                    formData.current.data
                  )
                    ? JSON.parse(
                        convertToGeorgianDateFormPersianDateInString(
                          JSON.stringify(formData.current.data)
                        )
                      )
                    : formData.current.data,
                  taskId: String(taskId),
                  userToken,
                })
              );
            }}
            onError={() => {
              console.log("errrrrrrrrrrrrrrrrrr");
            }}
          />
        )}
      </>
    ),
    [_recevedFormData, dispatch, form, taskId, userToken]
  );

  return (
    <div
      className={`${
        styles.taskFormFromTasksContainer
      } w-100 d-flex flex-column ${
        isNightModeOn ? "nightBg1" : ""
      } ${containerClassName}`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between flex-wrap">
        <p
          className=""
          style={{
            color: "#111D64",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          برای ادامه عملیات ، فرم زیر را تکمیل کنید
        </p>
        {/* <div className="d-flex flex-row align-items-center">
        <button
          className="operationEvent deleteBtn"
          onClick={() => {
            customizedToast(
              "درخواست دانلود اطلاعات فرم در حال پردازش میباشد",
              "info"
            );

            try {
              const data: { label: string; apiKey: string; value: string }[] =
                formioComponentsFlatter(form.components)
                  .map(
                    (item: any) =>
                      item.label && {
                        label: item?.label,
                        apiKey: item?.key,
                        value: (() => {
                          const flatern: any = objectFlatter(
                            formData?.current?.data
                          );
                          return flatern[item?.key];
                        })(),
                      }
                  )
                  .filter((item: any) => item)
                  .filter((item: any) => !!item?.value);

              downloadFormDataAsPDF("test", data);
              console.log(data);
            } catch (err) {
              customizedToast("مشکلی در پردازش فرم پیش آمده", "error");
            }
          }}
        >
          PDF
        </button>
          <button
            className="operationEvent relatedForms"
            onClick={() => {
              try {
                const csvString = JSON.stringify(
                  formData.current.data,
                  null,
                  4
                ).replace(/,/g, ";");
                const blob = new Blob([csvString], { type: "text/csv" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `${downloadCSVFromFormDataPrefixController(
                  String(taskId)
                )}.csv`;

                link.click();
                customizedToast("اطلاعات با موفقیت ذخیره شد", "success");
              } catch (err) {
                customizedToast(
                  "هنگام پردازش اطلاعات مشکلی به وجود آمده",
                  "error"
                );
              }
            }}
          >
            CSV
          </button>
        </div> */}
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {isFormShowPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>{hasError ? <>مشکلی در نمایش فرم پیش آمده</> : <>{__form__}</>}</>
      )}
    </div>
  );
};

export default TaskFormForShowInTaskDetail;
