import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const editSinglePostService = async (_data: {
  userToken: string;
  _data: {
    forSending: {
      placementType: string;
      active: boolean;
      data: string;
    };
    postId: string | number;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/posts/${_data._data.postId}/edit`,
    _data._data.forSending,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
