import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const changeEffectOfSingleSecurityEventService = async (_data: {
  userToken: string;
  _data: {
    eventIds: string[] | number[];
    effectType: string;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post("/security-events/change-effects", _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
