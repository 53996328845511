// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./ShowFormsByProcessKey.module.css";
// CSS

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CSVLink } from "react-csv";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  deleteAsyncForm,
  formsSlice,
  getAsyncForms,
} from "../../../Features/FormsSlice/FormsSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Components
import { permissionChecker } from "../../../Modules/permissionChecker";
// Components

// Utils
import Swal from "sweetalert2";
import { downloadAsJsonFile } from "../../../Utils/DownloadUtils/downloadAsJsonFile";
import Pagination from "../../../Utils/Pagination/Pagination";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Icons
import { AiOutlineEye } from "react-icons/ai";
import { LuFileJson } from "react-icons/lu";
// Icons

// Context
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Context

// Modules
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
// Modules

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Constants
import {
  downloadFormNamePreFix,
  themeColor,
} from "../../../Constants/constants";
import Pending from "../../../Components/Pending/Pending";
// Constants

const ShowFormsByProcessKey = () => {
  // constants
  const { processKey, persianProcessName } = useParams();
  const params = useAppSelector(
    (state) => state.searchAndBack.formsPage.showByProcessPage
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isFullRenderd = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const [searchParam, setSearchParam] = useSearchParams();
  // constants
  // localState
  const [formSearchData, setFormSearchData] = useState<{
    processKey: string;
    page: number;
    size: number;
    search: string;
    tenant: string;
    id: string;
  }>({
    processKey: String(processKey),
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    search: searchParam.get("search") || params.searchParm,
    tenant: selectedTenant.tenantId,
    id: "",
  });
  //
  // localState
  //   global states
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const forms = useAppSelector((state) => state.forms);
  const allGroups = useContext(UserGroupsContext);
  //   global states

  const language = useAppSelector((state) => state.currLanguage.language);

  //   Reports
  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "id",
      },
      {
        label: "نام فرم",
        key: "title",
      },
      {
        label: "کلید فرم",
        key: "_key",
      },
      {
        label: "کلید فرآیند",
        key: "_processKey",
      },
      {
        label: "آدرس فایل",
        key: "url",
      },
    ],
    data: forms.content.map((item, index) => ({
      id: item.id,
      title: item.title,
      _key: item.key,
      _processKey: item.processKey,
      url: item.url,
    })),
    filename: `form__${processKey}.csv`,
  };
  //   Reports

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("نمایش فرم"));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(formsSlice.actions.resetFormPending());
    };
  }, [dispatch]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !permissionChecker(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "Process_Form_Editor",
            "Process_Full_Manager",
          ],
          isFullRenderd
        )
      )
        return;
      dispatch(
        getAsyncForms({
          ...formSearchData,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [allGroups, dispatch, formSearchData, userToken, isFullRenderd]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setShowFormByProcessData({
        searchParm: formSearchData.search,
        tenantId: formSearchData.tenant,
      })
    );
  }, [dispatch, formSearchData]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formSearchData.page) || 1 + "",
        size: String(formSearchData.size) || 10 + "",
        search: formSearchData.search,
      },
      {
        replace: true,
      }
    );
  }, [formSearchData, setSearchParam, searchParam]);

  return (
    <div
      className={`${styles.showFormsByProcessKeyContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100 d-flex align-items-center flex-column`}
    >
      <div
        className={`${styles.top} w-100 d-flex align-items-center justify-content-between w-100 flex-wrap-reverse`}
      >
        <div
          className="d-flex align-items-center flex-wrap justify-content-between"
          style={{
            width: window.innerWidth < 1000 ? "100%" : "",
          }}
        >
          <p
            className={` operationEvent  submitBtn`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (
                !permitionCheckerWithoutToast(
                  allGroups,
                  [
                    "Tenant_Full_Manager",
                    "Tenant_Basic_Manager",

                    "Process_Form_Editor",
                    "Process_Full_Manager",
                  ],
                  isFullRenderd
                )
              )
                return;
              navigate(
                `/forms/show-form-by-process-key/new-form/${processKey}/${persianProcessName}/${formSearchData.tenant}`,
                {
                  state: {
                    processKey,
                  },
                }
              );
            }}
          >
            ساخت فرم جدید
            <span className="icon-Plus" style={{ marginLeft: "6px" }}></span>
          </p>
          <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
            csv خروجی
          </CSVLink>
        </div>
        <p
          className="d-flex flex-wrap justify-content-between"
          style={{
            width: window.innerWidth < 1000 ? "100%" : "",
            direction: "rtl",
          }}
        >
          <span>مشاهده فرم فرآیند </span>: &nbsp; &nbsp;
          <span
            style={{
              color: themeColor,
            }}
          >
            {persianProcessName}
          </span>
        </p>
      </div>
      <div
        className={`${styles.topItems} mt-2 w-100 d-flex flex-row align-items-center  justify-content-end flex-wrap`}
        style={{
          gap: "1rem",
        }}
      >
        <CustomInput1
          value={formSearchData.search}
          onBlur={(e) => {}}
          defaultValue={formSearchData.search}
          inputType="text"
          onChange={(e) => {
            setFormSearchData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder={`جستجو `}
        />

        <CustomInput1
          value={formSearchData.id}
          onBlur={(e) => {}}
          defaultValue={formSearchData.id}
          inputType="text"
          onChange={(e) => {
            setFormSearchData((prevState) => ({
              ...prevState,
              id: e.target.value,
            }));
          }}
          placeHolder={`شمارنده فرم`}
          containerStyle={{}}
          dontShowSearchIcon
        />

        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            onBlur={(e) => {
              return false;
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            inputType="text"
            onChange={() => {
              return false;
            }}
            placeHolder="نام سازمان"
            //  keyForShowOnTop="نام سازمان"
            dontShowSearchIcon
            disabled
          />
        )}
      </div>

      {/* Table */}

      {forms.isGettingFormPending ? (
        <Pending className="mt-4" /> 
      ) : (
        <div
          className={`${styles.tableDataContainer} `}
          style={{
            height: "100%",
            width: "100%",
            marginTop: "1rem",
            position: "relative",
          }}
          id="usersReport"
        >
          {forms.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              موردی جهت نمایش وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    {/* <p className={`${styles.persianProcessName}`}>نام فرآیند</p> */}
                    <p className={`${styles.formKey}`}>کلید فرم</p>
                    <p className={`${styles.formName}`}>نام فرم</p>
                    <p className={`${styles.formId}`}> شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {forms.content.map(
                      ({ formType, id, key, processKey, title, url }) => (
                        <div
                          key={id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p className={`${styles.operationTab}`}>
                            <button
                              className={` operationEvent xmlBtn`}
                              style={{ cursor: "pointer", fontSize: "12px" }}
                              onClick={() => {
                                customizedToast(
                                  "در خواست فایل ارسال شد",
                                  "info"
                                );
                                fetch(`${mainUrl}${url}`)
                                  .then((res) => res.json())
                                  .then((data) => {
                                    downloadAsJsonFile(
                                      [JSON.stringify(data)],
                                      `${downloadFormNamePreFix}${id}`
                                    );
                                    customizedToast(
                                      "دانلود فایل آغاز شد",
                                      "success"
                                    );
                                  });
                              }}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.json}
                            >
                              <LuFileJson />
                            </button>
                            <button
                              className={` operationEvent editBtn`}
                              onClick={() => {
                                navigate(
                                  `/forms/show-form-by-process-key/show-selected-form?_key=${key}&_processKey=${processKey}&id=${id}&title=${title}`
                                );
                              }}
                              style={{ cursor: "pointer", fontSize: "12px" }}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.see}
                            >
                              <AiOutlineEye />
                            </button>

                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Form_Editor",
                                "Process_Full_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className={` operationEvent  editBtn`}
                                onClick={() => {
                                  if (
                                    !permitionCheckerWithoutToast(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Form_Editor",
                                        "Process_Full_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;
                                  navigate(
                                    `/forms/show-form-by-process-key/edit-selected-form/${processKey}/${id}/${persianProcessName}?_key=${key}&_processKey=${processKey}&id=${id}&title=${title}`
                                  );
                                }}
                                style={{ cursor: "pointer", fontSize: "12px" }}
                              >
                                <span
                                  className="icon-edit"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={language.edit}
                                ></span>
                              </button>
                            )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Form_Editor",
                                "Process_Full_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className={` operationEvent  deleteBtn`}
                                onClick={() => {
                                  if (
                                    !permitionCheckerWithoutToast(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Form_Editor",
                                        "Process_Full_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;
                                  Swal.fire({
                                    title: "از حذف فرم اطمینان دارید ؟",
                                    icon: "warning",
                                    showCancelButton: true,
                                    cancelButtonColor: "#3085d6",
                                    confirmButtonColor: "#d33",
                                    confirmButtonText: "بله",
                                    cancelButtonText: "خیر",
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        deleteAsyncForm({
                                          userToken,
                                          _data: {
                                            formId: String(id),
                                            processKey,
                                          },
                                        })
                                      ).then(() => {
                                        dispatch(
                                          getAsyncForms({
                                            ...formSearchData,
                                            userToken,
                                          })
                                        );
                                      });
                                    }
                                  });
                                }}
                                style={{ cursor: "pointer", fontSize: "12px" }}
                              >
                                <span
                                  className="icon-trash"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={language.delete}
                                ></span>
                              </button>
                            )}
                          </p>
                          {/* <p className={`${styles.persianProcessName}`}>
                            {persianProcessName}
                          </p> */}
                          <p className={`${styles.formKey}`}>{key} </p>
                          <p className={`${styles.formName}`}>{title} </p>
                          <p className={`${styles.formId}`}>{id} </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
                >
                  {forms.content.map(
                    ({ formType, id, key, processKey, title, url }) => (
                      <div key={id} className="w-100">
                        <div
                          className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>نام فرم</p>
                          <p>{title}</p>
                        </div>
                        <div
                          className={`${styles.tableRow} ${
                            isNightOn ? "nightBg2" : ""
                          } w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            <span>نام فرآیند</span>
                            <span>{persianProcessName}</span>
                          </p>
                          <p>
                            <span>کلید فرم</span>
                            <span>{key}</span>
                          </p>
                          <p>
                            <span>عملیات</span>
                            <p
                              className="d-flex flex-row align-items-center"
                              style={{
                                width: "max-content",
                              }}
                            >
                              <button
                                className={` operationEvent xmlBtn`}
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                onClick={() => {
                                  customizedToast(
                                    "در خواست فایل ارسال شد",
                                    "info"
                                  );
                                  fetch(`${mainUrl}${url}`)
                                    .then((res) => res.json())
                                    .then((data) => {
                                      downloadAsJsonFile(
                                        [JSON.stringify(data)],
                                        `${downloadFormNamePreFix}${id}`
                                      );
                                      customizedToast(
                                        "دانلود فایل آغاز شد",
                                        "success"
                                      );
                                    });
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.json}
                              >
                                <LuFileJson />
                              </button>
                              <button
                                className={` operationEvent editBtn`}
                                onClick={() => {
                                  navigate(
                                    `/forms/show-form-by-process-key/show-selected-form?_key=${key}&_processKey=${processKey}&id=${id}&title=${title}`
                                  );
                                }}
                                style={{ cursor: "pointer", fontSize: "12px" }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.see}
                              >
                                <AiOutlineEye />
                              </button>

                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Form_Editor",
                                  "Process_Full_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <button
                                  className={` operationEvent  viewBtn `}
                                  onClick={() => {
                                    if (
                                      !permitionCheckerWithoutToast(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Form_Editor",
                                          "Process_Full_Manager",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                    navigate(
                                      `/forms/show-form-by-process-key/edit-selected-form/${processKey}/${id}/${persianProcessName}?_key=${key}&_processKey=${processKey}&id=${id}&title=${title}`
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "10px !important",
                                  }}
                                >
                                  <span
                                    className="icon-edit"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={language.edit}
                                  ></span>
                                </button>
                              )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Form_Editor",
                                  "Process_Full_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <button
                                  className={` operationEvent  deleteBtn`}
                                  onClick={() => {
                                    if (
                                      !permitionCheckerWithoutToast(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Form_Editor",
                                          "Process_Full_Manager",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                    Swal.fire({
                                      title: "از حذف فرم اطمینان دارید ؟",
                                      icon: "warning",
                                      showCancelButton: true,
                                      cancelButtonColor: "#3085d6",
                                      confirmButtonColor: "#d33",
                                      confirmButtonText: "بله",
                                      cancelButtonText: "خیر",
                                      customClass: isNightOn ? "swalNight" : "",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        dispatch(
                                          deleteAsyncForm({
                                            userToken,
                                            _data: {
                                              formId: String(id),
                                              processKey,
                                            },
                                          })
                                        ).then(() => {
                                          dispatch(
                                            getAsyncForms({
                                              ...formSearchData,
                                              userToken,
                                            })
                                          );
                                        });
                                      }
                                    });
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                >
                                  <span
                                    className="icon-trash"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={language.delete}
                                  ></span>
                                </button>
                              )}
                            </p>
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* Table */}
      <Pagination
        dataForPagination={{
          currPage: Number(formSearchData.page),
          size: Number(formSearchData.size),
          totalPages: forms.totalPages,
        }}
        onPageNumberChange={(pageNumber) => {
          setFormSearchData((prevState) => ({
            ...prevState,
            page: Number(pageNumber),
          }));
        }}
        onPageNumbersClick={(pageNumber) => {
          setFormSearchData((prevState) => ({
            ...prevState,
            page: pageNumber,
          }));
        }}
        onPageSizeChange={(pageSize) => {
          setFormSearchData((prevState) => ({
            ...prevState,
            size: Number(pageSize),
          }));
          setFormSearchData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={formSearchData.search}
      />
    </div>
  );
};

export default ShowFormsByProcessKey;
