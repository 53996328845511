// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./Map.module.css";
import "./MapGlobalStyles.css";
// CSS

// Map And Related Controllers
// Map And Related Controllers

// Constants
import { defaultLat, defaultLon, themeColor } from "../../Constants/constants";
// Constants

// Utils
import { applyValueToInputAndRegisterToIt } from "../../Utils/MapUtils/applyValueToInputAndRegisterToIt";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Events
// Events

// Formats
import { latLonFormat, latLonFormat2 } from "../../Formats/formats";
import L from "leaflet";
import { MapType } from "../../Models/types";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import CustomCheckbox from "../../Utils/CustomCheckbox/CustomCheckbox";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
// Formats

const Map = () => {
  const isNightModeOn = useContext(IsNightModeOnContext);

  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const mapRef = useRef<any>(null);
  const iconRef = useRef<any>(null);
  const circleRef = useRef<any>(null);

  const [mapData, setMapData] = useState<MapType>(
    (() => {
      const inputEl = JSON.parse(
        localStorage.getItem("mapId") || JSON.stringify("init")
      );
      if (inputEl === "init") {
        alert("not able to see input in dom");
        return {
          lat: defaultLat,
          lon: defaultLon,
          isCircleActive: false,
          circleRadius: 500,
        };
      }
      const _inpuitEl: any = document.getElementById(inputEl);
      if (!_inpuitEl)
        return {
          lat: defaultLat,
          lon: defaultLon,
          isCircleActive: false,
          circleRadius: 500,
        };
      if (latLonFormat.test(_inpuitEl.value)) {
        const res = JSON.parse(_inpuitEl.value);
        return {
          lat: res.lat || defaultLat,
          lon: res.lon || defaultLon,
          isCircleActive: false,
          circleRadius: 500,
        };
      }
      if (latLonFormat2.test(_inpuitEl.value)) {
        const res = JSON.parse(_inpuitEl.value);
        return {
          lat: res.lat || defaultLat,
          lon: res.lon || defaultLon,
          isCircleActive: res.isCircleActive || false,
          circleRadius: res.circleRadius || 500,
        };
      } else {
        return {
          lat: defaultLat,
          lon: defaultLon,
          isCircleActive: false,
          circleRadius: 500,
        };
      }
    })()
  );

  const mapDataRef = useRef<MapType>(mapData);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const closeModal = useCallback(() => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {}, 500);
  }, []);

  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  useEffect(() => {
    if (!document.getElementById("lif")) return;
    const map = L.map("lif").setView(
      [Number(mapDataRef.current.lat), Number(mapDataRef.current.lon)],
      10
    );
    mapRef.current = map;
    // Add a tile layer
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      map
    );
    map.on("click", function (e) {
      const latlng = e.latlng;
      setMapData((prevState) => ({
        ...prevState,
        lat: latlng.lat + "",
        lon: latlng.lng + "",
      }));
    });
    const customIcon = L.icon({
      iconUrl: markerIconPng,
      iconSize: [32, 48],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    const marker = L.marker(
      [Number(mapDataRef.current.lat), Number(mapDataRef.current.lon)],
      {
        icon: customIcon,
      }
    ).addTo(map);
    iconRef.current = marker;
    return () => {
      map.remove();
    };
  }, []);
  //
  useEffect(() => {
    mapDataRef.current = mapData;
    mapRef.current.panTo([
      Number(mapDataRef.current.lat),
      Number(mapDataRef.current.lon),
    ]);
    const newLatLng = L.latLng(
      Number(mapDataRef.current.lat),
      Number(mapDataRef.current.lon)
    );
    iconRef.current.setLatLng(newLatLng);
    // iconRef.current._icon.addEventListener("click", (e: any) => {
    //   e.stopPropagation();
    // });

    if (!mapData.isCircleActive && circleRef.current) {
      mapRef.current.removeLayer(circleRef.current);
      circleRef.current = null;
    }

    return () => {
      iconRef.current.setLatLng(newLatLng);
    };
  }, [mapData]);
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  /******* */
  //
  useEffect(() => {
    // it must be here for update map
    console.log(mapData.lat);
    // it must be here for update map
    Promise.resolve()
      .then(() => {
        if (mapData.isCircleActive)
          if (circleRef.current) {
            mapRef.current.removeLayer(circleRef.current);
            circleRef.current = null;
          }
      })
      .then(() => {
        if (mapData.isCircleActive) {
          const circle = L.circle(
            [Number(mapDataRef.current.lat), Number(mapDataRef.current.lon)],
            {
              color: "red", // Circle outline color
              fillColor: themeColor, // Circle fill color
              fillOpacity: 0.5, // Opacity of the fill color
              radius: mapDataRef.current.circleRadius, // Radius of the circle in meters
            }
          ).addTo(mapRef.current);
          circleRef.current = circle;
        }
        if (!circleRef.current) return;
        circleRef.current.on("click", (e: any) => {
          const location = e.latlng;

          const newLatLng = L.latLng(
            Number(location.lat),
            Number(location.lng)
          );
          iconRef.current.setLatLng(newLatLng);
          circleRef.current.setLatLng(newLatLng);
          console.log(e);
        });
      });
  }, [mapData.circleRadius, mapData.isCircleActive, mapData.lat]);
  //
  return (
    <div
      className={`${styles.mapMasterContainer} `}
      id="CUSTOM_MAP_SELECTOR_MODAL"
    >
      <div className={`${styles.bgRef}`} ref={bgRef}></div>

      <div
        className={`${styles.content} ${
          isNightModeOn ? "nightBg2" : ""
        } d-flex flex-column align-items-center `}
        ref={contentRef}
        id="mapContent"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p>لطفا موقعیت مکانی مورد نظر خود را از روی نقشه پیدا کنید</p>

        <div
          id="lif"
          className="mt-3"
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>

        <CustomCheckbox
          isChecked={Boolean(mapData.isCircleActive)}
          onChange={() => {}}
          onClick={(e) => {
            e.stopPropagation();
            setMapData((prevState) => ({
              ...prevState,
              isCircleActive: !Boolean(prevState.isCircleActive),
            }));
          }}
          title="نمایش حیطه"
          containerClassName="w-100 mt-3"
          containerId="mapCheckBox"
        />
        {Boolean(mapData.isCircleActive) ? (
          <CustomInput1
            defaultValue={String(mapData.circleRadius || 500)}
            inputType="number"
            onBlur={() => {}}
            onChange={(e) => {
              e.stopPropagation();
              setMapData((prevState) => ({
                ...prevState,
                circleRadius: e.target.valueAsNumber || 500,
              }));
            }}
            placeHolder={"مفدار دایره را بر اساس متر وارد کنید"}
            value={String(mapData.circleRadius || 500)}
            onContainerClick={(e) => {
              e.stopPropagation();
            }}
          />
        ) : null}

        <div
          className={`d-flex w-100 flex-row align-items-center justify-content-between w-100 mt-4 ${
            styles.mapButtonsContainer
          } ${isNightModeOn ? "nightBg2" : ""}`}
          id="mapButtons"
        >
          <button
            className="operationButtons submitBtn p-2 rounded-2"
            onClick={() => {
              Promise.resolve()
                .then(() => {
                  applyValueToInputAndRegisterToIt(mapData);
                })
                .then(() => {
                  closeModal();
                });
            }}
          >
            تایید
          </button>
          <button
            className="operationButtons submitBtn p-2 rounded-2"
            onClick={() => {
              Promise.resolve()
                .then(() => {
                  applyValueToInputAndRegisterToIt({
                    lat: defaultLat + "",
                    lon: defaultLon + "",
                    circleRadius: 500,
                    isCircleActive: false,
                  });
                })
                .then(() => {
                  closeModal();
                });
            }}
          >
            خالی کردن ورودی
          </button>
          <button
            className="operationButtons submitBtn p-2  rounded-2"
            onClick={() => {
              Promise.resolve()
                .then(() => {
                  applyValueToInputAndRegisterToIt({
                    lat: defaultLat + "",
                    lon: defaultLon + "",
                    circleRadius: 500,
                    isCircleActive: false,
                  });
                })
                .then(() => {
                  closeModal();
                });
            }}
          >
            ریست
          </button>
        </div>
      </div>
    </div>
  );
};

export default Map;
