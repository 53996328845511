// Axios
import { AxiosResponse } from "axios";
import { _axios_withoutHeaderToken } from "../../Base_Axios_Config/_axios_withoutHeaderToken";
// Axios

// Models
import { I_SendingDataForStartNewAllocatedProcessInstance } from "../../../Models/sendingDataSchema";
// Models
import { I_Message } from "../../../Models/interfaces";

export const startNewSingleAllocatedProcessInstanceService = async (_data: {
  userToken: string;
  _data: {
    processKey: string | number;
    _data: I_SendingDataForStartNewAllocatedProcessInstance;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      trackingCode: string;
    };
  }>
> => {
  return _axios_withoutHeaderToken.post(
    `/allocated-processes/${_data._data.processKey}/start`,
    _data._data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
