// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditableGroupTab.module.css";
// CSS

// Utils
import { CSVLink } from "react-csv";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import Pagination from "../../../Utils/Pagination/Pagination";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncEditableGroups,
  groupSlice,
  postAsyncPublicGroupRemoval,
} from "../../../Features/GroupSlice/groupSlice";
// Redux

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
// Contexts

// Modules
import { Spinner } from "reactstrap";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Components
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Components

const EditableGroupTab = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const editableGroups = useAppSelector((state) => state.groups.editableGroups);

  const allGroups = useContext(UserGroupsContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [paginationData, setPaginationData] = useState<{
    page: number;
    size: number;
    search: string;
    tenant: string;
  }>({ page: 1, size: 10, search: "", tenant: selectedTenant.tenantId });

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !permitionCheckerWithoutToast(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "All_Group_Manager",
            "Public_Group_Manager",
          ],
          isFullRenderd
        )
      )
        return;
      dispatch(
        getAsyncEditableGroups({
          _data: paginationData,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, paginationData, userToken, allGroups, isFullRenderd]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetEditableGroupsData());
    };
  }, [dispatch]);

  const csvReport = {
    headers: [
      {
        label: "نام لاتین",
        key: "engName",
      },
      {
        label: "از گروه",
        key: "fromGroup",
      },
      {
        label: "به گروه",
        key: "toGroup",
      },
    ],
    data: editableGroups.content.map((item) => ({
      engName: item.value,
      fromG: item.categoryType.title,
      key: item.title,
    })),
    filename: "editableGroups.csv",
  };

  return (
    <div
      className={`${styles.editableGroupsContainer} w-100 ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          گروه ها
        </p>
        <div className="d-flex flex-col align-items-center">
          <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
            csv خروجی
          </CSVLink>
        </div>
      </div>

      <div
        className={`${styles.inputsContainer} w-100 d-flex flex-row mt-2 align-items-center justify-content-end flex-wrap `}
      >
        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={(e) => {
              return false;
            }}
            placeHolder="  نام سازمان"
            // keyForShowOnTop="  نام سازمان"
            containerStyle={{
              width: "45%",
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}

        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={paginationData.search}
          defaultValue={paginationData.search}
          onChange={(e) => {
            setPaginationData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="نام گروه"
          //   keyForShowOnTop="نام گروه"
          containerStyle={{
            width: "45%",
          }}
        />
      </div>

      {editableGroups.isPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {editableGroups.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
              <p>موردی وجود ندارد</p>
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightModeOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.groupType}`}> نوع گروه </p>
                    <p className={`${styles.groupName}`}> نام گروه</p>
                    <p className={`${styles.engName}`}> نام لاتین </p>
                  </div>

                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {editableGroups.content.map((item) => (
                      <div
                        key={item.value}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <div className={`${styles.operationTab}`}>
                          <div className={`${styles.operationTab}`}>
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "All_Group_Manager",
                                "Public_Group_Manager",
                              ],
                              isFullRenderd
                            ) && item.categoryType.value.includes("PUBLIC") ? (
                              <button
                                className={` operationEvent editBtn`}
                                onClick={() => {
                                  if (
                                    !permitionCheckerWithoutToast(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "All_Group_Manager",
                                        "Public_Group_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;
                                  if (
                                    !item.categoryType.value.includes("PUBLIC")
                                  ) {
                                    customizedToast(
                                      "گروه اساسی قابل ویرایش نیست",
                                      "info"
                                    );
                                    return;
                                  }
                                  navigate(
                                    `/groups/edit-public-groups/${item.value}/${item.title}`
                                  );
                                }}
                              >
                                <span
                                  className="icon-edit"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="ویرایش"
                                ></span>
                              </button>
                            ) : (
                              <p
                                className=" deleteBtn p-2"
                                style={{
                                  cursor: "not-allowed",
                                  border: "1px solid #eb6363",
                                  borderRadius: "4px",
                                }}
                              >
                                غیرقابل ویرایش
                              </p>
                            )}

                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "All_Group_Manager",
                                "Public_Group_Manager",
                              ],
                              isFullRenderd
                            ) && item.categoryType.value.includes("PUBLIC") ? (
                              <button
                                className={"operationEvent deleteBtn"}
                                onClick={() => {
                                  if (
                                    permitionCheckerWithoutToast(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "All_Group_Manager",
                                        "Public_Group_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    Swal.fire({
                                      icon: "question",
                                      title: "حذف گروه بدون بازگشت می باشد ",
                                      text: "آیا ادامه میدهید ؟",
                                      showCancelButton: true,
                                      cancelButtonText: "لغو",
                                      confirmButtonText: "حذف",
                                      customClass: isNightModeOn
                                        ? "swalNight"
                                        : "",
                                    }).then(({ isConfirmed }) => {
                                      if (!isConfirmed) return;
                                      dispatch(
                                        postAsyncPublicGroupRemoval({
                                          userToken,
                                          groupId: String(item.value),
                                        })
                                      ).then(() => {
                                        dispatch(
                                          getAsyncEditableGroups({
                                            _data: paginationData,
                                            userToken,
                                          })
                                        );
                                      });
                                    });
                                }}
                              >
                                <span
                                  className="icon-trash "
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="حذف"
                                ></span>
                              </button>
                            ) : null}
                          </div>
                        </div>
                        <p
                          className={`${styles.groupType}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.categoryType.title.length >= 16
                              ? item.categoryType.title
                              : ""
                          }
                        >
                          {item.categoryType.title.length >= 16
                            ? "..." + item.categoryType.title.substring(0, 15)
                            : item.categoryType.title}
                        </p>
                        <p
                          className={`${styles.groupName}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.title.length >= 16 ? item.title : ""
                          }
                        >
                          {item.title.length >= 16
                            ? "..." + item.title.substring(0, 15)
                            : item.title}
                        </p>
                        <p
                          className={`${styles.engName}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            String(item.value).length >= 16
                              ? String(item.value)
                              : ""
                          }
                        >
                          {String(item.value).length >= 16
                            ? "..." + String(item.value).substring(0, 15)
                            : String(item.value)}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {editableGroups.content.map((item) => (
                    <div key={item.value} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.title}</p>
                        <p>نام گروه </p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightModeOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span>نوع گروه</span>
                          <span>{item.categoryType.value}</span>
                        </p>
                        <p>
                          <span>نام لاتین</span>
                          <span>{item.value}</span>
                        </p>
                        <p className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "All_Group_Manager",
                              "Public_Group_Manager",
                            ],
                            isFullRenderd
                          ) && item.categoryType.value.includes("PUBLIC") ? (
                            <button
                              className={` operationEvent editBtn`}
                              onClick={() => {
                                if (
                                  !permitionCheckerWithoutToast(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "All_Group_Manager",
                                      "Public_Group_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  return;
                                if (
                                  !item.categoryType.value.includes("PUBLIC")
                                ) {
                                  customizedToast(
                                    "گروه اساسی قابل ویرایش نیست",
                                    "info"
                                  );
                                  return;
                                }
                                navigate(
                                  `/groups/edit-public-groups/${item.value}/${item.title}`
                                );
                              }}
                            >
                              <span
                                className="icon-edit"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="ویرایش"
                              ></span>
                            </button>
                          ) : (
                            <p
                              className=" deleteBtn p-2"
                              style={{
                                cursor: "not-allowed",
                                border: "1px solid #eb6363",
                                borderRadius: "4px",
                              }}
                            >
                              غیرقابل ویرایش
                            </p>
                          )}

                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "All_Group_Manager",
                              "Public_Group_Manager",
                            ],
                            isFullRenderd
                          ) && item.categoryType.value.includes("PUBLIC") ? (
                            <button
                              className={"operationEvent deleteBtn"}
                              onClick={() => {
                                if (
                                  permitionCheckerWithoutToast(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "All_Group_Manager",
                                      "Public_Group_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  Swal.fire({
                                    icon: "question",
                                    title: "حذف گروه بدون بازگشت می باشد ",
                                    text: "آیا ادامه میدهید ؟",
                                    showCancelButton: true,
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "حذف",
                                    customClass: isNightModeOn
                                      ? "swalNight"
                                      : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      postAsyncPublicGroupRemoval({
                                        userToken,
                                        groupId: String(item.value),
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncEditableGroups({
                                          _data: paginationData,
                                          userToken,
                                        })
                                      );
                                    });
                                  });
                              }}
                            >
                              <span
                                className="icon-trash "
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="حذف"
                              ></span>
                            </button>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: paginationData.page,
          size: paginationData.size,
          totalPages: editableGroups.totalPages,
        }}
        onPageNumberChange={(page) => {
          setPaginationData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageNumbersClick={(page) => {
          setPaginationData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            size,
          }));
          setPaginationData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={paginationData.search}
      />
    </div>
  );
};

export default EditableGroupTab;
