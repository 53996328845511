// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditSingleCallOfRegistration.module.css";
// CSS

// DatePicker
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
// DatePicker

// TimePicker
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import Pagination from "../../../Utils/Pagination/Pagination";
// TimePicker

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncPossibleGroupMembershipSlice,
  possibleGroupMemberShipsSlice,
} from "../../../Features/PossibleGroupMemberShipsSlice/possibleGroupMemberShipsSlice";
import {
  callOfRegistrationSlice,
  editAsyncSingleCallOfRegistration,
  editAsyncSingleCallOfRegistrationWithoutDoneChanging,
  getAsyncAllDetailsForSingleCallOfRegistration,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
// Redux

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Utils
import { Spinner } from "reactstrap";
import { convertPersianNumbersToEnglish } from "../../../Utils/FormioUtils/convertPersianNumbersToEnglish";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
import { convertToGeorgian } from "../../../Utils/FormioUtils/convertToGeorgianDate";
// Utils

// Modules
import { permissionChecker } from "../../../Modules/permissionChecker";
// Modules

// Contexts
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Constants
import { weekDaysInPersian } from "../../../Constants/constants";
// Constants

const EditSingleCallOfRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { callOfRegistrationId } = useParams();

  const [paginationData, setPaginationData] = useState<{
    currPage: number;
    pageSize: number;
  }>({
    currPage: 1,
    pageSize: 10,
  });

  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<{
    registrationDate: string;
    startTime: string;
    endTime: string;
    title: string;
    selectedGroups: { groupId: string }[];
    active: boolean;
    description: string;
    rejectAllRequestsWithDeactivation: boolean;
  }>({
    active: Boolean(searchParams.get("active") === "true"),
    startTime: searchParams.get("startTime") || "",
    endTime: searchParams.get("endTime") || "",
    title: searchParams.get("title") || "",
    description: decodeURIComponent(searchParams.get("description")!),
    registrationDate: searchParams.get("registrationDate") || "",
    selectedGroups: [],
    rejectAllRequestsWithDeactivation: false,
  });

  //   JUST FOR INITIALIZING DATEPICKER
  const [values, setValues] = useState([
    searchParams.get("registrationDate")?.split("***")[0] || "",
    searchParams.get("registrationDate")?.split("***")[1] || "",
  ]);
  //   JUST FOR INITIALIZING DATEPICKER

  const groupsData = useAppSelector((state) => state.possibleGroupMembership);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const isFullRendered = useContext(IsFullRenderdContext);
  const allGroups = useContext(UserGroupsContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const { isPending, isDone } = useAppSelector(
    (state) => state.callOfRegistration.editingCallOfRegistration
  );

  const singleCallOfRegistrationData = useAppSelector(
    (state) => state.callOfRegistration.singleCallOfRegistrationDetails
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncPossibleGroupMembershipSlice({
          userToken,
          searchQuery: {
            page: paginationData.currPage,
            size: paginationData.pageSize,
            tenantId: selectedTenant.tenantId,
            search: "",
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, paginationData, selectedTenant]);

  useEffect(() => {
    if (!isDone) return;

    navigate("/call-of-registration");
  }, [isDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(possibleGroupMemberShipsSlice.actions.resetPossiblePermition());
      dispatch(
        callOfRegistrationSlice.actions.resetEditingSingleCallOfRegistrationState()
      );
      dispatch(
        callOfRegistrationSlice.actions.resetRecevedDetailsOfSingleCallOfRegistration()
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isFullRendered) return;
    if (
      !permissionChecker(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Call_Of_Registration_Manager",
        ],
        isFullRendered
      )
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllDetailsForSingleCallOfRegistration({
          userToken,
          callOfRegistrationId: String(callOfRegistrationId),
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, callOfRegistrationId, isFullRendered, allGroups]);

  return (
    <div
      className={`${styles.editSingleCallOfRegistrationContainer} ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div
        className={`w-100 d-flex flex-row align-items-center justify-content-between mb-5`}
      >
        <button
          className="operationEvent submitBtn"
          onClick={() => {
            if (
              !permitionCheckerWithoutToast(
                allGroups,
                [
                  "Tenant_Full_Manager",
                  "Tenant_Basic_Manager",
                  "CALL_OF_REGISTRATIONS_MANAGER",
                ],
                isFullRendered
              )
            )
              return;

            dispatch(
              editAsyncSingleCallOfRegistration({
                userToken,
                _data: {
                  title: formData.title,
                  active: formData.active,
                  clearEndTime: false,
                  clearStartTime: false,
                  description: formData.description,
                  endTime: `${convertToGeorgian(
                    formData.registrationDate.split("***")[1]
                  )} ${
                    formData.endTime.split(":").length === 3
                      ? formData.endTime
                      : `${formData.endTime}:00`
                  }`,
                  startTime: `${convertToGeorgian(
                    formData.registrationDate.split("***")[0]
                  )} ${
                    formData.startTime.split(":").length === 3
                      ? formData.startTime
                      : `${formData.startTime}:00`
                  }`,
                  groupMembershipToAdd: [],
                  groupMembershipToDelete: [],
                  rejectAllRequestsWithDeactivation:
                    formData.rejectAllRequestsWithDeactivation,
                },
                callOfRegistrationId: String(callOfRegistrationId),
              })
            );
          }}
        >
          {isPending ? <Spinner /> : "تایید"}
        </button>

        <p>افزودن فراخوان</p>
      </div>

      <CustomInput1
        inputType="text"
        onBlur={() => {}}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            title: e.target.value,
          }));
        }}
        value={formData.title}
        defaultValue={formData.title}
        placeHolder="نام فراخوان"
        dontShowSearchIcon
      />

      <CustomInput1
        inputType="text"
        onBlur={() => {}}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            description: e.target.value,
          }));
        }}
        value={formData.description}
        defaultValue={formData.description}
        placeHolder="توضیحات فرخوان"
        dontShowSearchIcon
        containerClassName="mt-3"
      />

      <DatePicker
        calendar={persian}
        locale={persian_fa}
        className={`${styles.dateInputClassName}  mt-2  ${
          isNightModeOn ? "purple bg-dark" : "purple"
        }`}
        value={values}
        dateSeparator="-"
        range
        weekDays={weekDaysInPersian}
        onChange={(value: any) => {
          if (!Boolean(value)) return;
          setValues(value);
          setFormData((prevState) => ({
            ...prevState,
            registrationDate: value.toString(),
          }));

          if (value.toString().split(",").length === 2) {
            const start = value.toString().split(",")[0];
            const end = value.toString().split(",")[1];

            const convertedStartToEnglish = start
              .split("/")
              .map((item: string) => convertPersianNumbersToEnglish(item))
              .join("-");

            const convertedEndToEnglish = end
              .split("/")
              .map((item: string) => convertPersianNumbersToEnglish(item))
              .join("-");

            setFormData((prevState) => ({
              ...prevState,
              registrationDate: `${convertedStartToEnglish}***${convertedEndToEnglish}`,
            }));
          }
        }}
        placeholder="بازه  فعالیت این فراخوان"
        containerClassName={`w-100 ${styles.datePickerContainer}`}
        inputClass={` w-100 lightBorder`}
        calendarPosition="bottom-center"
      />
      <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-2">
        <TimePicker
          onChange={(data: any) =>
            setFormData((prevState) => ({ ...prevState, startTime: data }))
          }
          value={formData.startTime}
          className={`${styles.timePickerClassName}`}
          locale="fa"
        />
        <p>زمان شروع</p>
      </div>
      <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-2">
        <TimePicker
          onChange={(data: any) =>
            setFormData((prevState) => ({ ...prevState, endTime: data }))
          }
          value={formData.endTime}
          locale="fa"
          className={`${styles.timePickerClassName}`}
        />
        <p>زمان پایان</p>
      </div>

      <CustomCheckbox
        isChecked={formData.active}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            active: !prevState.active,
          }));
        }}
        onClick={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            active: !prevState.active,
          }));
        }}
        title="فعال"
        containerClassName="mt-4"
      />

      <CustomCheckbox
        isChecked={formData.rejectAllRequestsWithDeactivation}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            rejectAllRequestsWithDeactivation:
              !prevState.rejectAllRequestsWithDeactivation,
          }));
        }}
        onClick={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            rejectAllRequestsWithDeactivation:
              !prevState.rejectAllRequestsWithDeactivation,
          }));
        }}
        title="حذف درخواست ها بعد از اتمام"
      />

      <p
        className={`w-100 d-flex flex-row align-items-center justify-content-end mt-5 mb-2`}
      >
        گروه های قابل الصاق
      </p>
      <div
        className={`${styles.selectingClassNameContainer} w-100 d-flex flex-row align-items-center justify-content-between flex-wrap`}
      >
        {groupsData.isPending ? (
          <div className="w-100" style={{ textAlign: "right" }}>
            در حال بارگذاری
          </div>
        ) : (
          <>
            {groupsData.content.length === 0 ? (
              <>موردی وجود ندارد </>
            ) : (
              <>
                {groupsData.content.map((item, index) => (
                  <CustomCheckbox
                    key={item.value}
                    isChecked={singleCallOfRegistrationData.data.groups
                      .map((_item) => _item.value)
                      .includes(item.value)}
                    onClick={() => {
                      if (
                        singleCallOfRegistrationData.data.groups
                          .map((_item) => _item.value)
                          .includes(item.value)
                      ) {
                        dispatch(
                          editAsyncSingleCallOfRegistrationWithoutDoneChanging({
                            userToken,
                            _data: {
                              title: formData.title,
                              active: formData.active,
                              clearEndTime: false,
                              clearStartTime: false,
                              description: formData.description,
                              endTime: `${
                                formData.registrationDate.split("***")[1]
                              } ${formData.endTime}:00`,
                              startTime: `${
                                formData.registrationDate.split("***")[0]
                              } ${formData.startTime}:00`,
                              groupMembershipToAdd: [],
                              groupMembershipToDelete: [
                                {
                                  groupId: item.value,
                                },
                              ],
                              rejectAllRequestsWithDeactivation:
                                formData.rejectAllRequestsWithDeactivation,
                            },
                            callOfRegistrationId: String(callOfRegistrationId),
                          })
                        ).then(() => {
                          dispatch(
                            getAsyncAllDetailsForSingleCallOfRegistration({
                              userToken,
                              callOfRegistrationId:
                                String(callOfRegistrationId),
                            })
                          );
                        });
                        return;
                      }
                      dispatch(
                        editAsyncSingleCallOfRegistrationWithoutDoneChanging({
                          userToken,
                          _data: {
                            title: formData.title,
                            active: formData.active,
                            clearEndTime: false,
                            clearStartTime: false,
                            description: formData.description,
                            endTime: `${
                              formData.registrationDate.split("***")[1]
                            } ${formData.endTime}:00`,
                            startTime: `${
                              formData.registrationDate.split("***")[0]
                            } ${formData.startTime}:00`,
                            groupMembershipToAdd: [
                              {
                                groupId: item.value,
                              },
                            ],
                            groupMembershipToDelete: [],
                            rejectAllRequestsWithDeactivation:
                              formData.rejectAllRequestsWithDeactivation,
                          },
                          callOfRegistrationId: String(callOfRegistrationId),
                        })
                      ).then(() => {
                        dispatch(
                          getAsyncAllDetailsForSingleCallOfRegistration({
                            userToken,
                            callOfRegistrationId: String(callOfRegistrationId),
                          })
                        );
                      });
                    }}
                    onChange={() => {}}
                    title={item.title}
                    containerClassName={`${styles.groupItem}`}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>
      <Pagination
        dataForPagination={{
          currPage: paginationData.currPage,
          size: paginationData.pageSize,
          totalPages: groupsData.totalPages,
        }}
        onPageNumberChange={(pageNumber) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageNumbersClick={(pageNumber: number) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            pageSize: Number(size),
          }));
          setPaginationData((prevState) => ({ ...prevState, currPage: 1 }));
        }}
        search={""}
      />
    </div>
  );
};

export default EditSingleCallOfRegistration;
