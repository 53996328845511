// React
import React, { useContext, useMemo } from "react";
// React

// CSS
import styles from "./WorkFlow.module.css";
// CSS

// Models
import { I_HistoryDetail } from "../../../../../../Models/interfaces";
import { ProcessHistoryContext } from "../../../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import { withoutInformation } from "../TaskInformation/TaskInformation";
import Accordion from "../../../../../Accordion/Accordion";
import RowItem from "../RowItem/RowItem";
// Models

type WorkFlowProps = {
  data: I_HistoryDetail;
};

const WorkFlow: React.FunctionComponent<WorkFlowProps> = ({ data: _data }) => {
  const processHistoryData = useContext(ProcessHistoryContext);

  const data: {
    title: string | number;
    value: string | number;
  }[] = useMemo(
    () =>
      processHistoryData.selectedHistoryType === "callActivity"
        ? [
            {
              title: "کد کاری",
              value:
                _data?.callActivityDetail?.businessKey || withoutInformation,
            },
            {
              title: "وضعیت دسترسی",
              value: _data?.callActivityDetail?.accessible
                ? "قابل دسترس"
                : "عیرفابل دسترس",
            },
            {
              title: "وضعیت",
              value:
                _data?.callActivityDetail?.state?.value || withoutInformation,
            },
            {
              title: "زمان شروع",
              value:
                _data?.callActivityDetail?.startTime?.jalaliDate ||
                withoutInformation,
            },
            {
              title: "زمان خاتمه",
              value:
                _data?.callActivityDetail?.endTime?.jalaliDate ||
                withoutInformation,
            },
          ]
        : [],
    [_data, processHistoryData]
  );

  return (
    <div className={`${styles.workFlowContainer}  w-100`}>
      <Accordion
        topName="گردش کار فرآیند"
        containerClassName="w-100 d-flex flex-column align-items-center mt-4"
      >
        <div
          className="w-100 d-flex flex-row align-items-center
         justify-content-center flex-wrap py-2
         gap-2
         "
        >
          {data.map((item, index) => (
            <RowItem data={item} key={item.title} isOdd />
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default WorkFlow;
