// React
import React, { useCallback, useContext } from "react";
// React

// CSS
import styles from "./../HomePageAllocatedProcesses.module.css";
// CSS

// Models
import { I_AllocatedProcessItem } from "../../../../Models/interfaces";
// Models

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { startAsyncNewAllocatedProcessInstance } from "../../../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
// Contexts

type AllocatedCardProps = {
  item: I_AllocatedProcessItem;
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<
    React.SetStateAction<{
      processKey: string;
      processId: string;
      persianProcessName: string;
    }>
  >;
};

const AllocatedCard: React.FunctionComponent<AllocatedCardProps> = ({
  item,
  setIsModalInScreen,
  setModalData,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const clickHandler = useCallback(() => {
    if (item.startable) {
      if (item.hasStartingForm) {
        navigate(
          `/allocated-processes/start-new-instance-with-form/${item.processKey}/${item.processName}`,
          {
            state: {
              initialInformationRequired: item.initialInformationRequired,
            },
          }
        );
        return;
      }
      Swal.fire({
        icon: "question",
        title: `فرآیند ${item.processName} شروع شود ؟`,
        showCancelButton: true,
        confirmButtonText: "بله",
        cancelButtonText: "خیر",
        customClass: isNightModeOn ? "swalNight" : "",
      }).then(({ isConfirmed }) => {
        if (!isConfirmed) return;

        if (item.initialInformationRequired) {
          setIsModalInScreen(true);
          setModalData({
            processId: item.processId,
            processKey: item.processKey,
            persianProcessName: item.processName,
          });
        } else {
          dispatch(
            startAsyncNewAllocatedProcessInstance({
              userToken,
              _data: {
                _data: {
                  formData: {},
                  businessKey: `${item.processKey}_businessKey_${Date.now()}`,
                  title: `${item.processName}_${Date.now()}`,
                },
                processKey: item.processKey,
                onDone: (tc) => {
                  Swal.fire({
                    icon: "success",
                    title: "عملیات موفق",
                    text: `کد رهگیری : ${tc}`,
                  });
                },
              },
            })
          );
        }
      });
    } else {
      return;
    }
  }, [
    item,
    dispatch,
    isNightModeOn,
    navigate,
    setIsModalInScreen,
    setModalData,
    userToken,
  ]);

  return (
    <div
      className={`${styles.processSlide}  ${
        isNightModeOn ? "nightBg2 pcNight" : ""
      }
    w-100 d-flex flex-column pointer align-items-center  rounded-2 p-2 justify-content-between
  `}
      key={item.processId}
      onClick={clickHandler}
      data-tooltip-content={"برای شروع کلیک کنید"}
      data-tooltip-id={item.startable ? "my-tooltip" : ""}
    >
      <div className="w-100 gap-2 d-flex flex-column align-items-center ">
        <p className="w-100 d-flex flex-row align-items-center justify-content-center bold ">
          {item.processName}
        </p>
      </div>

      <span>
        {item.startable ? (
          <>
            {item.hasStartingForm ? (
              <span className="operationEvent startBtn">
                <span className="icon-start"></span>
              </span>
            ) : (
              <span className="operationEvent startBtn" onClick={() => {}}>
                <span className="icon-start"></span>
              </span>
            )}
          </>
        ) : (
          <span
            className="operationEvent startBtn"
            onClick={() => {
              customizedToast("فرآیند غیر قابل شروع است", "info");
            }}
          >
            <span className="icon-start"></span>
          </span>
        )}
      </span>
    </div>
  );
};

export default AllocatedCard;
