// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../../Models/interfaces";
// Models

export const removeSingleFormSampleService = async (_data: {
  userToken: string;
  _data: {
    formSampleId: string | number;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/form-samples/${_data._data.formSampleId}/delete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
