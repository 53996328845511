// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./FastCreate.module.css";
// CSS

// BPMN
import BpmnModeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
// BPMN

// Utils
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
// Utils

// Redux
import FastCreateSaveModal from "./FastCreateSaveModal/FastCreateSaveModal";
import { searchAndBackSlice } from "../../../../Features/SearchAndBackSlice/searchAndBackSlice";
import { useAppDispatch } from "../../../../app/store";
// Redux

// Icons
// Icons

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const FastCreate = () => {
  const containerRef = useRef<any>(null);
  const modelerRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const isNightModeOn = useContext(IsNightModeOnContext);

  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  const [bpmnXML_Text, setBpmnXML_Text] =
    useState<string>(`<?xml version="1.0" encoding="UTF-8"?>
    <definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bioc="http://bpmn.io/schema/bpmn/biocolor/1.0" xmlns:color="http://www.omg.org/spec/BPMN/non-normative/color/1.0" xmlns:signavio="http://www.signavio.com" id="sid-83ed6021-15b8-42df-b971-a63f5e32fb4f" targetNamespace="http://www.signavio.com" expressionLanguage="http://www.w3.org/TR/XPath" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="12.0.0" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL http://www.omg.org/spec/BPMN/2.0/20100501/BPMN20.xsd">
      <process id="sid-db34fc7b-c49c-4542-9048-fcc0313c0cda" processType="None" isClosed="false" isExecutable="false">
        <task id="sid-D2A83D67-359A-4C2D-A4A5-EE6F61932675" name="">
          <extensionElements>
            <signavio:signavioMetaData metaKey="bgcolor" metaValue="#ffffcc" />
            <signavio:signavioMetaData metaKey="userstory" metaValue="" />
          </extensionElements>
        </task>
      </process>
      <collaboration id="sid-403fa843-143a-4d5e-8c39-59f628e3aa39">
        <extensionElements>
          <signavio:signavioDiagramMetaData metaKey="revisionid" metaValue="eec0098d1c324c5295a695320b4ab1a1" />
        </extensionElements>
        <participant id="sid-40FD4BAF-A217-4AEE-8B75-7F49EC865A3C" processRef="sid-23D3B118-620B-462C-87E7-3FCA5A31C116">
          <extensionElements>
            <signavio:signavioMetaData metaKey="bgcolor" metaValue="#ffffff" />
          </extensionElements>
        </participant>
      </collaboration>
      <process id="sid-23D3B118-620B-462C-87E7-3FCA5A31C116" name="Dispatch of Goods" processType="None" isClosed="false" isExecutable="false">
        <extensionElements />
        <laneSet id="sid-5a00a007-a3c0-420c-b67c-b2e3c2d43c83" />
      </process>
      <bpmndi:BPMNDiagram id="sid-6cd7a277-2063-47f4-a44d-2c3a88e85726">
        <bpmndi:BPMNPlane id="sid-9bb4fada-88a8-43b1-954b-b51ea8eb9e4e" bpmnElement="sid-403fa843-143a-4d5e-8c39-59f628e3aa39">
          <bpmndi:BPMNShape id="sid-40FD4BAF-A217-4AEE-8B75-7F49EC865A3C_gui" bpmnElement="sid-40FD4BAF-A217-4AEE-8B75-7F49EC865A3C" isHorizontal="true" bioc:stroke="#0d4372" bioc:fill="#bbdefb" color:background-color="#bbdefb" color:border-color="#0d4372">
            <omgdc:Bounds x="160" y="80" width="677" height="219" />
          </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
        <bpmndi:BPMNLabelStyle id="sid-513284be-f103-4ba2-ba64-2285a0e00c6a">
          <omgdc:Font name="Arial" size="11" isBold="false" isItalic="false" isUnderline="false" isStrikeThrough="false" />
        </bpmndi:BPMNLabelStyle>
        <bpmndi:BPMNLabelStyle id="sid-76d9330e-14a4-4bbb-83a1-45a6c2f8a30d">
          <omgdc:Font name="Arial" size="12" isBold="false" isItalic="false" isUnderline="false" isStrikeThrough="false" />
        </bpmndi:BPMNLabelStyle>
      </bpmndi:BPMNDiagram>
    </definitions>
    
  `);

  useEffect(() => {
    modelerRef.current = new BpmnModeler({
      container: containerRef.current,
    });

    // Load a BPMN diagram into the modeler (replace with your BPMN XML or URL)
    modelerRef.current.importXML("<Your BPMN XML or URL>", (err: any) => {
      if (err) {
        console.error("Error importing BPMN:", err);
      }
    });
  }, []);

  useEffect(() => {
    modelerRef.current.importXML(bpmnXML_Text, (err: any) => {
      if (Boolean(err) === true) {
        customizedToast("فایل ارسالی به صورت درست ساخته نشده", "error");
        console.error("Error importing BPMN:", err);
      } else {
        customizedToast("فایل اولیه با موفقیت بارگذاری شد", "success");
      }
    });
  }, [bpmnXML_Text]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      console.log(event.key);
      // Handle undo (Ctrl + Z)
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        modelerRef.current.get("commandStack").undo();
      }
      // Handle undo (Ctrl + Z)

      // Handle redo (Ctrl + Y)
      else if (event.ctrlKey && event.key === "y") {
        event.preventDefault();
        modelerRef.current.get("commandStack").redo();
      }
      // Handle redo (Ctrl + Y)

      // Handle delete
      else if (event.key === "Delete") {
        event.preventDefault();
        const selection = modelerRef.current.get("selection").get();
        if (selection.length > 0) {
          modelerRef.current.get("modeling").removeElements(selection);
        }
      }
      // Handle delete
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSave = async () => {
    try {
      const result = await modelerRef.current.saveXML({
        format: true,
      });
      const { xml } = result;
      setBpmnXML_Text(xml);

      setIsModalInScreen(true);
      console.log(xml);
    } catch (err: any) {
      console.log(err);
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  };

  //   const handleLoad = () => {
  //     modelerRef.current.importXML(bpmnXML_Text, (err: any) => {
  //       if (err) {
  //         console.error("Error importing BPMN:", err);
  //       }
  //     });
  //   };
  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("ساخت سریع"));
  }, [dispatch]);

  return (
    <div
      className={`${
        styles.fastCreateContainer
      } w-100 d-flex flex-column align-items-center ${
        isNightModeOn ? "nightBg1" : ""
      }`}
      style={{
        position: "relative",
      }}
    >
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex w-100 flex-row align-items-center justify-content-between">
          <button
            className={` operationEvent   submitBtn `}
            onClick={handleSave}
          >
            ذخیره و مرحله بعدی
          </button>
          <button
            className={`btn btn-secondary operationEvent  `}
            onClick={() => {
              if (!inputRef.current) return;
              inputRef.current.click();
            }}
          >
            آپلود فایل جدید
          </button>
        </div>
        <input
          type="file"
          style={{
            display: "none",
          }}
          onChange={(e) => {
            const file = e.target.files![0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (event: any) => {
                const content = event.target.result;
                setBpmnXML_Text(content);
              };
              reader.readAsText(file, "UTF-8");
            }
          }}
          ref={inputRef}
          accept=".json,application/json,.bpmn,.xml,application/xml"
        />
      </div>
      <div
        ref={containerRef}
        className="bpmn-container w-100"
        style={{
          height: "600px",
        }}
      ></div>

      {/* SAVE MODAL */}
      {isModalInScreen ? (
        <FastCreateSaveModal
          setIsModalInScreen={setIsModalInScreen}
          xmlData={bpmnXML_Text}
        />
      ) : null}
      {/* SAVE MODAL */}
    </div>
  );
};

export default FastCreate;
