// React
import React, { useContext } from "react";
// React

// CSS
import styles from "./KeyValuePairShow.module.css";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// CSS

type KeyValuePairShowProps = {
  _key: {
    title: string;
    className?: string;
    style?: React.CSSProperties;
  };
  _value: {
    title: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  };
  index?: "even" | "odd";
};

const KeyValuePairShow: React.FunctionComponent<KeyValuePairShowProps> = ({
  _key,
  _value,
  index,
}) => {
  const isNightOn = useContext(IsNightModeOnContext);

  return (
    <div
      className={`${styles.keyVaLuePairContainer} ${
        isNightOn ? `${index === "even" ? "nightBg2" : "nightBg1"}` : ""
      } w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
    >
      <p
        className={`${styles.key} ${_key.className}`}
        style={{ ..._key.style }}
      >
        : {_key.title}
      </p>
      <p
        className={`${styles.value} ${_value.className}`}
        style={{ ..._value.style }}
        onClick={_value.onClick}
      >
        {_value.title}
      </p>
    </div>
  );
};

export default KeyValuePairShow;
