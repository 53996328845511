// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AccessibleTenants.module.css";
// CSS

// Redux
import {
  getAsyncAllTenants,
  tenantsSlice,
} from "../../Features/TenantsSlice/tenantsSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
// Redux

// Utils
import { CSVLink } from "react-csv";
import Pagination from "../../Utils/Pagination/Pagination";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
// Utils

// Modules
import { permitionCheckerWithCustomToast } from "../../Modules/permitionCheckerWithCustomToast";
import Swal from "sweetalert2";
// Modules

// Components
import Pending from "../../Components/Pending/Pending";
// Components

// Contexts
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Images
import { logo } from "../../Images/images";
// Images

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { mainUrl } from "../../Services/urls";
import { useSearchParams } from "react-router-dom";
// Icons

const AccessibleTenants = () => {
  const dispatch = useAppDispatch();

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const [searchParam, setSearchParam] = useSearchParams();

  const [formsData, setFormsData] = useState<{
    page: number;
    search: "";
    size: number;
    state: 1 | 2;
  }>({
    page: Number(searchParam.get("page")) || 1,
    search: "",
    size: Number(searchParam.get("size")) || 10,
    state: 2,
  });

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const tenants = useAppSelector((state) => state.tenants);

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (
      !permitionCheckerWithCustomToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
        isFullRenderd,
        ""
      )
    )
      return;
    dispatch(
      getAsyncAllTenants({
        userToken,
        _data: formsData,
      })
    );
  }, [allGroups, isFullRenderd, dispatch, formsData, userToken]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  const csvReport = {
    headers: [
      {
        label: "دامنه",
        key: "domain",
      },
      {
        label: "وضعیت",
        key: "state",
      },
      {
        label: "نام ",
        key: "title",
      },
      {
        label: "نام",
        key: "id",
      },
    ],
    data: tenants.content.map((item) => ({
      domain: item.domain,
      state: item.state.title,
      title: item.title,
      id: item.id,
    })),
    filename: "tenants.csv",
  };

  return (
    <div
      className={`${styles.accessibleTenants} w-100  ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <div
        className={`w-100 d-flex flex-row-reverse align-items-center justify-content-between ${styles.pageInfo}`}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          لیست سازمان ها
        </p>
        <div className="d-flex flex-col align-items-center">
          <CSVLink
            {...csvReport}
            style={{
              textDecoration: "none",
              color: "#fff",
              background: "#3AC150",
              marginBottom: "1rem",
              padding: "6px 10px",
              marginLeft: "8px",
              borderRadius: "8px",
            }}
          >
            csv خروجی
          </CSVLink>
        </div>
      </div>

      {tenants.isPending ? (
        <Pending className="mt-4" />
      ) : (
        <>
          {tenants.content.length === 0 ? (
            <div
              className={` mt-4 d-flex flex-column align-items-center justify-content-center`}
            >
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.domain}`}>دامنه سازمان</p>
                    <p className={`${styles.isSelectedTenant}`}>انتخاب شده</p>
                    <p className={`${styles.state}`}>وضعیت</p>
                    <p className={`${styles.mainTenant}`}>دامنه اصلی</p>
                    <p className={`${styles.logo}`}>لوگو </p>
                    <p className={`${styles.title}`}>نام سازمان</p>
                    <p className={`${styles.id}`}>شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {[
                      {
                        id: "",
                        title: "سازمان پیشفرض",
                        tenantNameForShow: "noTenantInputValue",
                        domain: window.location.hostname,
                        logo: logo,
                        state: {
                          value: "2",
                          title: "فعال",
                        },
                        demoEnvironment: "",
                        mainTenant: true,
                      },
                      ...tenants.content,
                    ].map(
                      (
                        {
                          demoEnvironment,
                          domain,
                          id,
                          logo,
                          mainTenant,
                          state,
                          title,
                        },
                        index
                      ) => (
                        <div
                          key={id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p
                            className={`${styles.operationTab} operationEvent submitBtn`}
                            onClick={() => {
                              if (Number(state.value) !== 2) {
                                customizedToast("سازمان غیر فعال است", "info");
                                return;
                              }
                              Swal.fire({
                                icon: "question",
                                title: `از سازمان "${title}" استفاده شود ؟`,
                                showCancelButton: true,
                                cancelButtonText: "لغو",
                                confirmButtonText: "تایید",
                                customClass: isNightOn ? "swalNight" : "",
                              }).then(({ isConfirmed }) => {
                                if (!isConfirmed) return;
                                dispatch(
                                  tenantsSlice.actions.setSelectedTenant({
                                    tenantTitle: title,
                                    tenantId: id,
                                    tenantImage:
                                      id === "" ? logo : `${mainUrl}${logo}`,
                                  })
                                );
                                customizedToast(
                                  "سازمان با موفقیت به عنوان سازمان اصلی ثبت شد",
                                  "success"
                                );
                              });
                            }}
                          >
                            استفاده از این سازمان
                          </p>
                          <p className={`${styles.domain}`}>{domain}</p>
                          <p className={`${styles.isSelectedTenant}`}>
                            {id === selectedTenant.tenantId ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.state}`}>{state.title}</p>
                          <p className={`${styles.mainTenant}`}>
                            {mainTenant ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.logo}`}>
                            {Boolean(logo) ? (
                              <img
                                src={
                                  index === 0 ? `${logo}` : `${mainUrl}${logo}`
                                }
                                alt=""
                              />
                            ) : (
                              "ندارد"
                            )}
                          </p>
                          <p className={`${styles.title}`}>{title}</p>
                          <p className={`${styles.id}`}>{id}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
                >
                  <>
                    {[
                      {
                        id: "",
                        title: "سازمان پیشفرض",
                        tenantNameForShow: "noTenantInputValue",
                        domain: window.location.hostname,
                        logo: logo,
                        state: {
                          value: "2",
                          title: "فعال",
                        },
                        demoEnvironment: "",
                        mainTenant: true,
                      },
                      ...tenants.content,
                    ].map(
                      (
                        {
                          demoEnvironment,
                          domain,
                          id,
                          logo,
                          mainTenant,
                          state,
                          title,
                        },
                        index
                      ) => (
                        <div key={id} className="w-100">
                          <div
                            className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                          >
                            <p>{title}</p>
                            <p>نام سازمان </p>
                          </div>
                          <div
                            className={`${styles.tableRow} ${
                              isNightOn ? "nightBg2" : ""
                            } w-100 d-flex align-items-center justify-content-between `}
                          >
                            <p>
                              <span>سازمان انتخاب شده ؟</span>
                              <span>
                                {id === selectedTenant.tenantId ? (
                                  <MdDone
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  />
                                ) : (
                                  <BsDashLg
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  />
                                )}
                              </span>
                            </p>
                            <p>
                              <span>سازمان اصلی</span>
                              <span>
                                {mainTenant ? (
                                  <MdDone
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  />
                                ) : (
                                  <BsDashLg
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  />
                                )}
                              </span>
                            </p>
                            <p>
                              <span>لوگو</span>
                              <span>
                                {Boolean(logo) ? (
                                  <img
                                    src={
                                      index === 0
                                        ? `${logo}`
                                        : `${mainUrl}${logo}`
                                    }
                                    alt=""
                                  />
                                ) : (
                                  "ندارد"
                                )}
                              </span>
                            </p>
                            <p>
                              <span>عملیات</span>
                              <button
                                className="operationEvent submitBtn"
                                onClick={() => {
                                  if (Number(state.value) !== 2) {
                                    customizedToast(
                                      "سازمان غیر فعال است",
                                      "info"
                                    );
                                    return;
                                  }
                                  Swal.fire({
                                    icon: "question",
                                    title: `از سازمان "${title}" استفاده شود ؟`,
                                    showCancelButton: true,
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "تایید",
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      tenantsSlice.actions.setSelectedTenant({
                                        tenantTitle: title,
                                        tenantId: id,
                                        tenantImage:
                                          id === ""
                                            ? logo
                                            : `${mainUrl}${logo}`,
                                      })
                                    );
                                    customizedToast(
                                      "سازمان با موفقیت به عنوان سازمان اصلی ثبت شد",
                                      "success"
                                    );
                                  });
                                }}
                              >
                                انتخاب سازمان
                              </button>
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </>
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: tenants.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({ ...prevState, size }));
          setFormsData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={formsData.search}
      />
    </div>
  );
};

export default AccessibleTenants;
