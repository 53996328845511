// React
import React from "react";
// React

// CSS
import styles from "./HelpCenter.module.css";
// CSS

const HelpCenter = () => {
  return <div className={`${styles.helpCenterContainer}`}>HelpCenter</div>;
};

export default HelpCenter;
