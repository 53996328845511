// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../../Models/interfaces";
// Models

export const addNewPostService = (_data: {
  userToken: string;
  _data: {
    tenantId: string;
    placementType: string;
    active: boolean;
    data: string;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(`/posts/add`, _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
