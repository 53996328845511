// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./Sidebar.module.css";
// CSS

// Images
import { logo, separator } from "../../Images/images";
// Images

// Redux
import { useAppSelector } from "../../app/store";
// Redux

// Modules
import { NavLink } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Modules

// Context
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsSidebarOpenContext } from "../../Contexts/IsSidebarOpen/IsSidebarOpenContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// import { ExecutingPermissionsContext } from "../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
// Context

// Hooks
import useSidebarItems from "../../Hooks/SidebarHooks/useSidebarItems";
// Hooks

const Sidebar = () => {
  const { currLanguage } = useAppSelector((state) => state.currLanguage);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const [loggedIn, setLoggedIn] = useState(false);
  console.log(loggedIn);

  const isSidebarOpen = useContext(IsSidebarOpenContext);

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const [_isAllItemsShow, set_IsAllItemsShow] = useState<boolean>(false);

  const isNigthModeOn = useContext(IsNightModeOnContext);
  // const executePermissions = useContext(ExecutingPermissionsContext);

  const sidebarItems = useSidebarItems(currLanguage);

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const userIsLoggedIn = allGroups.length > 0;
    setLoggedIn(userIsLoggedIn);
  }, [allGroups, isFullRenderd, sidebarItems]);

  useEffect(() => {
    if (_isAllItemsShow) {
      set_IsAllItemsShow(!isSidebarOpen);
      return;
    }
    const timeOut = setTimeout(() => {
      set_IsAllItemsShow(!isSidebarOpen);
    }, 200);

    return () => {
      clearTimeout(timeOut);
    };
  }, [isSidebarOpen, _isAllItemsShow]);

  return (
    <div
      className={`${styles.sidebar} ${isSidebarOpen ? styles.melo : ""} ${
        isNigthModeOn ? styles.dark : ""
      } ${currLanguage !== "fa" && styles.englishStyles}`}
      style={{
        overflowY: "auto",
      }}
      ref={sidebarRef}
    >
      <div
        className={`${styles.logoContainer} w-100 d-flex flex-row-reverse align-items-center justify-content-start`}
      >
        <img
          src={
            selectedTenant.tenantId === "" ? logo : selectedTenant.tenantImage
          }
          alt=""
        />
        <span className={`${styles.samfonaName}`}>
          {selectedTenant.tenantId === ""
            ? "سمفونا"
            : selectedTenant.tenantTitle}
        </span>
      </div>

      <img src={separator} alt="" className={`${styles.seprator}`} />

      {sidebarItems
        .filter(
          (item) =>
            permitionCheckerWithoutToast(
              allGroups,
              item.requierdPerimitions,
              isFullRenderd
            ) === true
        )
        .map((item) => (
          <NavLink
            to={item.path}
            key={item.path}
            className={({ isActive }) => {
              return `${styles.sidebarItem} ${
                isActive ? styles.activeRoute : ""
              } ${!isSidebarOpen ? "" : styles.mini}`;
            }}
            style={({ isActive }) => ({
              background: isActive
                ? `${isNigthModeOn ? "#505050" : "#F6F9FC"}`
                : "transparent",
            })}
            // onClick={() => {
            //   set_IsAllItemsShow(false);
            //   setIsSidebarOpen(true);
            // }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={!_isAllItemsShow ? item.title : ""}
          >
            {item.logo}
            {_isAllItemsShow ? <span>{item.title}</span> : ""}
          </NavLink>
        ))}

      {/* <img src={separator} alt="" className={`${styles.seprator}`} />
      <div
        className={`${styles.documentContainer} ${
          isSidebarOpen ? styles.close : ""
        } w-100 d-flex flex-column align-items-center`}
      >
        <img src={document} alt="document" />
        <p className={`${styles.help}`}>نیاز به راهنمایی دارید ؟</p>
        <p
          className={`${styles.click}`}
          onClick={() => {
            navigate("/help-center");
          }}
        >
          اینجا کلیک کنید
        </p>
      </div> */}
    </div>
  );
};

export default Sidebar;
