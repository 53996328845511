// _axios
import { _axios } from "../../Base_Axios_Config/_axios";
// _axios

// Models
import { I_EditUserAccessibleProcess } from "../../../Models/sendingDataSchema";
// Models

export const editUserAccessibleProcessService = async (
  userToken: string,
  username: string,
  data: I_EditUserAccessibleProcess
) => {
  return _axios.post(`/users/${username}/accessible-processes/edit`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
