// React
import React, { useContext, useEffect, useMemo, useState } from "react";
// React

// CSS
import styles from "./ShowInstanceDataByInstanceId.module.css";
// CSS

// Modules
import { useNavigate, useParams } from "react-router-dom";
import { permissionChecker } from "../../../Modules/permissionChecker";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncInstanceProcessXmlData,
  processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
// Redux

// Components
import ShowBpmnByXmlData from "../../../Components/ShowBpmnByXmlData/ShowBpmnByXmlData";
// import Accardion from "./Accardion/Accardion";
// Components

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Contexts

// Utils
import { Spinner } from "reactstrap";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Utils

const ShowInstanceDataByInstanceId = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instanceId } = useParams();

  const { processInstanceXmlDataPending, isError } = useAppSelector(
    (state) => state.processes.processInstanceData
  );

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const instanceData = useAppSelector(
    (state) => state.processes.processInstanceData.processInstanceSchema
  );

  const allGroups = useContext(UserGroupsContext);

  const [bgDoneColor, setBgDoneColor] = useState<string>("#66ff00");
  const [textDoneColor, setTextDoneColor] = useState<string>("#ff0000");

  const isFullRenderd = useContext(IsFullRenderdContext);

  useEffect(() => {
    if (
      !permissionChecker(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Full_Manager",
          "Running_Process_Manager",
        ],
        isFullRenderd
      ) ||
      !(
        allGroups.includes("Running_Process_Manager") &&
        allGroups.includes("Process_Schema_Analyzer")
      )
    )
      return;
    dispatch(
      getAsyncInstanceProcessXmlData({
        instanceId: String(instanceId),
        userToken,
        onError: () => {
          navigate("/");
        },
      })
    );
  }, [dispatch, instanceId, userToken, navigate, allGroups, isFullRenderd]);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetProcessInstanceData());
    };
  }, [dispatch]);

  const __bpmn__ = useMemo(
    () => (
      <ShowBpmnByXmlData
        xmlData={instanceData.bpmnXml}
        currentStateIdContainer={
          // instanceData?.activeTask?.schemaId ||
          ""
        }
        className={`mt-4`}
        colorControlling={{
          bgDoneColor,
          setBgDoneColor,
          setTextDoneColor,
          textDoneColor,
        }}
        instanceId={instanceId as string}
      />
    ),
    [instanceData, bgDoneColor, textDoneColor, instanceId]
  );

  return (
    <div className={`${styles.showInstanceDataContainer}`}>
      {processInstanceXmlDataPending && !isError ? (
        <div className="d-flex flex-column w-100 align-items-center justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : null}

      {!processInstanceXmlDataPending && isError ? (
        <div className="d-flex flex-column w-100 align-align-items-center justify-content-center">
          <p>مشکلی پیش آمده</p>
          {permitionCheckerWithoutToast(
            allGroups,
            [
              "Tenant_Full_Manager",
              "Tenant_Basic_Manager",
              "Process_Full_Manager",
            ],
            isFullRenderd
          ) ||
          (allGroups.includes("Running_Process_Manager") &&
            allGroups.includes("Process_Schema_Analyzer")) ? (
            <button
              className="operationEvent deleteBtn"
              onClick={() => {
                dispatch(
                  getAsyncInstanceProcessXmlData({
                    instanceId: String(instanceId),
                    userToken,
                    onError: () => {
                      navigate(-1);
                    },
                  })
                );
              }}
            >
              تلاش مجدد
            </button>
          ) : null}
        </div>
      ) : null}

      {/* {dataForShow} */}

      {__bpmn__}
    </div>
  );
};

export default ShowInstanceDataByInstanceId;
