export const includesChecker = (
  permissionArray: string[],
  selectedArray: string[]
): boolean => {
  const lowercasePermissionArray = permissionArray.map((item) =>
    item.toLowerCase()
  );

  return selectedArray
    .map((item) => item.toLowerCase())
    .some((item) => lowercasePermissionArray.includes(item));
};
