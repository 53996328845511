// React
import React from "react";
// React

// CSS
import styles from "./CreateSubAttributeForSingleIdentityAttribute.module.css";
// CSS

const CreateSubAttributeForSingleIdentityAttribute = () => {
  return (
    <div className={`${styles.createSubIdentityContainer}`}>
      CreateSubAttributeForSingleIdentityAttribute
    </div>
  );
};

export default CreateSubAttributeForSingleIdentityAttribute;
