// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_GetCallOfRegistrationSendingData } from "../../../Models/sendingDataSchema";
import { AxiosResponse } from "axios";
import {
  I_CallOfRegistrationRecevedData,
  I_Message,
} from "../../../Models/interfaces";
// Models

export const getAllCallOfRegistrationService = async (_data: {
  userToken: string;
  _data: I_GetCallOfRegistrationSendingData;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_CallOfRegistrationRecevedData;
  }>
> => {
  return _axios.get(
    `/call-of-registrations?page=${_data._data.page}&size=${_data._data.size}&tenant=${_data._data.tenant}&forEditing=${_data._data.forEditing}&active=${_data._data.active}&search=${_data._data.search}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
