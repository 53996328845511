// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_RemoveProcessByVersionSendingData } from "../../../Models/sendingDataSchema";
import { I_Message } from "../../../Models/interfaces";
// Models

export const removeProcessByVersionService = (_data: {
  userToken: string;
  _data: I_RemoveProcessByVersionSendingData;
}): Promise<
  AxiosResponse<{
    messages: I_Message[];
  }>
> => {
  return _axios.post(
    `/processes/${_data._data.processKey}/delete-version`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
