// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../Models/interfaces";
import { UserIdentitiesWithOptions } from "../../Models/types";
// Models

export const getAllIdentityAttributeWithTheirOptionsService = async (_data: {
  userToken: string;
  tenantId: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: UserIdentitiesWithOptions[];
  }>
> => {
  return _axios.get(
    `/users/identity/attributes/with-options?tenant=${_data.tenantId}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
