// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./EditServerModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncServerData,
  serverManagementSlice,
} from "../../../Features/ServerManagementSlice/ServerManagementSlice";
// Redux

// Modules
// Modules

// Utils
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

type EditServerModalProps = {
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  serverName: string;
  isCentral: boolean;
  serverId: string;
  afterOrationCallback: () => void;
};

const EditServerModal: React.FunctionComponent<EditServerModalProps> = ({
  setIsModalInScreen,
  isCentral,
  serverName,
  serverId,
  afterOrationCallback,
}) => {
  const dispatch = useAppDispatch();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const editServerData = useAppSelector(
    (state) => state.servers.editingServerStatus
  );

  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const [formsData, setFormsData] = useState<{
    setAsCentralServer: boolean;
    serverAddress: string;
  }>({
    setAsCentralServer: isCentral,
    serverAddress: serverName,
  });

  const closeModal = useCallback(() => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  }, [setIsModalInScreen]);

  useEffect(() => {
    if (!editServerData.isDone) return;

    closeModal();

    return () => {
      dispatch(serverManagementSlice.actions.resetServerEditState());
    };
  }, [editServerData.isDone, closeModal, dispatch]);

  return (
    <div
      className={`${styles.editServerModalContainer}  d-flex align-items-center justify-content-center`}
    >
      <div className={`${styles.bgRef}`} onClick={closeModal} ref={bgRef}></div>
      <div
        className={`${styles.content} ${
          isNightModeOn ? "nightBg2" : ""
        } d-flex flex-column align-items-center `}
        ref={contentRef}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              serverAddress: e.target.value,
            }));
          }}
          dontShowSearchIcon
          placeHolder="آدرس سرور"
          //  keyForShowOnTop="آدرس سرور"
          value={formsData.serverAddress}
          defaultValue={formsData.serverAddress}
        />
        <CustomCheckbox
          isChecked={formsData.setAsCentralServer}
          onChange={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              setAsCentralServer: !prevState.setAsCentralServer,
            }));
          }}
          onClick={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              setAsCentralServer: !prevState.setAsCentralServer,
            }));
          }}
          title="تنظیم به عنوان سرور مرکزی"
          containerClassName="w-100 mt-4"
        />

        <button
          className="operationEvent submitBtn align-self-start "
          onClick={() => {
            dispatch(
              editAsyncServerData({
                _data: formsData,
                serverId,
                userToken,
              })
            ).then(() => {
              afterOrationCallback();
            });
          }}
        >
          {editServerData.isPending ? <Spinner /> : <>تایید</>}
        </button>
      </div>
    </div>
  );
};

export default EditServerModal;
