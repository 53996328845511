// React
import React from "react";
// React

// CSS
import styles from "./UserPersonalInformation.module.css";
// CSS

// Utils
import CustomInput2 from "../../../../Utils/CustomInput2/CustomInput2";
// Utils

// Models
import { I_UserData } from "../../../../Models/interfaces";
// Models

// Themes
import ProfileIdentitiesTab from "../../ProfileIdentitiesTab/ProfileIdentitiesTab";
// Themes

type UserPersonalInformationProps = {
  setUserDataForOverWrite: React.Dispatch<React.SetStateAction<I_UserData>>;
  userDataForOverWrite: I_UserData;
};

const UserPersonalInformation: React.FunctionComponent<
  UserPersonalInformationProps
> = ({ setUserDataForOverWrite, userDataForOverWrite }) => {
  return (
    <>
      <div className="w-100 d-flex flex-column align-items-center " style={{}}>
        <div
          className={`w-100 d-flex align-items-center justify-content-between flex-row-reverse flex-wrap ${styles.userInformationRow}`}
        >
          <CustomInput2
            defaultValue={userDataForOverWrite.firstname}
            onBlur={(e) => {
              setUserDataForOverWrite((prevState) => ({
                ...prevState,
                firstname: e.target.value,
              }));
            }}
            plaeHolder="برای مثال: هاشمی"
            title="نام "
            type="text"
          />
          <CustomInput2
            defaultValue={userDataForOverWrite.lastname}
            onBlur={(e) => {
              setUserDataForOverWrite((prevState) => ({
                ...prevState,
                lastname: e.target.value,
              }));
            }}
            plaeHolder="برای مثال: هاشمی"
            title="نام خانوادگی"
            type="text"
          />
          <CustomInput2
            defaultValue={userDataForOverWrite.email}
            onBlur={(e) => {
              setUserDataForOverWrite((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            plaeHolder="برای مثال: abcd.1234@gmail.com"
            title="ایمیل"
            type="email"
          />
          {/* <CustomInput2
            defaultValue={userDataForOverWrite.email}
            onBlur={() => {}}
            plaeHolder={
              userDataForOverWrite.tenantOwner ? "دارای دسترسی" : "بدون دسترسی"
            }
            title={`وضعیت دسترسی به سازمان`}
            type="text"
            isDisabled
          /> */}
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/* <button
          className=" mt-5 py-2 btn btn-primary align-self-start"
          style={{
            background: themeColor,
          }}
          onClick={() => {
            if (userInformation.isUserInformationChangePending) return;
            dispatch(
              editAsyncUserData({
                _data: {
                  firstname: userDataForOverWrite.firstname,
                  lastname: userDataForOverWrite.lastname,
                  email: userDataForOverWrite.email,
                },
                userToken,
              })
            ).then(() => {
              dispatch(
                getAsyncUserData({
                  userToken,
                })
              );
            });
          }}
        >
          {userInformation.isUserInformationChangePending ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              <BsChevronLeft
                style={{
                  marginRight: "8px",
                }}
              />
              <span>تایید </span>
            </>
          )}
        </button> */}
      </div>
      <ProfileIdentitiesTab userDataForOverWrite={userDataForOverWrite} />
    </>
  );
};

export default UserPersonalInformation;
