import { customizedToast } from "./CustomizedToast/customizedToast";

export const copyToClipboard = async (text: string) => {
  navigator.clipboard
    .writeText(String(text))
    .then(() => {
      customizedToast("با موفقیت کپی شد", "success");
    })
    .catch(() => {
      customizedToast("دسترسی به کلیپ بورد محدود شده", "error");
    });
};
