// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AddNewPost.module.css";
// CSS

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  addAsyncNewPost,
  postsSlice,
} from "../../../Features/PostsSlice/postsSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Editor
import { Editor } from "primereact/editor";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Editor

// Formats
import { scriptFormat } from "../../../Formats/formats";
// Formats

// Constants
import { scriptTagNameInExportOfEditor } from "../../../Constants/constants";
// Constants

const AddNewPost = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formsData, setFormsData] = useState<{
    placementType: string;
    active: boolean;
    postData: string;
  }>({
    placementType: "LOGIN_PAGE",
    active: true,
    postData: "",
  });
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { postPlacementTypes } = useAppSelector((state) => state.allRoles.data);

  const { isPending, isDone } = useAppSelector(
    (state) => state.posts.addingSinglePost
  );

  const isNightModeOn = useContext(IsNightModeOnContext);

  // Life Cycles
  useEffect(() => {
    if (!isDone) return;

    navigate(`/posts`);
  }, [isDone, navigate]);
  //
  useEffect(() => {
    return () => {
      dispatch(postsSlice.actions.resetAddingPostState());
    };
  }, [dispatch]);
  // Life Cycles

  return (
    <div
      className={`${styles.addNewPostContainer} ${
        isNightModeOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center flex-wrap`}
    >
      <div className="w-100 d-flex flex-row align-items-center justify-content-between ">
        <button
          className="operationEvent submitBtn"
          onClick={() => {
            dispatch(
              addAsyncNewPost({
                userToken,
                _data: {
                  active: formsData.active,
                  data: formsData.postData.replaceAll(
                    scriptFormat,
                    scriptTagNameInExportOfEditor
                  ),
                  placementType: formsData.placementType,
                  tenantId: selectedTenant.tenantId,
                },
              })
            );
          }}
        >
          {isPending ? <Spinner /> : "تایید"}
        </button>
        <p
          className=""
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          افزودن اعلان
        </p>
      </div>

      <div
        className={`w-100 d-flex flex-row align-items-center justify-content-between flex-wrap-reverse mt-5`}
        style={{
          gap: "8px",
        }}
      >
        <CustomCheckbox
          isChecked={formsData.active}
          onChange={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              active: !prevState.active,
            }));
          }}
          onClick={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              active: !prevState.active,
            }));
          }}
          title="فعال"
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 mt-4"
          }`}
          titleClassName={"mb-0"}
        />
        <CustomInput1
          inputType="text"
          value={selectedTenant.tenantTitle}
          defaultValue={selectedTenant.tenantTitle}
          onChange={() => false}
          onBlur={() => false}
          placeHolder="نام سازمان"
          dontShowSearchIcon
          disabled
        />

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState) => ({
              ...prevState,
              placementType: String(data),
            }));
          }}
          initialTitle="محل قرارگیری : صفحه لاگین"
          initialValue={"LOGIN_PAGE"}
          options={Object.keys(postPlacementTypes).map((_item) => ({
            title: postPlacementTypes[_item].title,
            value: postPlacementTypes[_item].value,
          }))}
        />
      </div>
      <Editor
        value={formsData.postData}
        onTextChange={(e: any) =>
          setFormsData((prevState) => ({
            ...prevState,
            postData: e.htmlValue,
          }))
        }
        style={{ height: "320px" }}
      />
    </div>
  );
};

export default AddNewPost;
