// React
import React, { useCallback, useContext, useRef, useState } from "react";
// React

// CSS
import styles from "./PasswordsTab.module.css";
// CSS

// Utils
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
import { Spinner } from "reactstrap";
import CustomInput2 from "../../../../Utils/CustomInput2/CustomInput2";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { changeAsyncPassword } from "../../../../Features/UserInformationSlice/userInformationSlice";
// Redux

// Themes
import { themeColor } from "../../../../Constants/constants";
import { SetUserGroupsContext } from "../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { loginSlice } from "../../../../Features/LoginSlice/loginSlice";
import { userPasswordPattern } from "../../../../Formats/formats";
// Themes

type PasswordsTabProps = {
  setPasswordData: React.Dispatch<
    React.SetStateAction<{ oldPassword: string; newPassword: string }>
  >;
  passwordData: { oldPassword: string; newPassword: string };
};

const PasswordsTab: React.FunctionComponent<PasswordsTabProps> = ({
  passwordData,
  setPasswordData,
}) => {
  const dispatch = useAppDispatch();
  const userInformation = useAppSelector((state) => state.userInformation);

  const confirmPasswordRef = useRef<string>("");

  const setUserGroups = useContext(SetUserGroupsContext);

  const [errorsAndTouchs, setErrorsAndTouches] = useState<{
    errors: {
      oldPassword: boolean;
      newPassword: boolean;
      confirmPassword: boolean;
    };
    touches: {
      oldPassword: boolean;
      newPassword: boolean;
      confirmPassword: boolean;
    };
  }>({
    errors: {
      confirmPassword: false,
      newPassword: false,
      oldPassword: false,
    },
    touches: {
      confirmPassword: false,
      newPassword: false,
      oldPassword: false,
    },
  });

  const passwordChecker = useCallback(
    (
      _key: "oldPassword" | "confirmPassword" | "newPassword",
      value: boolean
    ) => {
      setErrorsAndTouches((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [_key]: value,
        },
        touches: {
          ...prevState.touches,
          [_key]: true,
        },
      }));
    },
    []
  );

  return (
    <div className="w-100 d-flex flex-column ">
      <div
        className={`${styles.passwordChangeContainer} w-100 d-flex flex-wrap align-items-center justify-content-between `}
      >
        <CustomInput2
          defaultValue={confirmPasswordRef.current}
          onBlur={(e) => {
            passwordChecker(
              "confirmPassword",
              !userPasswordPattern.test(e.target.value)
            );
            confirmPasswordRef.current = e.target.value;
          }}
          plaeHolder="تکرار رمز عبور جدید"
          title="تکرار رمز عبور جدید"
          type="password"
          errors={{
            errorMessage:
              errorsAndTouchs.errors.confirmPassword &&
              errorsAndTouchs.touches.confirmPassword
                ? [
                    "رمز وارد شده را بررسی کنید . رمز ارسالی باید حداقل 8 کاراکتر که شامل 1 عدد و 1 حرف بزرگ و 1 حرف کوچک باشد و 1 نماد خاص",
                  ]
                : [],
          }}
        />
        <CustomInput2
          defaultValue={passwordData.newPassword}
          onBlur={(e) => {
            passwordChecker(
              "newPassword",
              !userPasswordPattern.test(e.target.value)
            );
            setPasswordData((prevState) => ({
              ...prevState,
              newPassword: e.target.value,
            }));
          }}
          plaeHolder="رمز عبور جدید"
          title="رمز عبور جدید"
          type="password"
          errors={{
            errorMessage:
              errorsAndTouchs.errors.newPassword &&
              errorsAndTouchs.touches.newPassword
                ? [
                    "رمز وارد شده را بررسی کنید . رمز ارسالی باید حداقل 8 کاراکتر که شامل 1 عدد و 1 حرف بزرگ و 1 حرف کوچک باشد و 1 نماد خاص",
                  ]
                : [],
          }}
        />
        <CustomInput2
          defaultValue={passwordData.oldPassword}
          onBlur={(e) => {
            console.log(userPasswordPattern.test(e.target.value));
            passwordChecker(
              "oldPassword",
              !userPasswordPattern.test(e.target.value)
            );
            setPasswordData((prevState) => ({
              ...prevState,
              oldPassword: e.target.value,
            }));
          }}
          plaeHolder="رمز عبور قدیمی"
          title="رمز عبور قدیمی"
          type="password"
          errors={{
            errorMessage:
              errorsAndTouchs.errors.oldPassword &&
              errorsAndTouchs.touches.oldPassword
                ? [
                    "رمز وارد شده را بررسی کنید . رمز ارسالی باید حداقل 8 کاراکتر که شامل 1 عدد و 1 حرف بزرگ و 1 حرف کوچک باشد و 1 نماد خاص",
                  ]
                : [],
          }}
        />
      </div>

      <button
        className="py-2 btn btn-primary mt-4 align-self-start"
        onClick={() => {
          if (passwordData.oldPassword.trim().length === 0) {
            customizedToast("ارسال رمز عبور قدیمی الزامی است", "info");
            return;
          }
          if (passwordData.newPassword.trim().length === 0) {
            customizedToast("ارسال رمز عبور جدید الزامی است", "info");
            return;
          }
          if (passwordData.newPassword !== confirmPasswordRef.current) {
            customizedToast("رمزعبور و تکرار آن یکسان نیست", "info");
            return;
          }
          dispatch(
            changeAsyncPassword({
              ...passwordData,
              onDone: () => {
                setTimeout(() => {
                  setUserGroups([]);
                  customizedToast(
                    "به علت تغییر رمز باید دوباره وارد شوید",
                    "info"
                  );
                  dispatch(loginSlice.actions.logout());
                }, 2000);
              },
            })
          );
        }}
        style={{
          background: themeColor,
        }}
      >
        {userInformation.isPasswordChangePending ? (
          <>
            <Spinner
            //   style={{
            //     width: "10px",
            //     height: "10px",
            //   }}
            />
          </>
        ) : (
          <>تایید</>
        )}
      </button>
    </div>
  );
};

export default PasswordsTab;
