export const addPersianDatePicker_EVENT = (): void => {
  const els = document.querySelectorAll('[data-jdp="true"]');

  els.forEach((item) => {
    // item.addEventListener("change", (ev: any) => {
    //   localStorage.setItem("date", JSON.stringify(ev.target.value));
    //   localStorage.setItem("id", JSON.stringify(ev.target.id));
    //   const el: any = document.getElementById(
    //     JSON.parse(localStorage.getItem("id")!)
    //   );
    //   const _JalaliDate: any = JalaliDate;

    //   if (el.value.split("/").length !== 3) {
    //     alert("x");

    //     // const str = `
    //     // T${
    //     //   String(hour).length === 1 ? `0${hour}` : hour
    //     // }:${String(minute).length === 1 ? `0${minute}` : minute}:${
    //     //   String(second).length === 1 ? `0${second}` : second
    //     // }`

    //     // const x = `${year}/${
    //     //   String(month).length === 1 ? `0${month}` : month
    //     // }/${String(day).length === 1 ? `0${day}` : day}`

    //     setTimeout(() => {
    //       const el: any = document.getElementById(
    //         JSON.parse(localStorage.getItem("id")!)
    //       );
    //       el.value = `2000/01/01`;
    //     }, 200);
    //     el.value = `2000/01/01`;
    //     return `2000/01/01`;
    //   } else {
    //     return;
    //   }
    // });

    item.addEventListener("blur", (ev: any) => {
      localStorage.setItem("date", JSON.stringify(ev.target.value));
      localStorage.setItem("id", JSON.stringify(ev.target.id));
      const el: any = document.getElementById(
        JSON.parse(localStorage.getItem("id")!)
      );

      console.log(el.value);
    });
    item.addEventListener("focus", (ev: any) => {
      ev.target.value = "";
    });
  });
};

export const removePersianDatePicker_EVENT = (e_function: EventListener) => {
  const els = document.querySelectorAll('[data-jdp="true"]');
  els.forEach((item) => {
    item.removeEventListener("change", e_function);
  });
};
