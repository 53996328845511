// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_Message,
  I_RecevedTasksByProcessKey,
} from "../../../Models/interfaces";
// Models

export const getProcessTasksServices = (_data: {
  _data: {
    page: number;
    size: number;
    orderByDueDate: boolean;
    orderByFollowUpDate: boolean;
    orderByPriority: boolean;
    descendingSort: boolean;
    tenant: string;
    userName: string;
    processKey: string;
    search: string;
  };
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_RecevedTasksByProcessKey;
  }>
> => {
  const {
    descendingSort,
    orderByDueDate,
    orderByFollowUpDate,
    orderByPriority,
    page,
    processKey,
    size,
    tenant,
    userName,
  } = _data._data;

  function nullChecker(input: any) {
    if (Boolean(input) === false) return false;

    return input;
  }

  return _axios.get(
    `/processes/tasks?page=${nullChecker(page)}&size=${nullChecker(
      size
    )}&orderByDueDate=${nullChecker(
      orderByDueDate
    )}&orderByFollowUpDate=${nullChecker(
      orderByFollowUpDate
    )}&orderByPriority=${nullChecker(
      orderByPriority
    )}&descendingSort=${nullChecker(
      descendingSort
    )}&tenant=${tenant}&processKey=${processKey}&username=${userName}
    &search=${_data._data.search}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
