// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_PossibleGroupsForCurrUserSendingData } from "../../Models/sendingDataSchema";
import { I_PossibleAccessibleGroupForCurrUser } from "../../Models/interfaces";
// Models

// Services
import { getPossibleGroupsForCurrUserToSubscribeOtherInItService } from "../../Services/Users/get/getPossibleGroupsForCurrUserToSubscribeOtherInItService";
// Services

// Utils
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Utils

const initialState: I_PossibleAccessibleGroupForCurrUser & {
  isPending: boolean;
  isError: boolean;
} = {
  isPending: false,
  pageNumber: 0,
  pageSize: 0,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],
  isError: false,
};

export const getAsyncPossibleGroupMembershipSlice = createAsyncThunk(
  "possibleGroupMembership/getPossibleGroupMembership",
  async (_data: {
    userToken: string;
    searchQuery: I_PossibleGroupsForCurrUserSendingData;
  }) => {
    try {
      const { data } =
        await getPossibleGroupsForCurrUserToSubscribeOtherInItService(
          _data.userToken,
          _data.searchQuery
        );

      return data.result;
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const possibleGroupMemberShipsSlice = createSlice({
  name: "possibleGroupMembership",
  initialState,
  reducers: {
    resetPossiblePermition: (state) => {
      state.content = resetReferenceOfObject(initialState.content);
      state.isPending = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAsyncPossibleGroupMembershipSlice.pending,
      (state, action) => {
        state.isPending = true;
        state.isError = false;
      }
    );
    builder.addCase(
      getAsyncPossibleGroupMembershipSlice.fulfilled,
      (state, action) => {
        state.isPending = false;
        state.pageNumber = action.payload!.pageNumber;
        state.pageSize = action.payload!.pageSize;
        state.totalPages = action.payload!.totalPages;
        state.totalElements = action.payload!.totalElements;
        state.hasNextPage = action.payload!.hasNextPage;
        state.content = action.payload!.content;
        state.isError = false;
      }
    );
    builder.addCase(
      getAsyncPossibleGroupMembershipSlice.rejected,
      (state, action) => {
        state.isPending = false;
        state.isError = true;
      }
    );
  },
});
