import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message, I_UserData } from "../../../Models/interfaces";

export const getUserProfileService = async (
  userToken: string
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_UserData;
  }>
> => {
  return _axios.get("/profile", {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
