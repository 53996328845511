// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_SingleUserData } from "../../../Models/interfaces";
// Models

export const getSingleAccessibleUserDataService = async (
  userToken: string,
  username: string
): Promise<
  AxiosResponse<{
    error: 0;
    messages: {
      message: string;
      extras: {
        informationType: string;
        information: string;
      }[];
    };
    result: I_SingleUserData;
  }>
> => {
  return _axios.get(`/users/${username}/detail`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
