// Redux
import { createSlice } from "@reduxjs/toolkit";
// Redux

// Language Data
import { language } from "../../Language/language";
// Language Data

// Types
import { LanguageType } from "../../Models/types";
// Types

const initialState: {
  currLanguage: LanguageType;
  language: {
    [key: string]: string;
  };
} = {
  currLanguage: JSON.parse(
    localStorage.getItem("currLanguage") || JSON.stringify("fa")
  ),
  language:
    JSON.parse(localStorage.getItem("currLanguage") || JSON.stringify("fa")) ===
    "fa"
      ? language.fa
      : language.en,
};

export const currLanguageSlice = createSlice({
  name: "currLanguage",
  initialState,
  reducers: {
    toFa: (state) => {
      state.currLanguage = "fa";
      localStorage.setItem("currLanguage", JSON.stringify("fa"));
      state.language = language.fa;
    },
    toEn: (state) => {
      state.currLanguage = "en";
      localStorage.setItem("currLanguage", JSON.stringify("en"));
      state.language = language.en;
    },
  },
});

const {
  actions: { toEn, toFa },
} = currLanguageSlice;

export { toEn, toFa };
