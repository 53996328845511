// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./FormSamples.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  formSamplesSlice,
  getAsyncFormSamples,
  removeAsyncSingleFormSample,
} from "../../Features/FormSamples/formSamplesSlice";
// Redux

// Utils
import Pagination from "./../../Utils/Pagination/Pagination";
import { Spinner } from "reactstrap";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import Swal from "sweetalert2";
// Utils

// Models
import { I_SendingDataForGettingFormsSample } from "../../Models/sendingDataSchema";
// Models

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Modules

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Icons
import { AiOutlineEye } from "react-icons/ai";
// Icons

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { permissionChecker } from "../../Modules/permissionChecker";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
// Contexts

const FormSamples = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const {
    formSamples: { data, isPending, isError },
  } = useAppSelector((state) => state.formSamples);
  // const { formType } = useAppSelector((state) => state.allRoles.data);

  const [searchParam, setSearchParam] = useSearchParams();

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const [formsData, setFormsData] =
    useState<I_SendingDataForGettingFormsSample>({
      page: Number(searchParam.get("page")) || 1,
      size: Number(searchParam.get("size")) || 10,
      type: searchParam.get("type") || "Form-IO",
      search: searchParam.get("search") || "",
      tenant: "",
      id: "",
    });

  const isNightOn = useContext(IsNightModeOnContext);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isFullRenderd) return;
    if (!isMounted) return;
    if (
      !permissionChecker(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Form_Editor",
          "Process_Full_Manager",
          "Form_Sample_Manager",
        ],
        isFullRenderd
      )
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncFormSamples({
          userToken,
          _data: {
            ...formsData,
            tenant: selectedTenant.tenantId,
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    dispatch,
    formsData,
    userToken,
    selectedTenant,
    isMounted,
    allGroups,
    isFullRenderd,
  ]);

  useEffect(() => {
    return () => {
      dispatch(formSamplesSlice.actions.resetRecevedFormSamplesState());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setFormSamplesPageData({
        pageNumber: formsData.page,
        size: formsData.size,
      })
    );
  }, [dispatch, formsData]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        type: formsData.type,
        search: formsData.search,
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  return (
    <div
      className={`w-100 d-flex flex-column align-items-center ${
        isNightOn ? "nightBg1 " : ""
      } ${styles.formSamplesContainer}`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between mb-3">
        <p
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
            margin: "unset",
          }}
        >
          لیست فرم ها
        </p>
        <button
          className="operationEvent submitBtn"
          onClick={() => {
            if (
              !permitionCheckerWithoutToast(
                allGroups,
                [
                  "Tenant_Full_Manager",
                  "Tenant_Basic_Manager",
                  "Form_Sample_Manager",
                ],
                isFullRenderd
              )
            )
              return;
            navigate("/form-samples/add-new-sample");
          }}
        >
          افزودن فرم
        </button>
      </div>

      <div
        className={`${styles.topItems} w-100 d-flex flex-row align-items-center justify-content-start flex-wrap`}
        style={{
          gap: "1rem",
        }}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={formsData.search}
          defaultValue={formsData.search}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="جستجو"
        />

        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={formsData.id}
          defaultValue={formsData.id}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              id: e.target.value,
            }));
          }}
          placeHolder="جستجوی شمارنده"
        />

        {/* <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState: any) => ({
              ...prevState,
              type: data,
            }));
          }}
          initialTitle={
            formsData.type === "Form-IO" ? "Form-IO" : "لطفا انتخاب کنید"
          }
          initialValue={"Form-IO"}
          options={Object.keys(formType).map((item) => ({
            title: formType[item].title,
            value: formType[item].value,
          }))}
        /> */}
      </div>

      {isPending && !isError ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : null}

      {isError && !isPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <button
            onClick={() => {
              dispatch(
                getAsyncFormSamples({
                  userToken,
                  _data: {
                    ...formsData,
                    tenant: selectedTenant.tenantId,
                  },
                })
              );
            }}
          >
            تلاش دوباره
          </button>
        </div>
      ) : null}

      {!isError && !isPending ? (
        <>
          {data.content.length === 0 ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} mt-4 ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead}  w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.formType}`}>نوع فرم ساز</p>
                    <p className={`${styles.title}`}>نام فرم</p>
                    <p className={`${styles.id}`}>شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {data.content.map(
                      ({ description, formType, id, title, url }) => (
                        <div
                          key={id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p
                            className={`${styles.operationTab} flex flex-row align-items-center flex-wrap`}
                          >
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Form_Sample_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className="operationEvent deleteBtn"
                                onClick={() => {
                                  Swal.fire({
                                    icon: "question",
                                    title: "آیا این فرم حذف شود ؟",
                                    text: "عملیات غیر قابل بازگشت میباشد",
                                    showCancelButton: true,
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "تایید",
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      removeAsyncSingleFormSample({
                                        _data: {
                                          formSampleId: id,
                                        },
                                        userToken,
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncFormSamples({
                                          userToken,
                                          _data: {
                                            ...formsData,
                                            tenant: selectedTenant.tenantId,
                                          },
                                        })
                                      );
                                    });
                                  });
                                }}
                              >
                                <span className="icon-trash"></span>
                              </button>
                            )}

                            <button
                              className="operationEvent viewBtn"
                              onClick={() => {
                                navigate(
                                  `/form-samples/view-selected-form-sample?description=${description}&formType=${formType.value}&id=${id}&title=${title}`
                                );
                              }}
                            >
                              <AiOutlineEye />
                            </button>

                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Form_Sample_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className="operationEvent editBtn"
                                onClick={() => {
                                  navigate(
                                    `/form-samples/edit-single-form/${id}/${title}?id=${id}`
                                  );
                                }}
                              >
                                <span className="icon-edit"></span>
                              </button>
                            )}
                          </p>
                          <p className={`${styles.formType}`}>
                            {formType.title}
                          </p>
                          <p className={`${styles.title}`}>{title} </p>
                          <p className={`${styles.id}`}>{id}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {data.content.map((item) => (
                    <div key={item.id} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.id}</p>
                        <p>شمارنده </p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span>نام فرم</span>
                          <span>{item.title}</span>
                        </p>
                        <p>
                          <span>نوع فرم </span>
                          <span>{item.formType.title}</span>
                        </p>

                        <p>
                          <span>عملیات</span>

                          <p className="w-100 d-flex flex-row align-items-center justify-content-center">
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Form_Sample_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className="operationEvent deleteBtn"
                                onClick={() => {
                                  Swal.fire({
                                    icon: "question",
                                    title: "آیا این فرم حذف شود ؟",
                                    text: "عملیات غیر قابل بازگشت میباشد",
                                    showCancelButton: true,
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "تایید",
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      removeAsyncSingleFormSample({
                                        _data: {
                                          formSampleId: item.id,
                                        },
                                        userToken,
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncFormSamples({
                                          userToken,
                                          _data: {
                                            ...formsData,
                                            tenant: selectedTenant.tenantId,
                                          },
                                        })
                                      );
                                    });
                                  });
                                }}
                              >
                                <span className="icon-trash"></span>
                              </button>
                            )}

                            <button
                              className="operationEvent viewBtn"
                              onClick={() => {
                                navigate(
                                  `/form-samples/view-selected-form-sample?description=${item.description}&formType=${item.formType}&id=${item.id}&title=${item.title}`
                                );
                              }}
                            >
                              <AiOutlineEye />
                            </button>

                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Form_Sample_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className="operationEvent editBtn"
                                onClick={() => {
                                  navigate(
                                    `/form-samples/edit-single-form/${item.id}/${item.title}?id=${item.id}`
                                  );
                                }}
                              >
                                <span className="icon-edit"></span>
                              </button>
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: data.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({ ...prevState, size }));
        }}
        search={formsData.search}
      />
    </div>
  );
};

export default FormSamples;
