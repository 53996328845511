// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditSingleAttribute.module.css";
// CSS

// Modules
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncUserIdentity,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Redux

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Models
import { I_EditSingleAttribute } from "../../../Models/sendingDataSchema";
import { Spinner } from "reactstrap";
// Models

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

const EditSingleAttribute = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { identityId } = useParams();

  const dispatch = useAppDispatch();

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const [formData, setFormData] = useState<I_EditSingleAttribute>({
    title: state?.data?.title || "",
    required: state?.data?.required || false,
    unique: state?.data?.unique || false,
    searchable: state?.data?.searchable || false,
    availableOnAccessList: state?.data?.availableOnAccessList || false,
    minimumValue: state?.data?.minimumValue || 0,
    maximumValue: state?.data?.maximumValue || 0,
  });

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isPending, isDone } = useAppSelector(
    (state) => state.userIdentities.editingUserIdentity
  );

  useEffect(() => {
    if (!state?.type?.value) {
      navigate("/user-identities");
      return;
    }
  }, [state, navigate]);

  useEffect(() => {
    if (!isDone) return;
    navigate("/user-identities");
  }, [isDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetEditingUserIdentityStatus());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.editSingleAttributeContainer} ${
        isNightModeOn ? "nightBg1" : ""
      } w-100 d-flex flex-column algin-items-center justify-content-center`}
    >
      <div
        className={`${styles.ediFormsContainer} w-100 d-flex flex-row-reverse flex-wrap justify-content-between `}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeHolder="نام"
          containerClassName=""
          dontShowSearchIcon
          value={formData.title}
          defaultValue={formData.title}
        />
        {state?.type?.type === "DOUBLE" || state?.type?.type === "INTEGER" ? (
          <>
            <CustomInput1
              inputType="text"
              onBlur={() => {}}
              value={String(formData.minimumValue)}
              defaultValue={String(formData.minimumValue)}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  minimumValue: e.target.value,
                }));
              }}
              placeHolder="کف"
              containerClassName=""
              dontShowSearchIcon
            />
            <CustomInput1
              inputType="text"
              onBlur={() => {}}
              value={String(formData.maximumValue)}
              defaultValue={String(formData.maximumValue)}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  maximumValue: e.target.value,
                }));
              }}
              placeHolder="سقف"
              containerClassName=""
              dontShowSearchIcon
            />
          </>
        ) : null}

        <CustomCheckbox
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              required: !prevState.required,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              required: !prevState.required,
            }));
          }}
          isChecked={formData.required}
          title="الزامی"
        />

        <CustomCheckbox
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              unique: !prevState.unique,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              unique: !prevState.unique,
            }));
          }}
          isChecked={formData.unique}
          title="خاص"
        />

        <CustomCheckbox
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              searchable: !prevState.searchable,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              searchable: !prevState.searchable,
            }));
          }}
          isChecked={formData.searchable}
          title="قابل جستجو"
        />

        <CustomCheckbox
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              availableOnAccessList: !prevState.availableOnAccessList,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              availableOnAccessList: !prevState.availableOnAccessList,
            }));
          }}
          isChecked={formData.availableOnAccessList}
          title="قابل مشاهده در لیست ها "
        />
      </div>
      <button
        className="align-self-start operationEvent submitBtn mt-4"
        onClick={() => {
          dispatch(
            editAsyncUserIdentity({
              userToken,
              _data: formData,
              attributeIdOrKey: String(identityId),
              tenant: selectedTenant.tenantId,
            })
          );
        }}
      >
        {isPending ? <Spinner /> : "تایید"}
      </button>
    </div>
  );
};

export default EditSingleAttribute;
