// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_GroupAccessModifying } from "../../../Models/sendingDataSchema";
// Models

export const changeUsersAccessingGroupsService = async (
  groupAccessingData: I_GroupAccessModifying,
  userToken: string,
  userName: string
) => {
  return _axios.post(
    `/users/${userName}/accessible-groups/edit`,
    { ...groupAccessingData },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
