// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./SecurityEvents.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  changeAsyncEffectTypeOfSecurityEvent,
  getAsyncSecurityEvent,
  securityEventSlice,
} from "../../Features/SecurityEvents/securityEventsSlice";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Models
import { I_SecurityEventRequestInterface } from "../../Models/sendingDataSchema";
// Models

// Utils
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import { convertPersianNumbersToEnglish } from "../../Utils/FormioUtils/convertPersianNumbersToEnglish";
import { Spinner } from "reactstrap";
import Pagination from "../../Utils/Pagination/Pagination";
import { removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE } from "../../Utils/removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE";
import { copyToClipboard } from "../../Utils/copyToClipboard";
import CustomSelectOption2 from "../../Utils/CustomSelectOption2/CustomSelectOption2";
import CustomCheckbox from "../../Utils/CustomCheckbox/CustomCheckbox";
import { includesChecker } from "../../Utils/includesChecker";
import { toGeorgian } from "../../Utils/FormioUtils/toGeorgian";
// Utils

// DatePicker
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
// DatePicker

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Icons
import { MdContentCopy, MdPublishedWithChanges } from "react-icons/md";
// Icons

// Components
import ChangeEffectOfSecurityEventModal from "./ChangeEffectOfSecurityEventModal/ChangeEffectOfSecurityEventModal";
// Components

// Hooks
import { useSearchParams } from "react-router-dom";
// Hooks

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Contexts

// Modules
import { permissionChecker } from "../../Modules/permissionChecker";
// Modules

// Constants
import { weekDaysInPersian } from "../../Constants/constants";
// Constants

const SecurityEvents = () => {
  const dispatch = useAppDispatch();

  const selectedEventId = useRef<string[] | number[]>([]);

  const [searchParam, setSearchParam] = useSearchParams();

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const [selectedEffectType, setSelectedEffectType] = useState<string | number>(
    ""
  );

  const [isEventTypeChanged, setIsEventTypeChanged] = useState<boolean>(false);
  const [values, setValues] = useState<number[] | string[]>([
    searchParam.get("from")
      ? String(searchParam.get("from")).replaceAll("-", "/").replace(" ", "")
      : "",
    searchParam.get("to")
      ? String(searchParam.get("to")).replaceAll("-", "/").replace(" ", "")
      : "",
  ]);
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  const [formsData, setFormsData] = useState<I_SecurityEventRequestInterface>({
    effect: searchParam.get("effect") || "",
    from: searchParam.get("from") || "",
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    risk: Number(searchParam.get("risk")) || "",
    search: searchParam.get("search") || "",
    to: searchParam.get("to") || String(values[0]) || "",
    type: searchParam.get("type") || String(values[1]) || "",
  });

  const [securityEventDataForSendToServer, setSecurityEventDataToSendToServer] =
    useState<
      {
        id: number;
        eventType: {
          value: string;
          title: string;
        };
        relatedUsername: string;
        ipAddress: string;
        eventTime: {
          gregorianDate: string;
          jalaliDate: string;
        };
        requestInformation: string;
        effectType: {
          value: string;
          title: string;
        };
      }[]
    >([]);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { securityRiskType, securityEventType } = useAppSelector(
    (state) => state.allRoles.data
  );
  const {
    data: eventData,
    isError: eventDataError,
    isPending: eventDataPending,
  } = useAppSelector((state) => state.securityEvent.securityEventData);
  const language = useAppSelector((state) => state.currLanguage.language);
  const { securityEventEffectType } = useAppSelector(
    (state) => state.allRoles.data
  );
  const { isPending } = useAppSelector(
    (state) => state.securityEvent.changeEffectStatus
  );

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (!isFullRenderd) return;
    if (
      !permissionChecker(
        allGroups,
        ["Security_Event_Reader", "Security_Event_Manager"],
        isFullRenderd
      )
    )
      return;
    const timeOut = setTimeout(() => {
      let _from: any = toGeorgian(
        String(values[0]).split("/")[0],
        String(values[0]).split("/")[1],
        String(values[0]).split("/")[2]
      );
      let _to: any = toGeorgian(
        String(values[1]).split("/")[0],
        String(values[1]).split("/")[1],
        String(values[1]).split("/")[2]
      );
      if (!Boolean(values[0]) || !Boolean(values[1])) {
        dispatch(
          getAsyncSecurityEvent({
            userToken,
            _data: {
              ...formsData,
              from: "",
              to: "",
            },
          })
        );
      }
      dispatch(
        getAsyncSecurityEvent({
          userToken,
          _data: {
            ...formsData,
            from:
              Boolean(values[0]) && Boolean(values[1])
                ? `${_from.toString().replaceAll(",", "/")} 00:00:00`
                : "",
            to:
              Boolean(values[0]) && Boolean(values[1])
                ? `${_to.toString().replaceAll(",", "/")} 23:59:00`
                : "",
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    dispatch,
    userToken,
    formsData,
    isMounted,
    allGroups,
    isFullRenderd,
    values,
  ]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.securityEventDataForPages({
        pageNumber: formsData.page,
        size: formsData.size,
      })
    );
  }, [dispatch, formsData]);

  useEffect(() => {
    return () => {
      dispatch(securityEventSlice.actions.resetSecurityEvent());
    };
  }, [dispatch]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        effect: String(formsData.effect),
        from: formsData.from.replaceAll("/", "-"),
        risk: String(formsData.risk),
        search: String(formsData.search),
        to: formsData.to.replaceAll("/", "-"),
        type: String(formsData.type),
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  useEffect(() => {
    const keys = Object.keys(securityEventEffectType);
    setSelectedEffectType(securityEventEffectType[keys[0]]?.value || "");
  }, [securityEventEffectType]);

  return (
    <div
      className={`${styles.securityEventContainer} ${
        isNightModeOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center`}
    >
      <p
        className="w-100 d-flex flex-row align-items-center justify-content-end mb-4"
        style={{
          color: "#111D64",
          fontSize: "1.5rem",
          fontWeight: "bold",
        }}
      >
        رویداد های امنیتی
      </p>
      <div
        className={`${styles.topItems} w-100 d-flex flex-row-reverse flex-wrap align-items-center justify-content-start gap-3`}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={formsData.search}
          defaultValue={formsData.search}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="جستجو"
        />
        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState) => ({
              ...prevState,
              type: String(data),
            }));
            return data;
          }}
          options={[
            {
              title: "بدون رویداد",
              value: "",
            },
            ...Object.keys(securityEventType).map((item) => ({
              title: securityEventType[item].title,
              value: securityEventType[item].value,
            })),
          ]}
          initialTitle={(() => {
            const effectTypes = JSON.parse(localStorage.getItem("basicItems")!)
              .result.securityEventType;
            if (!effectTypes) return "در حال بارگذاری";
            const keys = Object.keys(effectTypes);
            let res = "لطفا نوع رویداد را انتخاب کنید";
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (effectTypes[keys[i]].value === formsData.type) {
                res = effectTypes[keys[i]].title;
              }
            }
            return res;
          })()}
          initialValue={(() => {
            const effectTypes = JSON.parse(localStorage.getItem("basicItems")!)
              .result.securityEventType;
            const keys = Object.keys(effectTypes);
            let res = 1;
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (effectTypes[keys[i]].value === formsData.type) {
                res = effectTypes[keys[i]].value;
              }
            }
            return res;
          })()}
        />

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState) => ({
              ...prevState,
              risk: data,
            }));
            return data;
          }}
          options={[
            { title: "بدون فیلتر", value: "" },
            ...Object.keys(securityRiskType).map((item) => ({
              title: securityRiskType[item].title,
              value: securityRiskType[item].value,
            })),
          ]}
          initialTitle={(() => {
            const riskTypes = JSON.parse(localStorage.getItem("basicItems")!)
              .result.securityRiskType;
            if (!riskTypes) return "در حال بارگذاری";
            const keys = Object.keys(riskTypes);
            let res = "نوع هشدار";
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (riskTypes[keys[i]].value === formsData.risk) {
                res = riskTypes[keys[i]].title;
              }
            }
            return res;
          })()}
          initialValue={(() => {
            const riskTypes = JSON.parse(localStorage.getItem("basicItems")!)
              .result.securityRiskType;
            const keys = Object.keys(riskTypes);
            let res = 1;
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (riskTypes[keys[i]].value === formsData.risk) {
                res = riskTypes[keys[i]].value;
              }
            }
            return res;
          })()}
        />

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState) => ({
              ...prevState,
              effect: String(data),
            }));
            return data;
          }}
          options={[
            {
              title: "بدون اثر",
              value: "",
            },
            ...Object.keys(securityEventEffectType).map((item) => ({
              title: securityEventEffectType[item].title,
              value: securityEventEffectType[item].value,
            })),
          ]}
          initialTitle={(() => {
            const effectTypes = JSON.parse(localStorage.getItem("basicItems")!)
              .result.securityEventEffectType;
            if (!effectTypes) return "در حال بارگذاری";
            const keys = Object.keys(effectTypes);
            let res = "نوع اثر";
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (effectTypes[keys[i]].value === formsData.effect) {
                res = effectTypes[keys[i]].title;
              }
            }
            return res;
          })()}
          initialValue={(() => {
            const effectTypes = JSON.parse(localStorage.getItem("basicItems")!)
              .result.securityEventEffectType;
            const keys = Object.keys(effectTypes);
            let res = 1;
            if (keys.length === 0) return "در حال بارگذاری";
            for (let i = 0; i <= keys.length - 1; i++) {
              if (effectTypes[keys[i]].value === formsData.effect) {
                res = effectTypes[keys[i]].value;
              }
            }
            return res;
          })()}
        />

        <DatePicker
          calendar={persian}
          locale={persian_fa}
          className={`${isNightModeOn ? "purple bg-dark" : "purple"}`}
          value={values}
          weekDays={weekDaysInPersian}
          dateSeparator="-"
          range
          onChange={(value: any) => {
            if (!Boolean(value)) {
              setValues([]);
              return;
            }
            setValues(value);
            if (
              typeof value !== "boolean" &&
              value.toString().split(",").length === 2
            ) {
              const start = {
                y: value.toString().split(",")[0].split(",")[0],
                m: value.toString().split(",")[0].split(",")[1],
                d: value.toString().split(",")[0].split(",")[2],
              };
              const end = {
                y: value.toString().split(",")[1].split(",")[0],
                m: value.toString().split(",")[1].split(",")[1],
                d: value.toString().split(",")[1].split(",")[2],
              };
              setFormsData((prevState) => ({
                ...prevState,
                from: `${convertPersianNumbersToEnglish(
                  start.y
                )}/${convertPersianNumbersToEnglish(
                  start.m
                )}/${convertPersianNumbersToEnglish(start.d)}`,
                to: `${convertPersianNumbersToEnglish(
                  end.y
                )}/${convertPersianNumbersToEnglish(
                  end.m
                )}/${convertPersianNumbersToEnglish(end.d)}`,
              }));
              setValues([
                `${convertPersianNumbersToEnglish(
                  start.y
                )}/${convertPersianNumbersToEnglish(
                  start.m
                )}/${convertPersianNumbersToEnglish(start.d)}`,
                `${convertPersianNumbersToEnglish(
                  end.y
                )}/${convertPersianNumbersToEnglish(
                  end.m
                )}/${convertPersianNumbersToEnglish(end.d)}`,
              ]);
            }
          }}
          inputClass={`${styles.dateInputClassName} `}
          placeholder="تاریخ واقعه"
        >
          <button
            className="px-2 py-1 rounded-2 mb-2"
            onClick={() => {
              setValues([]);
              setFormsData((prevState) => ({ ...prevState, from: "", to: "" }));
            }}
          >
            لغو انتخاب
          </button>
        </DatePicker>
        {window.innerWidth > 1000 &&
        securityEventDataForSendToServer.length > 0 ? (
          <div className=" d-flex flex-row align-items-center justify-content-end ">
            <button
              className="operationEvent submitBtn px-4"
              onClick={() => {
                selectedEventId.current = [];
                selectedEventId.current =
                  removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE(
                    securityEventDataForSendToServer.map((item) => item.id)
                  );

                dispatch(
                  changeAsyncEffectTypeOfSecurityEvent({
                    userToken,
                    _data: {
                      effectType: String(selectedEffectType),
                      eventIds:
                        removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE(
                          securityEventDataForSendToServer.map(
                            (item) => item.id
                          )
                        ),
                    },
                  })
                )
                  .then(() => {
                    dispatch(
                      getAsyncSecurityEvent({
                        userToken,
                        _data: formsData,
                      })
                    );
                  })
                  .then(() => {
                    setSecurityEventDataToSendToServer([]);
                  });
              }}
            >
              {isPending ? <Spinner /> : "اعمال"}
            </button>
            <CustomSelectOption2
              getSelectedOption={(data) => {
                setSelectedEffectType(data);
                setIsEventTypeChanged(true);
              }}
              initialValue={`پیشفرض : ${(() => {
                const keys = Object.keys(securityEventEffectType);
                return securityEventEffectType[keys[0]]?.value;
              })()}`}
              initialTitle={`عملیات گروهی 
           `}
              options={Object.keys(securityEventEffectType).map((item) => ({
                title: securityEventEffectType[item].title,
                value: securityEventEffectType[item].value,
              }))}
              attributes={{
                "data-tooltip-id": "formTooltip",
                "data-tooltip-content": isEventTypeChanged
                  ? ""
                  : `پیشفرض :  ${(() => {
                      const keys = Object.keys(securityEventEffectType);
                      return securityEventEffectType[keys[0]]?.title;
                    })()}`,
              }}
            />
          </div>
        ) : null}
      </div>

      {eventDataPending && !eventDataError ? (
        <div
          className={`${styles.loadingContainer} d-flex flex-column align-items-center justify-content-center mt-3`}
        >
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : null}
      {!eventDataPending && eventDataError ? (
        <p className="mt-5">مشکلی پیش آمده</p>
      ) : null}
      {!eventDataPending && !eventDataError ? (
        <>
          {eventData.content.length === 0 ? (
            <p className="mt-5">موردی وجود ندارد</p>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    securityEventDataForSendToServer.length > 0
                      ? styles.lessHeight
                      : ""
                  } mt-3 ${
                    isNightModeOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead}  w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.effectType}`}>نوع اثر </p>
                    <p className={`${styles.eventTime}`}>زمان رویداد</p>
                    <p className={`${styles.eventType}`}>نوع رویداد</p>
                    <p className={`${styles.ipAddress}`}>آی پی</p>
                    <p className={`${styles.relatedUsername}`}>
                      نام کاربری
                      {includesChecker(
                        eventData.content.map((item) => String(item.id)),
                        securityEventDataForSendToServer.map((item) =>
                          String(item.id)
                        )
                      ) &&
                      securityEventDataForSendToServer.length !==
                        eventData.content.length ? (
                        <div
                          className={`${styles.someSelectedBtn}`}
                          onClick={() => {
                            setSecurityEventDataToSendToServer(
                              eventData.content
                            );
                          }}
                          style={{
                            marginRight: "-1rem",
                          }}
                        >
                          -
                        </div>
                      ) : null}
                      {eventData.content.length !== 0 &&
                      securityEventDataForSendToServer.length !== 0 &&
                      securityEventDataForSendToServer.length ===
                        eventData.content.length ? (
                        <CustomCheckbox
                          isChecked
                          onChange={() => {}}
                          onClick={() => {
                            setSecurityEventDataToSendToServer([]);
                          }}
                          title=""
                          containerClassName={styles.halfRem}
                        />
                      ) : null}
                    </p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {eventData.content.map(
                      ({
                        effectType,
                        eventTime,
                        eventType,
                        id,
                        ipAddress,
                        relatedUsername,
                        requestInformation,
                      }) => (
                        <div
                          key={id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p className={`${styles.operationTab}`}>
                            <button
                              className="operationEvent editBtn"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.changeStatus}
                              onClick={() => {
                                selectedEventId.current = [];
                                selectedEventId.current = [id];
                                setIsModalInScreen(true);
                              }}
                            >
                              <MdPublishedWithChanges />
                            </button>
                            <button
                              className="operationEvent taskBtn"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.copy}
                              onClick={() => {
                                copyToClipboard(requestInformation);
                              }}
                            >
                              <MdContentCopy />
                            </button>
                          </p>
                          <p className={`${styles.effectType}`}>
                            {effectType.title}
                          </p>
                          <p className={`${styles.eventTime}`}>
                            {eventTime.jalaliDate}
                          </p>
                          <p
                            className={`${styles.eventType}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(eventType.title).length >= 15
                                ? String(eventType.title)
                                : ""
                            }
                          >
                            {eventType.title}
                          </p>
                          <p
                            className={`${styles.ipAddress}`}
                            data-tooltip-id="my-tooltip"
                          >
                            {ipAddress}
                          </p>
                          <p
                            className={`${styles.relatedUsername} d-flex flex-row align-items-center justify-content-end`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(relatedUsername).length >= 15
                                ? String(relatedUsername)
                                : ""
                            }
                          >
                            <span
                              style={{
                                marginRight: "6px",
                              }}
                            >
                              {String(relatedUsername).length >= 15
                                ? "..." +
                                  String(relatedUsername).substring(0, 14)
                                : String(relatedUsername)}
                            </span>
                            <CustomCheckbox
                              isChecked={securityEventDataForSendToServer
                                .map((_item) => _item.id)
                                .includes(id)}
                              onChange={() => {}}
                              onClick={() => {
                                if (
                                  securityEventDataForSendToServer
                                    .map((_item) => _item.id)
                                    .includes(id)
                                ) {
                                  setSecurityEventDataToSendToServer(
                                    (prevState) =>
                                      prevState.filter(
                                        (_item) => _item.id !== id
                                      )
                                  );
                                  return;
                                } else {
                                  setSecurityEventDataToSendToServer(
                                    (prevState) => [
                                      ...prevState,
                                      {
                                        effectType,
                                        eventTime,
                                        eventType,
                                        id,
                                        ipAddress,
                                        relatedUsername,
                                        requestInformation,
                                      },
                                    ]
                                  );
                                }
                              }}
                              title=""
                            />
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-5 w-100 d-flex flex-column align-items-center`}
                >
                  {eventData.content.map(
                    ({
                      effectType,
                      eventTime,
                      eventType,
                      id,
                      ipAddress,
                      relatedUsername,
                      requestInformation,
                    }) => (
                      <div key={id} className="w-100">
                        <div
                          className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>{id}</p>
                          <p> شمارنده </p>
                        </div>
                        <div
                          className={`${styles.tableRow} ${
                            isNightModeOn ? "nightBg2" : ""
                          } w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            <span>کد درخواست</span>
                            <span
                              onClick={() => {
                                copyToClipboard(requestInformation);
                              }}
                            >
                              کپی
                            </span>
                          </p>
                          <p>
                            <span>زمان رویداد</span>
                            <span>{eventTime.jalaliDate}</span>
                          </p>
                          <p>
                            <span>نوع رویداد</span>
                            <span>{eventType.title}</span>
                          </p>
                          <p>
                            <span>آی پی</span>
                            <span>{ipAddress}</span>
                          </p>
                          <p>
                            <span>نام کاربری</span>
                            <span>{relatedUsername}</span>
                          </p>
                          <p>
                            <span>نوع اثر</span>
                            <span>{effectType.title}</span>
                          </p>

                          <p>
                            <span>عملیات</span>
                            <span className="d-flex flex-row align-items-center justify-content-center">
                              <button
                                className="operationEvent editBtn"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.changeStatus}
                                onClick={() => {
                                  selectedEventId.current = [];
                                  selectedEventId.current = [id];
                                  setIsModalInScreen(true);
                                }}
                              >
                                <MdPublishedWithChanges />
                              </button>
                              <button
                                className="operationEvent taskBtn"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.copy}
                                onClick={() => {
                                  copyToClipboard(requestInformation);
                                }}
                              >
                                <MdContentCopy />
                              </button>
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: eventData.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
          setSecurityEventDataToSendToServer([]);
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({ ...prevState, size }));
        }}
        search={formsData.search}
      />

      {isModalInScreen ? (
        <ChangeEffectOfSecurityEventModal
          eventId={selectedEventId.current}
          setIsModalInScreen={setIsModalInScreen}
          formsData={formsData}
          setSecurityEventDataToSendToServer={
            setSecurityEventDataToSendToServer
          }
        />
      ) : null}
    </div>
  );
};

export default SecurityEvents;
