import { dateFormat, formioDateFormat2 } from "../../Formats/formats";
import { gregorian_to_jalali, jalali_to_gregorian } from "./getPersianDate";

export const convertToPersianDateFromGeorgianDateInString = (
  stringData: any
) => {
  if (dateFormat.test(JSON.stringify(stringData))) {
    const modifiedString = JSON.stringify(stringData).replaceAll(
      formioDateFormat2,
      (match) => {
        if (match.split("/").length === 3) {
          let str: string = "";
          const date = match.split("/");
          const year = date[0];
          const month = date[1];
          const day = date[2];

          const res = jalali_to_gregorian(
            Number(year),
            Number(month),
            Number(day)
          );

          str = `${res[0]}-${
            String(res[1]).length === 1 ? `0${res[1]}` : res[1]
          }-${String(res[2]).length === 1 ? `0${res[2]}` : res[2]}`;

          return str;
        }
        return match;
      }
    );
    const modifiedObject = JSON.parse(modifiedString);
    console.log(modifiedObject);
    return modifiedObject;
  }

  if (formioDateFormat2.test(JSON.stringify(stringData))) {
    const modifiedString = JSON.stringify(stringData).replaceAll(
      formioDateFormat2,
      (match: any) => {
        if (match.split("T").length === 2) {
          let str: string = "";
          console.log(match.split("T"));
          const date = match.split("T")[0].split("-");
          const year = date[0];
          const month = date[1];
          const day = date[2];
          const res = (() => {
            return gregorian_to_jalali(
              Number(year),
              Number(month),
              Number(day)
            );
          })();

          str = `${res[0]}/${
            String(res[1]).length === 1 ? `0${res[1]}` : res[1]
          }/${String(res[2]).length === 1 ? `0${res[2]}` : res[2]}`;

          return str;
        }

        return match;
      }
    );
    const modifiedObject = JSON.parse(modifiedString);
    console.log(modifiedObject);
    return modifiedObject;
  }
};
