// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import {
  I_EditableGroups,
  I_GroupOnGroupData,
  I_IndirectMembershipData,
  I_RecevedAllGroupsFromServer,
} from "../../Models/interfaces";
import {
  I_GroupOnGroupSendingData,
  I_NewGroupSendingData,
  I_SendingPaginationData,
} from "../../Models/sendingDataSchema";
// Models

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
// Utils

// Services
import { getAllGroupsWithPaginationService } from "../../Services/Groups/get/getAllGroupsWithPaginationService";
import { getIndirectMembershipsService } from "../../Services/Groups/get/getIndirectMembershipsService";
import { getGroupOnGroupAccessService } from "../../Services/Groups/get/getGroupOnGroupAccessService";
import { getEditableGroupsService } from "../../Services/Groups/get/getEditableGroupsService";
import { addNewGroupService } from "../../Services/Groups/Post/addNewGroupService";
import { editPublicGroupService } from "../../Services/Groups/Post/editPublicGroupService";
import { removePublicAddedGroupService } from "../../Services/Groups/Post/removePublicAddedGroupService";
import { editIndirectMembershipGroupService } from "../../Services/Groups/Post/editIndirectMembershipGroupService";
import { editGroupOnGroupService } from "../../Services/Groups/Post/editGroupOnGroupService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Services

const initialState: {
  allGroupsData: I_RecevedAllGroupsFromServer;
  indirectMembership: I_IndirectMembershipData & {
    isPending: boolean;
    isError: boolean;
  } & {
    edit: {
      isPending: boolean;
      isDone: boolean;
    };
  };
  groupOnGroupAccess: I_GroupOnGroupData & {
    isPending: boolean;
    isError: boolean;
    edit: {
      isDone: boolean;
      isPending: boolean;
    };
  };
  editableGroups: I_EditableGroups & { isPending: boolean; isError: boolean };
  isAddGroupPending: boolean;
  isAddingGroupsDone: boolean;
  isEditPubicGroupsPending: boolean;
  isEditPublicGroupDone: boolean;
} = {
  allGroupsData: {
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalElements: 0,
    hasNextPage: false,
    content: [],

    // added by Developer
    isRecevedGroupsPending: true,
    isError: false,
    // added by Developer
  },
  indirectMembership: {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
    hasNextPage: false,
    content: [],

    // added by Developer
    isPending: true,
    isError: false,
    edit: {
      isDone: false,
      isPending: false,
    },
    // added by Developer
  },
  groupOnGroupAccess: {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
    hasNextPage: false,
    content: [],

    // added by Developer
    isPending: false,
    isError: false,
    edit: {
      isDone: false,
      isPending: false,
    },
    // added by Developer
  },
  editableGroups: {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
    hasNextPage: false,
    content: [],

    // added by Developer
    isPending: true,
    isError: false,
    // added by Developer
  },

  // added by Developer
  isAddGroupPending: false,
  isAddingGroupsDone: false,
  isEditPubicGroupsPending: false,
  isEditPublicGroupDone: false,
  // added by Developer
};

export const getAsyncAllGroups = createAsyncThunk(
  "groups/getAllGroups",
  async (_data: { _data: I_SendingPaginationData; userToken: string }) => {
    try {
      const { data } = await getAllGroupsWithPaginationService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncIndirectMemberships = createAsyncThunk(
  "groups/getIndirectMemberships",
  async (_data: { _data: I_SendingPaginationData; userToken: string }) => {
    try {
      const { data } = await getIndirectMembershipsService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncGroupOnGroupAccess = createAsyncThunk(
  "groups/getGroupOnGroupAccess",
  async (_data: {
    userToken: string;
    _data: {
      page: number;
      size: number;
      search: string;
      tenant: string;
    };
  }) => {
    try {
      const { data } = await getGroupOnGroupAccessService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncEditableGroups = createAsyncThunk(
  "groups/getEditableGroups",
  async (_data: { _data: I_SendingPaginationData; userToken: string }) => {
    try {
      const { data } = await getEditableGroupsService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const postAsyncNewGroup = createAsyncThunk(
  "groups/addNewGroup",
  async (_data: { _data: I_NewGroupSendingData; userToken: string }) => {
    try {
      const { data } = await addNewGroupService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const postAsyncPublicGroupEdit = createAsyncThunk(
  "groups/editPublicGroups",
  async (_data: {
    userToken: string;
    groupId: string;
    _data: {
      category: string;
      groupTitle: string;
    };
  }) => {
    try {
      const { data } = await editPublicGroupService(
        _data.userToken,
        _data.groupId,
        _data._data
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const postAsyncPublicGroupRemoval = createAsyncThunk(
  "groups/removeSelectGroup",
  async (_data: { userToken: string; groupId: string }) => {
    try {
      const { data } = await removePublicAddedGroupService(
        _data.userToken,
        _data.groupId
      );
      return data;
    } catch (err: any) {
      console.log(err);
      if (!err?.response.data.messages) {
        customizedToast("مشکلی هنگام انجام عملیات پیش آمده", "error");
        return;
      }
      reduxCatchHandler(err);
    }
  }
);

export const removeAsyncSingleIndirectGroupMembership = createAsyncThunk(
  "groups/removeSingleIndirectMembershipData",
  async (_data: {
    userToken: string;
    _data: {
      tenantId: string;
      deleteList: {
        id: number;
      }[];
    };
  }) => {
    try {
      const { data } = await editIndirectMembershipGroupService(_data);

      return data;
    } catch (err: any) {
      console.log(err);
      if (!err?.response.data.messages) {
        customizedToast("مشکلی هنگام انجام عملیات پیش آمده", "error");
        return;
      }
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncSingleIndirectGroupMembership = createAsyncThunk(
  "groups",
  async (_data: {
    userToken: string;
    _data: {
      tenantId: string;
      addList: {
        fromGroup: string;
        toGroup: string;
      }[];
    };
  }) => {
    try {
      const { data } = await editIndirectMembershipGroupService(_data);
      return data;
    } catch (err: any) {
      console.log(err);
      if (!err?.response.data.messages) {
        customizedToast("مشکلی هنگام انجام عملیات پیش آمده", "error");
        return;
      }
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncGroupOnGroup = createAsyncThunk(
  "groups/editGroupOnGroup",
  async (_data: {
    userToken: string;
    _data: I_GroupOnGroupSendingData & {
      tenantId: string;
    };
  }) => {
    try {
      const { data } = await editGroupOnGroupService(_data);

      return data;
    } catch (err: any) {
      console.log(err);
      if (!err?.response.data.messages) {
        customizedToast("مشکلی هنگام انجام عملیات پیش آمده", "error");
        return;
      }
      reduxCatchHandler(err);
    }
  }
);

export const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    resetAllGroupsData: (state) => {
      state.allGroupsData.content = resetReferenceOfObject(
        initialState.allGroupsData.content
      );

      state.allGroupsData.pageNumber = 1;
      state.allGroupsData.pageSize = 10;
      state.allGroupsData.isRecevedGroupsPending = true;
    },
    resetAddingGroupData: (state) => {
      state.isAddingGroupsDone = false;
      state.isAddGroupPending = false;
    },
    resetIndirectMembershipsData: (state) => {
      state.indirectMembership.content = resetReferenceOfObject(
        initialState.indirectMembership.content
      );
      state.indirectMembership.isPending = true;
      state.indirectMembership.pageNumber = 1;
      state.indirectMembership.pageSize = 5;
    },
    resetGroupOnGroupData: (state) => {
      state.groupOnGroupAccess.content = resetReferenceOfObject(
        initialState.groupOnGroupAccess.content
      );
      state.groupOnGroupAccess.isPending = true;
      state.groupOnGroupAccess.pageNumber = 1;
      state.groupOnGroupAccess.pageSize = 5;
    },
    resetEditableGroupsData: (state) => {
      state.editableGroups.content = resetReferenceOfObject(
        initialState.editableGroups.content
      );
      state.editableGroups.isPending = true;
      state.editableGroups.pageNumber = 1;
      state.editableGroups.pageSize = 5;
    },
    resetAllGroups: (state) => {
      state.allGroupsData.isRecevedGroupsPending = true;
    },
    resetPublicGroupEditingData: (state) => {
      state.isEditPublicGroupDone = false;
    },
    resetIndirectMembershipAddingState: (state) => {
      state.indirectMembership.edit.isDone = false;
      state.indirectMembership.edit.isPending = false;
    },
    resetGroupOnGroupAddingState: (state) => {
      state.groupOnGroupAccess.edit.isDone = false;
      state.groupOnGroupAccess.edit.isPending = false;
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncAllGroups.pending, (state, action) => {
      state.allGroupsData.isRecevedGroupsPending = true;
      state.allGroupsData.isError = false;
    });
    builder.addCase(getAsyncAllGroups.fulfilled, (state, action) => {
      state.allGroupsData.isRecevedGroupsPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
      }
      state.allGroupsData.pageNumber = action.payload!.result.pageNumber;
      state.allGroupsData.pageSize = action.payload!.result.pageSize;
      state.allGroupsData.totalPages = action.payload!.result.totalPages;
      state.allGroupsData.totalElements = action.payload!.result.totalElements;
      state.allGroupsData.hasNextPage = action.payload!.result.hasNextPage;
      state.allGroupsData.content = action.payload!.result.content;
      state.allGroupsData.isError = false;
    });
    builder.addCase(getAsyncAllGroups.rejected, (state, action) => {
      state.allGroupsData.isRecevedGroupsPending = false;
      state.allGroupsData.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncIndirectMemberships.pending, (state, action) => {
      state.indirectMembership.isPending = true;
      state.indirectMembership.isError = false;
    });
    builder.addCase(getAsyncIndirectMemberships.fulfilled, (state, action) => {
      state.indirectMembership.isPending = false;

      if (!action.payload) return;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
      }
      console.log("sssss");
      state.indirectMembership.pageNumber = action.payload!.result.pageNumber;
      state.indirectMembership.pageSize = action.payload!.result.pageSize;
      state.indirectMembership.totalPages = action.payload!.result.totalPages;
      state.indirectMembership.totalElements =
        action.payload!.result.totalElements;
      state.indirectMembership.hasNextPage = action.payload!.result.hasNextPage;
      state.indirectMembership.content = action.payload!.result.content;
      state.indirectMembership.isError = false;
    });
    builder.addCase(getAsyncIndirectMemberships.rejected, (state, action) => {
      state.indirectMembership.isPending = false;
      state.indirectMembership.isError = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncGroupOnGroupAccess.pending, (state) => {
      state.groupOnGroupAccess.isPending = true;
      state.groupOnGroupAccess.isError = false;
    });
    builder.addCase(getAsyncGroupOnGroupAccess.fulfilled, (state, action) => {
      state.groupOnGroupAccess.isPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
      }

      state.groupOnGroupAccess.pageNumber = action.payload!.result.pageNumber;
      state.groupOnGroupAccess.pageSize = action.payload!.result.pageSize;
      state.groupOnGroupAccess.totalPages = action.payload!.result.totalPages;
      state.groupOnGroupAccess.totalElements =
        action.payload!.result.totalElements;
      state.groupOnGroupAccess.hasNextPage = action.payload!.result.hasNextPage;
      state.groupOnGroupAccess.content = action.payload!.result.content;
      state.groupOnGroupAccess.isError = false;
    });
    builder.addCase(getAsyncGroupOnGroupAccess.rejected, (state) => {
      state.groupOnGroupAccess.isPending = false;
      state.groupOnGroupAccess.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncEditableGroups.pending, (state, action) => {
      state.editableGroups.isPending = true;
      state.editableGroups.isError = false;
    });
    builder.addCase(getAsyncEditableGroups.fulfilled, (state, action) => {
      state.editableGroups.isPending = false;

      if (action?.payload?.error !== 0) return;

      state.editableGroups.pageNumber = action.payload.result.pageNumber;
      state.editableGroups.pageSize = action.payload.result.pageSize;
      state.editableGroups.totalPages = action.payload.result.totalPages;
      state.editableGroups.totalElements = action.payload.result.totalElements;
      state.editableGroups.hasNextPage = action.payload.result.hasNextPage;
      state.editableGroups.content = action.payload.result.content;
      state.editableGroups.isError = false;
    });
    builder.addCase(getAsyncEditableGroups.rejected, (state, action) => {
      state.editableGroups.isPending = false;
      state.editableGroups.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(postAsyncNewGroup.pending, (state) => {
      state.isAddGroupPending = true;
      state.isAddingGroupsDone = false;
    });
    builder.addCase(postAsyncNewGroup.fulfilled, (state, action) => {
      state.isAddGroupPending = false;

      if (!action.payload) return;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("گروه با موفقیت اضافه شد", "success");
        state.isAddingGroupsDone = true;
        return;
      }
    });
    builder.addCase(postAsyncNewGroup.rejected, (state) => {
      state.isAddGroupPending = false;
      state.isAddingGroupsDone = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(postAsyncPublicGroupEdit.pending, (state, action) => {
      state.isEditPubicGroupsPending = true;
      state.isEditPublicGroupDone = false;
    });
    builder.addCase(postAsyncPublicGroupEdit.fulfilled, (state, action) => {
      state.isEditPubicGroupsPending = false;

      if (!action.payload) return;

      if (action.payload.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }

        customizedToast("گروه با موفقیت تغییر کرد", "success");

        state.isEditPublicGroupDone = true;

        return;
      }
    });
    builder.addCase(postAsyncPublicGroupEdit.rejected, (state, action) => {
      state.isEditPubicGroupsPending = false;
      state.isEditPublicGroupDone = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(postAsyncPublicGroupRemoval.pending, (state) => {});
    builder.addCase(postAsyncPublicGroupRemoval.fulfilled, (state, action) => {
      if (!action.payload) return;

      if (action.payload.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        return;
      }
    });
    builder.addCase(postAsyncPublicGroupRemoval.rejected, (state) => {});
    //
    //
    //
    //
    //
    //
    builder.addCase(
      removeAsyncSingleIndirectGroupMembership.pending,
      (state, action) => {}
    );
    builder.addCase(
      removeAsyncSingleIndirectGroupMembership.fulfilled,
      (state, action) => {
        if (!action.payload) return;
        if (action.payload.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          return;
        }
      }
    );
    builder.addCase(
      removeAsyncSingleIndirectGroupMembership.rejected,
      (state, action) => {}
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(
      editAsyncSingleIndirectGroupMembership.pending,
      (state, action) => {
        state.indirectMembership.edit.isPending = true;
        state.indirectMembership.edit.isDone = false;
      }
    );
    builder.addCase(
      editAsyncSingleIndirectGroupMembership.fulfilled,
      (state, action) => {
        state.indirectMembership.edit.isPending = false;
        if (!action.payload) return;
        if (action.payload.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.indirectMembership.edit.isDone = true;
          return;
        }
      }
    );
    builder.addCase(
      editAsyncSingleIndirectGroupMembership.rejected,
      (state, action) => {
        state.indirectMembership.edit.isPending = false;
        state.indirectMembership.edit.isDone = false;
      }
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(editAsyncGroupOnGroup.pending, (state, action) => {
      state.groupOnGroupAccess.edit.isPending = true;
      state.groupOnGroupAccess.edit.isDone = false;
    });
    builder.addCase(editAsyncGroupOnGroup.fulfilled, (state, action) => {
      state.groupOnGroupAccess.edit.isPending = false;
      if (!action.payload) return;
      if (action.payload.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        state.groupOnGroupAccess.edit.isDone = true;
        return;
      }
    });
    builder.addCase(editAsyncGroupOnGroup.rejected, (state, action) => {
      state.groupOnGroupAccess.edit.isPending = false;
      state.groupOnGroupAccess.edit.isDone = false;
    });
    //
    //
    //
    //
    //
    //

    //
    //
    //
    //
    //
    //
  },
});
