// React
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./ShowBpmnByXmlData.module.css";
// CSS

// Bpmn
import BPMN_VIEWER from "./BPMN_VIEWER/BPMN_VIEWER";
// Bpmn

// Components
import StepsAndHistoryViewer from "./StepsAndHistoryViewer/StepsAndHistoryViewer";
import BPMN_STEPS_VIEWER from "./BPMN_STEPS_VIEWER/BPMN_STEPS_VIEWER";
import { processActivityStep } from "../../Models/interfaces";
import { useAppSelector } from "../../app/store";
// Components

type ShowBpmnByXmlDataProps = {
  xmlData: string;
  currentStateIdContainer?: string;
  className?: string;
  colorControlling: {
    bgDoneColor: string;
    setBgDoneColor: React.Dispatch<React.SetStateAction<string>>;
    textDoneColor: string;
    setTextDoneColor: React.Dispatch<React.SetStateAction<string>>;
  };
  instanceId: string;
};
const ShowBpmnByXmlData: React.FunctionComponent<ShowBpmnByXmlDataProps> = ({
  xmlData,
  currentStateIdContainer,
  className,
  colorControlling,
  instanceId,
}) => {
  const instanceData = useAppSelector(
    (state) => state.processes.processInstanceData.processInstanceSchema
  );

  const [selectedStep, setSelectedStep] = useState<string>("");

  const [isRetry, setIsRetry] = useState<boolean>(false);

  const relatedDataRef = useRef<{
    maxStep: string | number;
    steps: processActivityStep;
    type: "next" | "prev";
    currentStep: string | number;
  }>({ currentStep: 1, maxStep: 1, steps: [], type: "next" });

  const setSelectedStepFromBPMN = useCallback(
    (stepId: string, extraData: any) => {
      console.log(extraData);
      const isValid = instanceData.validSchemas
        .map((_i) => _i.schemaId)
        .includes(stepId);
      if (isValid) {
        setSelectedStep(stepId);
      }
    },
    [instanceData]
  );

  const { isPending } = useAppSelector(
    (state) => state.processInstanceHistorySlice.processActivitySteps
  );

  const BPMN = useMemo(
    () =>
      isPending ? (
        ""
      ) : (
        <BPMN_VIEWER
          colorControlling={colorControlling}
          xmlData={xmlData}
          className={className}
          currentStateIdContainer={currentStateIdContainer}
          getSelectedStep={setSelectedStepFromBPMN}
          selectedElements={{
            elementId: selectedStep,
          }}
          getNewSchema={() => {}}
        />
      ),
    [
      colorControlling,
      xmlData,
      className,
      currentStateIdContainer,
      setSelectedStepFromBPMN,
      selectedStep,
      isPending,
    ]
  );

  const StepsAndHistoryOfSingleBPMN = useMemo(
    () => (
      <StepsAndHistoryViewer
        selectedStep={selectedStep}
        instanceId={instanceId}
        setSelectedStep={setSelectedStep}
      />
    ),
    [selectedStep, instanceId]
  );

  useEffect(() => {
    const ev = () => {
      setIsRetry(true);
      setTimeout(() => {
        setIsRetry(false);
      }, 1000);
    };

    window.addEventListener("openProcessInThisTabEvent", ev);

    return () => {
      window.removeEventListener("openProcessInThisTabEvent", ev);
    };
  }, []);

  return (
    <div className={`${styles.bpmnAndTableContainer} w-100 d-flex flex-column`}>
      {isRetry ? (
        ""
      ) : (
        <>
          <BPMN_STEPS_VIEWER
            instanceId={instanceId}
            selectedDiagramIndexId={selectedStep}
            getRelatedData={(data) => {
              relatedDataRef.current = data;
            }}
            setSelectedStep={setSelectedStep}
          />
          {isPending ? "" : BPMN}
          {StepsAndHistoryOfSingleBPMN}
        </>
      )}
    </div>
  );
};

export default ShowBpmnByXmlData;
