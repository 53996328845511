// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./ChangeEffectOfSecurityEventModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  changeAsyncEffectTypeOfSecurityEvent,
  getAsyncSecurityEvent,
  securityEventSlice,
} from "../../../Features/SecurityEvents/securityEventsSlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import { Spinner } from "reactstrap";
// Utils

// Models
import { I_SecurityEventRequestInterface } from "../../../Models/sendingDataSchema";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Models

type ChangeEffectOfSecurityEventModalProps = {
  eventId: string[] | number[];

  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;

  formsData: I_SecurityEventRequestInterface;

  setSecurityEventDataToSendToServer: Function;
};

const ChangeEffectOfSecurityEventModal: React.FunctionComponent<
  ChangeEffectOfSecurityEventModalProps
> = ({
  eventId,
  setIsModalInScreen,
  formsData,
  setSecurityEventDataToSendToServer,
}) => {
  const dispatch = useAppDispatch();

  const [selectedEffectType, setSelectedEffectType] = useState<string | number>(
    ""
  );

  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const isNightOn = useContext(IsNightModeOnContext);

  const { securityEventEffectType } = useAppSelector(
    (state) => state.allRoles.data
  );
  const { isDone, isPending } = useAppSelector(
    (state) => state.securityEvent.changeEffectStatus
  );

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const closeModal = useCallback(() => {
    if (!outerContentRef.current) return;
    if (!innerContentRef.current) return;

    outerContentRef.current.style.opacity = "0";
    innerContentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  }, [setIsModalInScreen]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!outerContentRef.current) return;
      if (!innerContentRef.current) return;

      outerContentRef.current.style.opacity = "0.75";
      innerContentRef.current.style.top = "0px";
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    const keys = Object.keys(securityEventEffectType);
    setSelectedEffectType(securityEventEffectType[keys[0]].value);
  }, [securityEventEffectType]);

  useEffect(() => {
    if (!isDone) return;
    closeModal();
  }, [isDone, closeModal]);

  useEffect(() => {
    return () => {
      dispatch(securityEventSlice.actions.resetEffectStatus());
    };
  }, [dispatch]);

  return (
    <div className={`${styles.changeEffectModalContainer}`}>
      <div
        className={`${styles.startFomModal}  w-100 d-flex flex-column align-items-center`}
      >
        <div
          className={`${styles.outerContent}`}
          ref={outerContentRef}
          onClick={closeModal}
        ></div>
        <div
          className={`${styles.innerContent}  ${isNightOn ? "nightBg1" : ""} `}
          ref={innerContentRef}
        >
          <p className="w-100 d-flex flex-row align-items-center justify-content-end">
            نوع اثر را برای ویرایش انتخاب کنید
          </p>

          <CustomSelectOption2
            getSelectedOption={(data) => {
              setSelectedEffectType(data);
            }}
            initialValue={`پیشفرض : ${(() => {
              const keys = Object.keys(securityEventEffectType);
              return securityEventEffectType[keys[0]].value;
            })()}`}
            initialTitle={`پیشفرض : ${(() => {
              const keys = Object.keys(securityEventEffectType);
              return securityEventEffectType[keys[0]].title;
            })()}`}
            options={Object.keys(securityEventEffectType).map((item) => ({
              title: securityEventEffectType[item].title,
              value: securityEventEffectType[item].value,
            }))}
            containerClassName="w-100"
          />
          <button
            className="operationEvent submitBtn w-100 mt-3 d-flex flex-row align-items-center justify-content-center"
            onClick={() => {
              dispatch(
                changeAsyncEffectTypeOfSecurityEvent({
                  userToken,
                  _data: {
                    effectType: String(selectedEffectType),
                    eventIds: eventId,
                  },
                })
              )
                .then(() => {
                  dispatch(
                    getAsyncSecurityEvent({
                      userToken,
                      _data: formsData,
                    })
                  );
                })
                .then(() => {
                  setSecurityEventDataToSendToServer([]);
                })
                .then(() => {
                  closeModal();
                });
            }}
          >
            {isPending ? <Spinner /> : "تایید"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeEffectOfSecurityEventModal;
