// React
import React from "react";
// React

// CSS
import styles from "./TopDetailItem.module.css";
// CSS

type TopDetailItemProps = {
  title: {
    className?: string;
    data: string;
  };
  value: {
    className?: string;
    data: string | React.ReactElement;
  };
  containerClassName?: string;
};

const TopDetailItem: React.FunctionComponent<TopDetailItemProps> = ({
  title,
  value,
}) => {
  return (
    <div
      className={`${styles.topDetailItemContainer} px-4 py-1 d-flex flex-row-reverse align-items-center justify-content-center gap-2`}
    >
      <span className={`${styles.title} ${title.className} `}>
        {title.data}
      </span>
      <span className={`${styles.value} ${value.className}`}>{value.data}</span>
    </div>
  );
};

export default TopDetailItem;
