// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./ShowAndSubmitFormByTaskId.module.css";
// CSS

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Components
import { Form } from "@formio/react";
// Components

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { taskSlice } from "../../../Features/TasksSlice/tasksSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
import { Spinner } from "reactstrap";
// Redux

// Urls
import { mainUrl } from "../../../Services/urls";
import {
  postAsyncFormFromSpecificTaskFromProcesses,
  processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
// Urls

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Contexts

// Utils
import { resetReferenceOfObject } from "../../../Utils/resetReferenceOfObject";
import { convertToPersianDateFromGeorgianDateInString } from "../../../Utils/FormioUtils/convertToPersianDateFromGeorgianDateInString";
import { convertToGeorgianDateFormPersianDateInString } from "../../../Utils/FormioUtils/convertToGeorgianDateFormPersianDateInString";
// Utils

// Modules
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Hooks
import usePersianDatePickerEventHandler from "../../../Hooks/usePersianDatePickerEventHandler";
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { useMapRenderInLittleModalInTasks } from "../../../Hooks/MapHooks/useMapRenderInLittleModalInTasks";
// Hooks

type ShowAndSubmitFormByTaskIdProps = {
  taskId: string;
  state: { formUrl: string };
  isPending: boolean;
  processKey: string;
};

const ShowAndSubmitFormByTaskId: React.FunctionComponent<
  ShowAndSubmitFormByTaskIdProps
> = ({ taskId, state, isPending, processKey }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isDone } = useAppSelector(
    (state) => state.processes.submitSingleSpecificForm
  );

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const [form, setForm] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    current: {
      data: {},
    },
  });

  console.log(setFormData);

  const [nForm, setNForm] = useState<any>({});
  console.log(nForm);
  const [isFormShowPending, setIsFormShowPending] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const data: any = useAppSelector(
    (state) => state.processes.taskDetailByProcesses.form.formData
  );

  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (!Boolean(state.formUrl)) return;
    fetch(`${mainUrl}${state.formUrl}`)
      .then((res) => res.json())
      .then((data) => setForm(data))
      .then(() => setIsFormShowPending(false))
      .catch((err) => {
        setIsFormShowPending(false);
        console.log(err);
        setHasError(true);
      });
  }, [state]);
  //
  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.changeCurrLocation("ارسال فرم شروع با وظیفه")
    );
  }, [dispatch]);
  //
  useEffect(() => {
    return () => {
      dispatch(taskSlice.actions.resetFormSubmitStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isDone) return;
    navigate(`/processes/show-tasks-by-processKey/${processKey}`);
  }, [isDone, navigate, processKey]);
  //
  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetSubmissionSingleSpecificForm());
    };
  }, [dispatch]);

  usePersianDatePickerEventHandler(isFormShowPending);

  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormShowPending,
  });

  useMapRenderInLittleModal(isFormShowPending);

  useMapRenderInLittleModalInTasks(isFormShowPending);

  return (
    <div
      className={`${styles.showAndSubmitFormByTaskIdContaiener} ${
        isNightOn ? "nightBg1" : ""
      } w-100`}
    >
      <p
        className="w-100 d-flex align-items-center justify-content-end"
        style={{
          color: "#111D64",
          fontSize: "20px",
          fontWeight: "bold",
        }}
        onClick={() => {
          console.log(
            convertToPersianDateFromGeorgianDateInString(
              resetReferenceOfObject(data)
            )
          );
        }}
      >
        برای ادامه عملیات ، فرم زیر را تکمیل کنید
      </p>

      {isFormShowPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {hasError ? (
            <>مشکلی در نمایش فرم پیش آمده</>
          ) : (
            <>
              {Object.keys(data).length > 0 ? (
                <Form
                  src={form}
                  onChange={(data: any) => {
                    formData.current = data;
                  }}
                  onFormLoad={(e: any) => {
                    console.log(e, "loaded");
                  }}
                  onSubmit={(data: any) => {
                    console.log(formData.current.data);
                    if (
                      !permitionCheckerWithoutToast(
                        allGroups,
                        [
                          "Tenant_Full_Manager",
                          "Tenant_Basic_Manager",
                          "Process_Full_Manager",
                          "Running_Process_Task_Manager",
                        ],
                        isFullRenderd
                      )
                    )
                      return;
                    Boolean(
                      convertToGeorgianDateFormPersianDateInString(
                        formData.current.data
                      )
                    )
                      ? dispatch(
                          postAsyncFormFromSpecificTaskFromProcesses({
                            userToken,
                            formData: JSON.parse(
                              convertToGeorgianDateFormPersianDateInString(
                                JSON.stringify(formData.current.data)
                              )
                            ),
                            taskId: String(taskId),
                          })
                        )
                      : dispatch(
                          postAsyncFormFromSpecificTaskFromProcesses({
                            userToken,
                            formData: formData.current.data,
                            taskId: String(taskId),
                          })
                        );
                  }}
                  onError={() => {
                    console.log("errrrrrrrrrrrrrrrrrr");
                  }}
                  submission={{
                    data: convertToPersianDateFromGeorgianDateInString(
                      resetReferenceOfObject(data)
                    )
                      ? resetReferenceOfObject(
                          convertToPersianDateFromGeorgianDateInString(
                            resetReferenceOfObject(data)
                          )
                        )
                      : data,
                  }}
                />
              ) : (
                <Form
                  src={form}
                  onChange={(data: any) => {
                    setNForm(data);
                    formData.current = data;
                  }}
                  onFormLoad={(e: any) => {
                    console.log(e, "loaded");
                  }}
                  onSubmit={(data: any) => {
                    console.log(formData.current.data);

                    if (
                      !permitionCheckerWithoutToast(
                        allGroups,
                        [
                          "Tenant_Full_Manager",
                          "Tenant_Basic_Manager",
                          "Process_Full_Manager",
                          "Running_Process_Task_Manager",
                        ],
                        isFullRenderd
                      )
                    )
                      return;

                    Boolean(
                      convertToGeorgianDateFormPersianDateInString(
                        formData.current.data
                      )
                    )
                      ? dispatch(
                          postAsyncFormFromSpecificTaskFromProcesses({
                            userToken,
                            formData: JSON.parse(
                              convertToGeorgianDateFormPersianDateInString(
                                JSON.stringify(formData.current.data)
                              )
                            ),
                            taskId: String(taskId),
                          })
                        )
                      : dispatch(
                          postAsyncFormFromSpecificTaskFromProcesses({
                            userToken,
                            formData: formData.current.data,
                            taskId: String(taskId),
                          })
                        );

                    console.log("submit", formData.current.data);
                  }}
                  onError={() => {
                    console.log("errrrrrrrrrrrrrrrrrr");
                  }}
                  options={
                    {
                      // readOnly: !!data,
                    }
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ShowAndSubmitFormByTaskId;
