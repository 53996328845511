import { LanguageType } from "../Models/types";

export const sidebarLanguage: {
  [key in LanguageType]: {
    [key: string]: string;
  };
} = {
  fa: {
    home: "خانه",
    tenant: "سازمان ها",
    users: "کاربران",
    groups: "گروه ها",
    tasks: "وظایف",
    processes: `مدیریت فرآیند ها`,
    allocatedProcesses: "رویه های کاری",
    formTemplates: "فرم های پیش ساخته",
    servers: "پایگاه داده",
    userIdentities: "فیلد های هویتی",
    callOfRegistration: "فراخوان های ثبت نام",
    posts: "اعلانات",
    securityEvents: "رویداد های امنیتی",
  },
  en: {
    home: "home",
    tenant: "tenant",
    users: "users",
    groups: "groups",
    tasks: "tasks",
    processes: "processes",
    allocatedProcesses: "allocated processes",
    formTemplates: "form templates",
    servers: "servers",
    userIdentities: " user identities",
    callOfRegistration: " call of registration ",
    posts: "posts",
    securityEvents: " securityevents ",
  },
};
