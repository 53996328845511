import { LanguageType } from "../Models/types";

export const breadCrumbsLanguage: {
  [key in LanguageType]: {
    [key: string]: string;
  };
} = {
  fa: {
    //Home
    home: "خانه",
    //Home

    // Users
    users: "کاربران",
    addUsers: "افزودن کاربر",
    singleUser: "اطلاعات کاربر تکی",
    // Users

    // Profile
    profile: "پروفایل ",
    // Profile

    // Forms
    showRelatedForms: "نمایش فرم های مربوطه",
    addForm: "افزودن فرم",
    showForm: "نمایش فرم",
    editSelectedForm: "ویرایش فرم انتخابی",
    // Forms

    // Processes
    processes: "فرآیند ها",
    showSelectedProcess: "نمایش فرآیند انتخابی",
    showProcessTasks: "نمایش وظایف فرآیند",
    showProcessTask: "نمایش وظیفه",
    showRunningInstances: "نمایش اجرا ها",
    editProcess: "ویرایش فرآیند",
    addProcess: "افزودن فرآیند ",
    addProcessFastCreate: "افزودن سریع فرآیند",
    sendStartForm: "ارسال فرم شروع",
    showInstanceData: "نمایش اطلاعات اجرا",
    // Processes

    // Reports
    showProcessReport: "نمایش گزارش فرآیند",
    makeNewReport: "ساخت گزارش جدید",
    // Reports

    // Groups
    groups: "گروه ها",
    addGroups: "افزودن گروه",
    addIndirectMembership: "افزودن روابط غیرمستقیم",
    addGroupOnGroup: "افزودن گروه در گروه",
    editGroup: "ویرایش گروه",
    // Groups

    // Tasks
    tasks: "وظایف",
    taskDetails: "جزئیات وظیفه",
    sendTaskForm: "ارسال فرم وظیفه",
    // Tasks

    // Server Management
    serverManagement: "پایگاه های داده",
    addNewServer: "افزودن پایگاه جدید",
    // Server Management

    // Tenants
    tenants: "سازمان ها",
    addTenant: "افزودن سازمان",
    // Tenants

    // User Identities
    userIdentities: "فیلد های هویتی",
    addFieldToUserIdentity: "افزودن فیلد",
    showFieldOfUserIdentity: "نمایش فیلد",
    addOptionToSingleUserIdentity: "ساخت زیر شناسه",
    editFieldOfUserIdentity: "ویرایش فیلد",
    addOptionToSingleAttribute: "افزودن گزینه به شناسه",
    connectedIdentities: "شناسه های متصل",
    // User Identities

    // Call Of Registration
    callOfRegistration: "فراخوان ها",
    showDetailsOfCallOfRegistration: "نمایش جزئیات",
    showRequestsOfCallOfRegistration: "نمایش درخواست ها",
    addCallOfRegistration: "افزودن فراخوان",
    editCallOfRegistration: "ویرایش فراخوان",
    // Call Of Registration

    // Posts
    posts: "اعلانات",
    editPost: "ویرایش اعلان",
    seePost: "مشاهده اعلان",
    addPost: "افزودن اعلان",
    // Posts

    // Security Event
    securityEvent: "رویداد های امنیتی",
    // Security Event

    // Form Samples
    formSamples: "فرم های پیش ساخته",
    editFormSamples: "ویرایش فرم پیش ساخته",
    addFormSamples: "افزودن فرم پیش ساخته",
    // Form Samples

    // Allocated Process
    allocatedProcesses: "رویه های کاری",
    showFormOfAllocatedProcesses: "نمایش فرم شروع رویه جدید",
    // Allocated Process
  },
  en: {
    //Home
    home: "home",
    //Home

    // Users
    users: "users",
    addUsers: "addUsers",
    singleUser: "singleUser",
    // Users

    // Profile
    profile: "profile",
    // Profile

    // Forms
    showRelatedForms: "showRelatedForms",
    addForm: "addForm",
    showForm: "showForm",
    editSelectedForm: "editSelectedForm",
    // Forms

    // Processes
    processes: "processes",
    showSelectedProcess: "showSelectedProcess",
    showProcessTasks: "showProcessTasks",
    showProcessTask: "showProcessTask",
    showRunningInstances: "showRunningInstances",
    editProcess: "editProcess",
    addProcess: "addProcess",
    addProcessFastCreate: "addProcessFastCreate",
    sendStartForm: "sendStartForm",
    showInstanceData: "showInstanceData",
    // Processes

    // Reports
    showProcessReport: "showProcessReport",
    makeNewReport: "makeNewReport",
    // Reports

    // Groups
    groups: "groups",
    addIndirectMembership: "addIndirectMembership",
    addGroupOnGroup: "addGroupOnGroup",
    editGroup: "editGroup",
    // Groups

    // Tasks
    tasks: "tasks",
    taskDetails: "taskDetails",
    sendTaskForm: "sendTaskForm",
    // Tasks

    // Server Management
    serverManagement: "serverManagement",
    addNewServer: "addNewServer",
    // Server Management

    // Tenants
    tenants: "tenants",
    addTenant: "addTenant",
    // Tenants

    // User Identities
    userIdentities: "userIdentities",
    addFieldToUserIdentity: "addFieldToUserIdentity",
    showFieldOfUserIdentity: "showFieldOfUserIdentity",
    addOptionToSingleUserIdentity: "addOptionToSingleUserIdentity",
    editFieldOfUserIdentity: "editFieldOfUserIdentity",
    addOptionToSingleAttribute: "addOptionToSingleAttribute",
    connectedIdentities: "connectedIdentities",
    // User Identities

    // Call Of Registration
    callOfRegistration: "callOfRegistration",
    showDetailsOfCallOfRegistration: "showDetailsOfCallOfRegistration",
    showRequestsOfCallOfRegistration: "showRequestsOfCallOfRegistration",
    addCallOfRegistration: "addCallOfRegistration",
    editCallOfRegistration: "editCallOfRegistration",
    // Call Of Registration

    // Posts
    posts: "posts",
    editPost: "editPost",
    seePost: "seePost",
    addPost: "addPost",
    // Posts

    // Security Event
    securityEvent: "",
    // Security Event

    // Form Samples
    formSamples: "formSamples",
    editFormSamples: "editFormSamples",
    addFormSamples: "addFormSamples",
    // Form Samples

    // Allocated Process
    allocatedProcesses: "allocatedProcesses",
    showFormOfAllocatedProcesses: "showFormOfAllocatedProcesses",
    // Allocated Process
  },
};
