// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../../Models/interfaces";
// Models

export const editIndirectMembershipGroupService = async (_data: {
  userToken: string;
  _data: {
    tenantId: string;
    addList?: {
      fromGroup: string;
      toGroup: string;
    }[];
    deleteList?: {
      id: number;
    }[];
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(`/groups/indirect-memberships/update`, _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
