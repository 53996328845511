import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  usersPage: {
    userSearchQuery: string;
    userGroup: string;
    userTenantId: string;
    page: number;
    size: number;
    singleUserPage: {
      currPage: number;
      pageSize: number;
      searchQuery: string;
    };
  };
  formsPage: {
    tenantId: string;
    searchParm: string;
    isStartable: boolean;

    showByProcessPage: {
      tenantId: string;
      searchParm: string;
    };
  };
  tasksPage: {
    pageNumber: number;
    size: number;
  };
  processPage: {
    tenantId: string;
    searchParm: string;
    isStartable: boolean;
    pageNumber: number;
    size: number;
  };
  processTasksPage: {
    pageNumber: number;
    size: number;
  };
  currLocationName: string;
} & {
  allocatedProcesses: {
    pageNumber: number;
    size: number;
  };
  formSample: {
    pageNumber: number;
    size: number;
  };
  serverManagement: {
    pageNumber: number;
    size: number;
  };
  callOfRegistration: {
    pageNumber: number;
    size: number;
  };
  posts: {
    pageNumber: number;
    size: number;
  };
  securityEvents: {
    pageNumber: number;
    size: number;
  };
} = {
  usersPage: {
    userSearchQuery: "",
    userGroup: "",
    userTenantId: "",
    page: 1,
    size: 10,
    singleUserPage: {
      currPage: 1,
      pageSize: 10,
      searchQuery: "",
    },
  },
  formsPage: {
    tenantId: "",
    searchParm: "",
    isStartable: false,

    showByProcessPage: {
      searchParm: "",
      tenantId: "",
    },
  },
  tasksPage: {
    pageNumber: 1,
    size: 10,
  },
  processPage: {
    tenantId: "",
    searchParm: "",
    isStartable: true,
    pageNumber: 1,
    size: 10,
  },
  processTasksPage: {
    pageNumber: 1,
    size: 10,
  },
  currLocationName: "خانه",
  allocatedProcesses: {
    pageNumber: 1,
    size: 10,
  },
  formSample: {
    pageNumber: 1,
    size: 10,
  },
  serverManagement: {
    pageNumber: 1,
    size: 10,
  },
  callOfRegistration: {
    pageNumber: 1,
    size: 10,
  },
  posts: {
    pageNumber: 1,
    size: 10,
  },
  securityEvents: {
    pageNumber: 1,
    size: 10,
  },
};

export const searchAndBackSlice = createSlice({
  name: "searchAndBack",
  initialState,
  reducers: {
    setUsersPageData: (state, action) => {
      state.usersPage.userGroup = action.payload.userGroup;
      state.usersPage.userSearchQuery = action.payload.userSearchQuery;
      state.usersPage.userTenantId = action.payload.userTenantId;
      state.usersPage.page = action.payload.page;
      state.usersPage.size = action.payload.size;
    },
    setFormsPageData: (state, action) => {
      state.formsPage.tenantId = action.payload.tenantId;
      state.formsPage.searchParm = action.payload.searchParm;
      state.formsPage.isStartable = action.payload.isStartable;
    },
    setShowFormByProcessData: (state, action) => {
      state.formsPage.showByProcessPage.searchParm = action.payload.searchParm;
      state.formsPage.showByProcessPage.tenantId = action.payload.tenantId;
    },
    setPorcessPageData: (state, action) => {
      state.processPage.isStartable = action.payload.isStartable;
      state.processPage.searchParm = action.payload.searchParm;
      state.processPage.tenantId = action.payload.tenantId;
      state.processPage.pageNumber = action.payload.pageNumber;
      state.processPage.size = action.payload.size;
    },
    setSingleUserEditPaginationData: (state, action) => {
      state.usersPage.singleUserPage.currPage = action.payload.currPage;
      state.usersPage.singleUserPage.pageSize = action.payload.pageSize;
      state.usersPage.singleUserPage.searchQuery = action.payload.searchQuery;
    },
    changeCurrLocation: (state, action) => {
      state.currLocationName = action.payload;
    },
    setPageNumberAndSizeOfTasksPage: (state, { payload: { size, page } }) => {
      state.tasksPage.pageNumber = page;
      state.tasksPage.size = size;
    },
    setProcessTasksPagePageNumberAdSize: (
      state,
      { payload: { size, page } }
    ) => {
      state.processTasksPage.pageNumber = page;
      state.processTasksPage.size = size;
    },
    setAllocatedProcessesData: (state, action) => {
      state.allocatedProcesses.pageNumber = action.payload.pageNumber;
      state.allocatedProcesses.size = action.payload.size;
    },
    setFormSamplesPageData: (state, action) => {
      state.formSample.pageNumber = action.payload.pageNumber;
      state.formSample.size = action.payload.size;
    },
    setServersDataForPages: (state, action) => {
      state.serverManagement.pageNumber = action.payload.pageNumber;
      state.serverManagement.size = action.payload.size;
    },
    setCallOfRegistrationDataForPages: (state, action) => {
      state.callOfRegistration.pageNumber = action.payload.pageNumber;
      state.callOfRegistration.size = action.payload.size;
    },
    setPostsDataForPages: (state, action) => {
      state.posts.pageNumber = action.payload.pageNumber;
      state.posts.size = action.payload.size;
    },
    securityEventDataForPages: (state, action) => {
      state.securityEvents.pageNumber = action.payload.pageNumber;
      state.securityEvents.size = action.payload.size;
    },
  },
});
