// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_EditExistingProcessSendingSchema } from "../../../Models/sendingDataSchema";
import { AxiosResponse } from "axios";
import { I_Message } from "../../../Models/interfaces";
// Models

export const editExistingProcessService = async (
  userToken: string,
  processKey: string,
  _data: I_EditExistingProcessSendingSchema
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(`/processes/${processKey}/edit`, _data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
