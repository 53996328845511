// React
import React, { useContext, useEffect } from "react";
// React

// CSS
import styles from "./ShowSelectedIdentity.module.css";
// CSS

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncAllOptionsForSingleAttribute,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import KeyValuePairShow from "../../../Utils/KeyValuePairShow/KeyValuePairShow";
// Contexts

const ShowSelectedIdentity = () => {
  const { identityId, persianName } = useParams();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant: tenant } = useAppSelector((state) => state.tenants);

  const [searchParams] = useSearchParams();

  const isNightOn = useContext(IsNightModeOnContext);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const singleAttributeData = useAppSelector(
    (state) => state.userIdentities.singleAttributeOptions
  );

  useEffect(() => {
    dispatch(
      getAsyncAllOptionsForSingleAttribute({
        userToken,
        attributeId: String(identityId),
        tenant: tenant.tenantId,
      })
    );
  }, [dispatch, userToken, identityId, tenant]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetSingleAttributeData());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.singleAttributeDataContainer}  ${
        isNightOn ? "nightBg1" : ""
      }  w-100 d-flex flex-column align-items-center `}
    >
      <div
        className={`${styles.topIdentifier} w-100 d-flex align-items-center justify-content-between my-4`}
      >
        {Boolean(searchParams.get("canShowMoreBtn")) ? (
          <button
            className="operationEvent submitBtn"
            onClick={() => {
              navigate(
                `/user-identities/add-option-to-single-attribute/${identityId}/${persianName}`
              );
            }}
          >
            ساخت زیر شناسه روی این شناسه
          </button>
        ) : (
          <span></span>
        )}
        <p
          style={{
            direction: "rtl",
          }}
        >
          نمایش شناسه ها با شمارنده {persianName}
        </p>
      </div>
      <div
        className={`${styles.identityOptionData} w-100 d-flex flex-column align-items-center`}
      >
        {Boolean(searchParams.get("identityOptions")) ? (
          <>
            {searchParams.get("identityOptions")!.length === 0 ? (
              <div
                className={`w-100 d-flex flex-row align-items-center justify-content-center`}
              >
                موردی وجود ندارد
              </div>
            ) : (
              <>
                {JSON.parse(
                  searchParams.get("identityOptions") || JSON.stringify([])
                ).map((item: { id: 0; title: string }) => (
                  <div
                    key={item.id}
                    className="w-100 d-flex flex-row align-items-center justify-content-between"
                  >
                    <p>{item.id}</p>
                    <p>{item.title}</p>
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {singleAttributeData.isPending ? (
              <div
                className={`w-100 d-flex flex-row align-items-center justify-content-center`}
              >
                موردی وجود ندارد
              </div>
            ) : (
              <>
                {singleAttributeData?.data?.length === 0 ? (
                  <div
                    className={`w-100 d-flex flex-row align-items-center justify-content-center`}
                  >
                    موردی وجود ندارد
                  </div>
                ) : (
                  <>
                    {singleAttributeData?.data?.map((item, index) => (
                      <div
                        key={item.id}
                        className="w-100 d-flex flex-row align-items-center justify-content-between"
                      >
                        <KeyValuePairShow
                          _key={{
                            title: item.title,
                            className: styles.keyClassName,
                          }}
                          _value={{
                            title: String(item.id),
                            className: styles.valueClassName,
                          }}
                          index={index % 2 === 0 ? "even" : "odd"}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShowSelectedIdentity;
