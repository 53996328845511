// React
import React from "react";
// React

// CSS
import styles from "./NotFound.module.css";
// CSS

const NotFound = () => {
  return (
    <p
      className={`${styles.notFoundCenterContainer} w-100 d-flex align-items-center justify-content-center mt-4 `}
    >
      صفحه ی مورد نظر شما پیدا نشد یا شما به آن دسترسی ندارید
    </p>
  );
};

export default NotFound;
