// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../../Models/interfaces";
// Models

export const editPublicGroupService = async (
  userToken: string,
  groupId: string,
  _data: {
    category: string;
    groupTitle: string;
  }
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      value: string;
      title: string;
      categoryType: {
        value: string;
        title: string;
      };
    };
  }>
> => {
  return _axios.post(`/groups/${groupId}/edit`, _data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
