// React
import React, { useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./MasterContainer.module.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// CSS

// Components
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
// import PerfectScrollbar from "react-perfect-scrollbar";
// Components

// Redux
import { useAppSelector } from "../../app/store";
// Redux

// Modules
import { useLocation } from "react-router";
// Modules

// Contexts
import { IsSidebarOpenContext } from "../../Contexts/IsSidebarOpen/IsSidebarOpenContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import Footer from "../../Components/Footer/Footer";
// Contexts

type MasterContainerProps = {
  children: React.ReactNode;
};

const MasterContainer: React.FunctionComponent<MasterContainerProps> = ({
  children,
}) => {
  const { currLanguage } = useAppSelector((state) => state.currLanguage);

  const childrenContainerRef = useRef<HTMLDivElement>(null);

  const isSidebarOpen = useContext(IsSidebarOpenContext);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const location = useLocation();

  useEffect(() => {
    if (!childrenContainerRef.current) return;

    childrenContainerRef.current.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  return (
    <>
      {location.pathname.includes("/login") ? (
        <>{children}</>
      ) : (
        <>
          <div
            className={`${styles.masterContainerContainer}  ${
              isNightModeOn ? ` night ` : ""
            }`}
          >
            <Sidebar />
            <div
              className={`${styles.childrenContainerClassName}  ${
                isSidebarOpen ? styles.full : ""
              } ${isNightModeOn ? styles.dark : ""} `}
            >
              <div className={`${styles.headerContainer} `}>
                <Header />
              </div>
              <div
                className={`${styles.childrenClassName}  ${
                  isSidebarOpen ? styles.full : ""
                } ${
                  currLanguage !== "fa" &&
                  window.innerWidth > 1000 &&
                  styles.englishStyles
                }`}
                ref={childrenContainerRef}
              >
                {window.innerWidth > 1000 ? (
                  <>
                    {/* <PerfectScrollbar
                      style={{
                        zIndex: 9999,
                      }}
                      id="perfectScrollbarCustomId"
                    >
                    </PerfectScrollbar> */}
                    {children}
                    <Footer />
                  </>
                ) : (
                  <div className="noPerfectScrollbar">{children}</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MasterContainer;
