// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
import { AxiosResponse } from "axios";
// Axios

// Models
import { I_AddingNewSingleCallOfRegistration } from "../../../Models/sendingDataSchema";
import { I_Message } from "../../../Models/interfaces";
// Models

export const addNewSingleCallOfRegistrationService = async (_data: {
  _data: I_AddingNewSingleCallOfRegistration;
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: typeof _data._data & {
      id: number;
      active: boolean;
      groups: {
        value: string;
        title: string;
        categoryType: {
          value: string;
          title: string;
        };
      }[];
    };
  }>
> => {
  return _axios.post(`/call-of-registrations/add`, _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
