import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const deleteFormService = (_data: {
  _data: {
    processKey: string;
    formId: string;
  };
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: {};
  }>
> => {
  return _axios.post(
    `/processes/${_data._data.processKey}/forms/${_data._data.formId}/delete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
