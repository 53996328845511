// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./StartAllocatedProcessModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  allocatedProcessesSlice,
  startAsyncNewAllocatedProcessInstance,
} from "../../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Formats
// Formats

// Modules
import { useNavigate } from "react-router-dom";
import { convertToGeorgianDateFormPersianDateInString } from "../../../Utils/FormioUtils/convertToGeorgianDateFormPersianDateInString";
// Modules

// DayJs
import dayJs from "dayjs";
import faLocale from "dayjs/locale/fa";
import jalaliday from "jalaliday";
// DayJs

dayJs.extend(jalaliday);

type StartAllocatedProcessModalProps = {
  processKey: string;
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  formData: {};
  persianName: string;
};

const StartAllocatedProcessModal: React.FunctionComponent<
  StartAllocatedProcessModalProps
> = ({ processKey, setIsModalInScreen, formData, persianName }) => {
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [_formData, set_formData] = useState<{
    businessKey: string;
    title: string;
  }>({
    businessKey: `${processKey}_businessKey_${dayJs()
      .calendar("jalali")
      .locale(faLocale)
      .format("YYYY-MM-DD")}`,
    title: `${persianName}_${dayJs()
      .calendar("jalali")
      .locale(faLocale)
      .format("YYYY-MM-DD")}`,
  });

  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const isNightOn = useContext(IsNightModeOnContext);

  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    if (!outerContentRef.current) return;
    if (!innerContentRef.current) return;

    outerContentRef.current.style.opacity = "0";
    innerContentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  }, [setIsModalInScreen]);

  const { isPending } = useAppSelector(
    (state) => state.allocatedProcesses.allocatedProcessStartingNewInstanceState
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!outerContentRef.current) return;
      if (!innerContentRef.current) return;

      outerContentRef.current.style.opacity = "0.75";
      innerContentRef.current.style.top = "0px";
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  // useEffect(() => {
  //   if (!isDone) return;

  // }, [isDone, closeModal, trackingCode, navigate]);

  useEffect(() => {
    return () => {
      dispatch(
        allocatedProcessesSlice.actions.resetStartingNewAllocatedProcessInstance()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.startFomModal}  w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.outerContent}`}
        ref={outerContentRef}
        onClick={closeModal}
      ></div>
      <div
        className={`${
          styles.innerContent
        } d-flex flex-column align-items-center ${
          isNightOn ? "nightBg1" : ""
        } `}
        ref={innerContentRef}
      >
        <CustomInput1
          inputType="text"
          value={_formData.businessKey}
          defaultValue={_formData.businessKey}
          onBlur={() => {}}
          onChange={(e) => {
            set_formData((prevState) => ({
              ...prevState,
              businessKey: e.target.value,
            }));
          }}
          placeHolder="کد کاری"
          dontShowSearchIcon
          containerClassName=""
          required
          inputClassName="w-100"
        />
        <CustomInput1
          inputType="text"
          value={_formData.title}
          defaultValue={_formData.title}
          onBlur={() => {}}
          onChange={(e) => {
            set_formData((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeHolder="نام فرآیند"
          dontShowSearchIcon
          containerClassName="mt-3"
          required
        />

        <div
          className={` w-100 d-flex flex-row align-items-cell-align-center justify-content-between  mt-4 `}
        >
          <button
            className={`${styles.startBtn} operationEvent`}
            onClick={() => {
              // if (
              //   !latinLetterWithoutSpaceFormat.test(_formData.businessKey) ||
              //   _formData.businessKey.includes(" ")
              // ) {
              //   customizedToast("کد فرآیند باید لاتین و بدون فاصله باشد", "info");
              //   return;
              // }
              // if (_formData.title.length <= 4) {
              //   customizedToast("طول عنوان فرآیند کمتر از 5 کاراکتر است", "info");
              //   return;
              // }
              // console.log(
              //   convertToGeorgianDateFormPersianDateInString(
              //     JSON.stringify(formData)
              //   )
              // );
              dispatch(
                startAsyncNewAllocatedProcessInstance({
                  userToken,
                  _data: {
                    _data: {
                      ..._formData,
                      formData: convertToGeorgianDateFormPersianDateInString(
                        JSON.stringify(formData)
                      )
                        ? JSON.parse(
                            convertToGeorgianDateFormPersianDateInString(
                              JSON.stringify(formData)
                            )
                          )
                        : formData,
                    },
                    processKey,
                    onDone: (tc) => {
                      Swal.fire({
                        icon: "success",
                        title: "عملیات موفق",
                        text: `کد رهگیری : ${tc}`,
                      }).then(() => {
                        closeModal();
                        navigate("/allocated-processes");
                      });
                    },
                  },
                })
              );
            }}
          >
            {isPending ? <Spinner /> : `تـایید`}
          </button>

          <button
            className={`${styles.cancelBtn} operationEvent  px-6`}
            onClick={closeModal}
          >
            انصراف
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartAllocatedProcessModal;
