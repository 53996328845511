// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_SendingNewServerData } from "../../../Models/sendingDataSchema";
import { I_Message } from "../../../Models/interfaces";
// Models

export const addServerService = async (_data: {
  userToken: string;
  _data: I_SendingNewServerData;
}): Promise<
  AxiosResponse<{ error: number; messages: I_Message[]; result: {} }>
> => {
  return _axios.post(`/data-servers/add`, _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
