// React
import React from "react";
// React

// CSS
import styles from "./TableTab.module.css";
// CSS

// Models
import { T_TableTab } from "../StepsAndHistoryViewer";
// Models

type TableTabProps = {
  data: T_TableTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<T_TableTab>>;
  selectedTab: T_TableTab;
  containerClassName?: string;
  willHidden?: boolean;
  onClick?: () => void;
};

const TableTab: React.FunctionComponent<TableTabProps> = ({
  data,
  selectedTab,
  setSelectedTab,
  containerClassName,
  willHidden,
  onClick,
}) => {
  return willHidden ? (
    <></>
  ) : (
    <button
      className={`${styles.tableTabItem} ${
        data.name === selectedTab.name ? `${styles.activeTab}` : ""
      }`}
      onClick={() => {
        setSelectedTab(data);
        if (typeof onClick === "function") {
          onClick();
        }
      }}
    >
      {data.title}
    </button>
  );
};

export default TableTab;
