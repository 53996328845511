// React
import ReactDOM from "react-dom/client";
// React

// Main Component
import App from "./App";
// Main Component

// Styles
import "bootstrap/dist/css/bootstrap.css";
import "./styles.css";
import "./CDNS_OF_ALL_DEPENDENCIES/FormIO_Styles/formIO_Styles_From_CDN.css";
import "./CDNS_OF_ALL_DEPENDENCIES/FormIO_Styles/formIO_Styles_From_CDN2.css";

// PDF Styles
import "./Utils/FormioUtils/pdfStyles.css";
// PDF Styles

// Styles

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import CDN from "./CDNS_OF_ALL_DEPENDENCIES/FormIO_Styles/CDN";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// if (process.env.NODE_ENV === "production") {
//   window.console.log = (...args) => {};
// }

root.render(
  <Provider store={store}>
    <Router>
      <CDN>
        <App />
      </CDN>
    </Router>
  </Provider>
);
