// Constants
import { defaultLat, defaultLon } from "../../../Constants/constants";
// Constants

export const persianDatePicker = (className?: string) => ({
  label: "تقویم فارسی",
  labelPosition: "top",
  placeholder: "",
  description: "",
  tooltip: "",
  prefix: "",
  suffix: "",
  widget: {
    type: "input",
  },
  inputMask: "",
  displayMask: "",
  applyMaskOn: "change",
  allowMultipleMasks: false,
  customClass: className || "",
  tabindex: "",
  autocomplete: "",
  hidden: false,
  hideLabel: false,
  showWordCount: false,
  showCharCount: false,
  mask: false,
  autofocus: false,
  spellcheck: true,
  disabled: false,
  tableView: true,
  modalEdit: false,
  multiple: false,
  persistent: true,
  inputFormat: "plain",
  protected: false,
  dbIndex: false,
  case: "",
  truncateMultipleSpaces: false,
  encrypted: false,
  redrawOn: "",
  clearOnHide: true,
  customDefaultValue: "",
  calculateValue: "",
  calculateServer: false,
  allowCalculateOverride: false,
  validateOn: "change",
  validate: {
    required: false,
    pattern: "",
    customMessage: "",
    custom: "",
    customPrivate: false,
    json: "",
    minLength: "",
    maxLength: "",
    strictDateValidation: false,
    multiple: false,
    unique: false,
  },
  unique: false,
  errorLabel: "",
  errors: "",
  key: "textField",
  tags: [],
  properties: {},
  conditional: {
    show: null,
    when: null,
    eq: "",
    json: "",
  },
  customConditional: "",
  logic: [],
  attributes: {
    "data-jdp": "true",
    "data-tooltip-id": "formTooltip",
    "data-tooltip-content": "اینجا راهنما قرار میگیرد",
  },
  overlay: {
    style: "",
    page: "",
    left: "",
    top: "",
    width: "",
    height: "",
  },
  type: "textfield",
  input: true,
  refreshOn: "",
  dataGridLabel: false,
  addons: [],
  inputType: "text",
  id: `el86fr${Date.now()}`,
  defaultValue: "",
});

export const complexPersianDatePicker = (
  className: string,
  data: {
    minDate: string;
    maxDate: string;
  }
) => ({
  label: "تقویم فارسی",
  labelPosition: "top",
  placeholder: "",
  description: "",
  tooltip: "",
  prefix: "",
  suffix: "",
  widget: {
    type: "input",
  },
  inputMask: "",
  displayMask: "",
  applyMaskOn: "change",
  allowMultipleMasks: false,
  customClass: className || "",
  tabindex: "",
  autocomplete: "",
  hidden: false,
  hideLabel: false,
  showWordCount: false,
  showCharCount: false,
  mask: false,
  autofocus: false,
  spellcheck: true,
  disabled: false,
  tableView: true,
  modalEdit: false,
  multiple: false,
  persistent: true,
  inputFormat: "plain",
  protected: false,
  dbIndex: false,
  case: "",
  truncateMultipleSpaces: false,
  encrypted: false,
  redrawOn: "",
  clearOnHide: true,
  customDefaultValue: "",
  calculateValue: "",
  calculateServer: false,
  allowCalculateOverride: false,
  validateOn: "change",
  validate: {
    required: false,
    pattern: "",
    customMessage: "",
    custom: "",
    customPrivate: false,
    json: "",
    minLength: "",
    maxLength: "",
    strictDateValidation: false,
    multiple: false,
    unique: false,
  },
  unique: false,
  errorLabel: "",
  errors: "",
  key: "textField",
  tags: [],
  properties: {},
  conditional: {
    show: null,
    when: null,
    eq: "",
    json: "",
  },
  customConditional: "",
  logic: [],
  attributes: {
    "data-jdp": "true",
    "data-tooltip-id": "formTooltip",
    "data-tooltip-content": "اینجا راهنما قرار میگیرد",
    "data-jdp-max-date": "today",
    "data-jdp-min-date": "today",
  },
  overlay: {
    style: "",
    page: "",
    left: "",
    top: "",
    width: "",
    height: "",
  },
  type: "textfield",
  input: true,
  refreshOn: "",
  dataGridLabel: false,
  addons: [],
  inputType: "text",
  id: `el86fr${Date.now()}`,
  defaultValue: "",
});

export const imageComponent = (imageSrc: string, className?: string ) => ({
  label: "HTML",
  tag: "img",
  className: className || "",
  attrs: [
    {
      attr: "src",
      value: imageSrc,
    },
  ],
  content: "",
  refreshOnChange: false,
  customClass: "",
  hidden: false,
  modalEdit: false,
  key: "html",
  tags: [],
  properties: {},
  conditional: {
    show: null,
    when: null,
    eq: "",
    json: "",
  },
  customConditional: "",
  logic: [],
  attributes: {},
  overlay: {
    style: "",
    page: "",
    left: "",
    top: "",
    width: "",
    height: "",
  },
  type: "htmlelement",
  input: false,
  placeholder: "",
  prefix: "",
  suffix: "",
  multiple: false,
  defaultValue: null,
  protected: false,
  unique: false,
  persistent: false,
  clearOnHide: true,
  refreshOn: "",
  redrawOn: "",
  tableView: false,
  dataGridLabel: false,
  labelPosition: "top",
  description: "",
  errorLabel: "",
  tooltip: "",
  hideLabel: false,
  tabindex: "",
  disabled: false,
  autofocus: false,
  dbIndex: false,
  customDefaultValue: "",
  calculateValue: "",
  calculateServer: false,
  widget: null,
  validateOn: "change",
  validate: {
    required: false,
    custom: "",
    customPrivate: false,
    strictDateValidation: false,
    multiple: false,
    unique: false,
  },
  allowCalculateOverride: false,
  encrypted: false,
  showCharCount: false,
  showWordCount: false,
  allowMultipleMasks: false,
  addons: [],
  id: `el26fr${Date.now()}`,
});

export const customEmailComponent = (className: string) => ({
  label: "ایمیل",
  labelPosition: "top",
  placeholder: "",
  description: "",
  tooltip: "",
  prefix: "",
  suffix: "",
  widget: {
    type: "input",
  },
  inputMask: "",
  displayMask: "",
  applyMaskOn: "change",
  allowMultipleMasks: false,
  customClass: `${className} direction__ltr` || "direction__ltr",
  tabindex: "",
  autocomplete: "",
  hidden: false,
  hideLabel: false,
  showWordCount: false,
  showCharCount: false,
  mask: false,
  autofocus: false,
  spellcheck: true,
  disabled: false,
  tableView: true,
  modalEdit: false,
  multiple: false,
  persistent: true,
  inputFormat: "plain",
  protected: false,
  dbIndex: false,
  case: "",
  truncateMultipleSpaces: false,
  encrypted: false,
  redrawOn: "",
  clearOnHide: true,
  customDefaultValue: "",
  calculateValue: "",
  calculateServer: false,
  allowCalculateOverride: false,
  validateOn: "change",
  validate: {
    required: false,
    pattern: "",
    customMessage: "",
    custom: "",
    customPrivate: false,
    json: "",
    minLength: "",
    maxLength: "",
    strictDateValidation: false,
    multiple: false,
    unique: false,
  },
  unique: false,
  errorLabel: "",
  errors: "",
  key: "textField",
  tags: [],
  properties: {},
  conditional: {
    show: null,
    when: null,
    eq: "",
    json: "",
  },
  customConditional: "",
  logic: [],
  attributes: {
    "custom-type": "email",
  },
  overlay: {
    style: "",
    page: "",
    left: "",
    top: "",
    width: "",
    height: "",
  },
  type: "textfield",
  input: true,
  refreshOn: "",
  dataGridLabel: false,
  addons: [],
  inputType: "email",
  id: `el86fr${Date.now()}`,
  defaultValue: "",
});

export const mapComponent = (
  mapDetails: {
    mapDetails: {
      lat: string;
      lon: string;
      markerText: string;
    };
    circleData: {
      isActive: boolean;
      lat: string;
      lon: string;
      circleColor: string;
      circleRadius: number | string;
    };
  },
  className: string,
  returnerMapId: (mapId: string) => void
) => {
  const desiredId = `customMapComponent${Date.now()}`;
  returnerMapId(desiredId);
  return {
    label: "HTML",
    attrs: [
      {
        attr: "id",
        value: desiredId,
        "data-is-map": true,
        "data-lat": mapDetails.mapDetails.lat || defaultLat,
        "data-lon": mapDetails.mapDetails.lon || defaultLon,
        "data-semiLikeId": desiredId,
        "data-is-circle-active": mapDetails.circleData.isActive
          ? mapDetails.circleData.isActive
          : false,
        "data-circle-lat": mapDetails.circleData.lat,
        "data-circle-lon": mapDetails.circleData.lon,
        "data-marker-text": mapDetails.mapDetails.markerText,
        "data-circle-color": mapDetails.circleData.circleColor,
        "data-circle-radius": mapDetails.circleData.circleRadius,
        id: desiredId,
      },
    ],
    attributes: {
      attr: "id",
      value: desiredId,
      "data-is-map": true,
      "data-lat": mapDetails.mapDetails.lat || defaultLat,
      "data-lon": mapDetails.mapDetails.lon || defaultLon,
      "data-semiLikeId": desiredId,
      "data-is-circle-active": mapDetails.circleData.isActive
        ? mapDetails.circleData.isActive
        : false,
      "data-circle-lat": mapDetails.circleData.lat,
      "data-circle-lon": mapDetails.circleData.lon,
      "data-marker-text": mapDetails.mapDetails.markerText,
      "data-circle-color": mapDetails.circleData.circleColor,
      "data-circle-radius": mapDetails.circleData.circleRadius,
      id: desiredId,
    },
    tag: "div",
    className: "",

    // attr: [
    //   {
    //     value: desiredId,
    //     id: desiredId,
    //     "data-is-map": true,
    //     "data-lat": mapDetails.mapDetails.lat || defaultLat,
    //     "data-lon": mapDetails.mapDetails.lon || defaultLon,
    //     "data-semiLikeId": desiredId,
    //     "data-is-circle-active": mapDetails.circleData.isActive
    //       ? mapDetails.circleData.isActive
    //       : false,
    //     "data-circle-lat": mapDetails.circleData.lat,
    //     "data-circle-lon": mapDetails.circleData.lon,
    //     "data-marker-text": mapDetails.mapDetails.markerText,
    //   },
    // ],
    content: "",
    refreshOnChange: false,
    customClass: className
      ? `${className} formioMapComponent`
      : "formioMapComponent",
    hidden: false,
    modalEdit: false,
    key: "mapData1",
    tags: [],
    properties: {},
    conditional: {
      show: null,
      when: null,
      eq: "",
      json: "",
    },
    customConditional: "",
    logic: [],

    // attributes: {},
    overlay: {
      style: "",
      page: "",
      left: "",
      top: "",
      width: "",
      height: "",
    },
    type: "htmlelement",
    input: false,
    placeholder: "",
    prefix: "",
    suffix: "",
    multiple: false,
    defaultValue: null,
    protected: false,
    unique: false,
    persistent: false,
    clearOnHide: true,
    refreshOn: "",
    redrawOn: "",
    tableView: false,
    dataGridLabel: false,
    labelPosition: "top",
    description: "",
    errorLabel: "",
    tooltip: "",
    hideLabel: false,
    tabindex: "",
    disabled: false,
    autofocus: false,
    dbIndex: false,
    customDefaultValue: "",
    calculateValue: "",
    calculateServer: false,
    widget: null,
    validateOn: "change",
    validate: {
      required: false,
      custom: "",
      customPrivate: false,
      strictDateValidation: false,
      multiple: false,
      unique: false,
    },
    allowCalculateOverride: false,
    encrypted: false,
    showCharCount: false,
    showWordCount: false,
    allowMultipleMasks: false,
    addons: [],
    attr: "id",
    value: desiredId,
  };
};

export const mapComponentWithInput = (className?: string) => ({
  label: "انتخاب مکان از روی نقشه",
  labelPosition: "top",
  placeholder: "",
  description: "",
  tooltip: "",
  prefix: "",
  suffix: "",
  widget: {
    type: "input",
  },
  inputMask: "",
  displayMask: "",
  applyMaskOn: "change",
  allowMultipleMasks: false,
  customClass: className || "",
  tabindex: "",
  autocomplete: "",
  hidden: false,
  hideLabel: false,
  showWordCount: false,
  showCharCount: false,
  mask: false,
  autofocus: false,
  spellcheck: true,
  disabled: false,
  tableView: true,
  modalEdit: false,
  multiple: false,
  persistent: true,
  inputFormat: "plain",
  protected: false,
  dbIndex: false,
  case: "",
  truncateMultipleSpaces: false,
  encrypted: false,
  redrawOn: "",
  clearOnHide: true,
  customDefaultValue: "",
  calculateValue: "",
  calculateServer: false,
  allowCalculateOverride: false,
  validateOn: "change",
  validate: {
    required: false,
    pattern: "",
    customMessage: "",
    custom: "",
    customPrivate: false,
    json: "",
    minLength: "",
    maxLength: "",
    strictDateValidation: false,
    multiple: false,
    unique: false,
  },
  unique: false,
  errorLabel: "",
  errors: "",
  key: "textField",
  tags: [],
  properties: {},
  conditional: {
    show: null,
    when: null,
    eq: "",
    json: "",
  },
  customConditional: "",
  logic: [],
  attributes: {
    "data-map-component": "true",
    "data-tooltip-id": "formTooltip",
    "data-tooltip-content": "اینجا راهنما برای نقشه قرار میگیرد",
  },
  overlay: {
    style: "",
    page: "",
    left: "",
    top: "",
    width: "",
    height: "",
  },
  type: "textfield",
  input: true,
  refreshOn: "",
  dataGridLabel: false,
  addons: [],
  inputType: "text",
  id: `el86fr${Date.now()}`,
  defaultValue: "",
});
