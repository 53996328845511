// Types
import { MapType } from "../../Models/types";
// Types

export const applyValueToInputAndRegisterToIt = (mapDetails: MapType) => {
  const mapId = JSON.parse(
    localStorage.getItem("mapId") || JSON.stringify("INIT")
  );

  const inputElement: any = document.getElementById(mapId);

  if (!inputElement) {
    const mapContainer = document.getElementById("CUSTOM_MAP_SELECTOR_MODAL")!;
    mapContainer.classList.add("hide");
    mapContainer.classList.remove("showAsFlex");
    // customizedToast("el not found ", "error");
    return;
  }

  inputElement.value = JSON.stringify({
    lat: mapDetails.lat,
    lon: mapDetails.lon,
    isCircleActive: mapDetails.isCircleActive,
    circleRadius: mapDetails.circleRadius,
  });

  var event = new Event("input", { bubbles: true });
  inputElement.dispatchEvent(event);

  const mapContainer = document.getElementById("CUSTOM_MAP_SELECTOR_MODAL")!;
  mapContainer.classList.add("hide");
  mapContainer.classList.remove("showAsFlex");
};
