import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message, I_Posts } from "../../../Models/interfaces";

export const getListOfPostsServices = async (_data: {
  userToken: string;
  _data: {
    page: number;
    size: number;
    tenant: string;
    placement: string;
    active: boolean | string;
    search: string;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_Posts;
  }>
> => {
  return _axios.get(
    `/posts?page=${_data._data.page}&size=${_data._data.size}&tenant=${_data._data.tenant}&placement=${_data._data.placement}&active=${_data._data.active}&search=${_data._data.search}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
