import { AxiosResponse } from "axios";
import { I_Message } from "../../../Models/interfaces";
import { _axios } from "../../Base_Axios_Config/_axios";

export const removePublicAddedGroupService = async (
  userToken: string,
  groupId: string
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/groups/${groupId}/delete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
