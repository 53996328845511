// React
import React, { useRef, useState } from "react";
// React

// CSS
import styles from "./MultipleSelect.module.css";
// CSS

// Icons
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
// Icons

type MultipleSelectProps = {
  title: string;
  containerClassName?: string;
  _setSelectedItems: (data: string[]) => void;
  _selectedItems: string[];
};

const MultipleSelect: React.FunctionComponent<MultipleSelectProps> = ({
  title,
  containerClassName,
  _setSelectedItems,
  _selectedItems,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(_selectedItems);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`${styles.multipleSelectContainer} ${containerClassName}`}
      onClick={() => {
        if (!inputRef.current) return;
        inputRef.current.focus();
      }}
    >
      <input
        type="text"
        ref={inputRef}
        onKeyDown={(e) => {
          console.log(e.key);
          if (e.key !== "Enter") return;
          if (!inputRef.current) return;

          const value = inputRef.current.value;

          if (value.trim().length === 0) return;

          setSelectedItems((prevState) => {
            if (prevState.includes(value.trim()) === true) return prevState;
            else {
              _setSelectedItems([...prevState, value]);
              return [...prevState, value];
            }
          });
          inputRef.current.value = "";
        }}
        placeholder={title}
        className={styles.input}
      />
      {selectedItems.length === 0 ? (
        <span
          className="icon-search"
          onClick={() => {
            if (!inputRef.current) return;

            const value = inputRef.current.value;

            if (value.trim().length === 0) return;

            setSelectedItems((prevState) => {
              if (prevState.includes(value.trim()) === true) return prevState;
              else {
                _setSelectedItems([...prevState, value]);
                return [...prevState, value];
              }
            });
            inputRef.current.value = "";
          }}
        ></span>
      ) : (
        <HiMiniArrowUturnLeft
          style={{
            fontSize: "1.5rem",
            color: "#8392AB",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedItems([]);
            _setSelectedItems([]);
          }}
        />
      )}
      <div
        className={`${styles.selectedItemsContaniner} d-flex flex-row align-items-center flex-wrap`}
        style={{
          maxWidth: "40%",
        }}
        onClick={(e) => {
          if (!inputRef.current) return;
          e.stopPropagation();
          inputRef.current.focus();
          inputRef.current.click();
        }}
      >
        {selectedItems.map((item) => (
          <p key={item} className={`${styles.selectedUserName}`}>
            <span>{item}</span>
            &nbsp;|&nbsp;
            <span
              className={`${styles.removeButton}`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedItems((prevState) =>
                  prevState.filter((_item) => {
                    return _item !== item;
                  })
                );
                _setSelectedItems(
                  selectedItems.filter((_item) => _item !== item)
                );
              }}
              style={{
                cursor: "pointer",
              }}
            >
              &#9587;
            </span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default MultipleSelect;
