// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./AddIndirectMembership.module.css";
// CSS

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncSingleIndirectGroupMembership,
  getAsyncAllGroups,
  groupSlice,
} from "../../../Features/GroupSlice/groupSlice";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Redux

// Utils
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const AddIndirectMembership = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isNightOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const allGroups = useAppSelector((state) => state.groups.allGroupsData);
  const {
    indirectMembership: {
      edit: { isDone, isPending },
    },
  } = useAppSelector((state) => state.groups);

  const fromSelectRef = useRef<HTMLSelectElement>(null);
  const toSelectRef = useRef<HTMLSelectElement>(null);

  const initializeData = useRef<{
    fromGroup: string;
    toGroup: string;
  }>({
    fromGroup: "",
    toGroup: "",
  });
  const [groupsToAdd, setGroupsToAdd] = useState<
    {
      id: number;
      data: {
        fromGroup: string;
        toGroup: string;
      };
    }[]
  >([]);

  useEffect(() => {
    dispatch(
      getAsyncAllGroups({
        userToken,
        _data: {
          page: 1,
          search: "",
          size: 999,
          tenant: selectedTenant.tenantId,
        },
      })
    );
  }, [dispatch, userToken, selectedTenant]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetIndirectMembershipAddingState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isDone) return;
    navigate("/groups");
  }, [isDone, navigate]);

  return (
    <div
      className={`${styles.indirectMembershipContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center`}
    >
      {!allGroups.isRecevedGroupsPending ? (
        <div className="w-100 d-flex flex-column align-items-center">
          <div
            className={`${styles.groupsContainer}  w-100 d-flex flex-row-reverse align-items-center justify-content-between `}
          >
            <div className={`${styles.selectOptionContainer}`}>
              <p>مبدا</p>
              <select
                className="form-select"
                defaultValue={""}
                onChange={(e) => {
                  initializeData.current.fromGroup = e.target.value;
                }}
                ref={fromSelectRef}
              >
                <option value="">یکی را انتخاب کنید</option>
                {allGroups.content.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${styles.selectOptionContainer}`}>
              <p>مقصد</p>
              <select
                className="form-select"
                defaultValue={""}
                onChange={(e) => {
                  initializeData.current.toGroup = e.target.value;
                }}
                ref={toSelectRef}
              >
                <option value="">یکی را انتخاب کنید</option>
                {allGroups.content.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button
            onClick={() => {
              if (initializeData.current.fromGroup === "") {
                customizedToast("مبدا وارد نشده", "info");
                return;
              }
              if (initializeData.current.toGroup === "") {
                customizedToast("مقصد وارد نشده", "info");
                return;
              }
              if (
                initializeData.current.fromGroup ===
                initializeData.current.toGroup
              ) {
                customizedToast("مبدا و مقصد نمیتواند یکسان باشد", "info");
                return;
              }
              setGroupsToAdd((prevState) => [
                ...prevState,
                {
                  id: Date.now(),
                  data: {
                    fromGroup: initializeData.current.fromGroup,
                    toGroup: initializeData.current.toGroup,
                  },
                },
              ]);
            }}
            className="operationEvent submitBtn align-self-start my-5"
          >
            افزودن
          </button>
        </div>
      ) : (
        <Spinner />
      )}

      <p
        className={`${styles.selectedItemsName} w-100 d-flex flex-row align-items-center justify-content-end`}
      >
        موارد انتخاب شده
      </p>

      <div
        className={`${styles.indirectMembershipItems} w-100 d-flex flex-column align-items-center `}
      >
        {groupsToAdd.map((item, index) => (
          <div
            key={item.id}
            className={`${styles.indirectMembershipItem} ${
              isNightOn ? "nightBg2" : ""
            } w-100 d-flex flex-row align-items-center justify-content-between`}
          >
            <div className="d-flex flex-row">
              <p>
                <span>از گروه</span>
                &nbsp;
                <span>{item.data.fromGroup}</span>
              </p>
              &nbsp;
              {"->"}
              &nbsp;
              <p>
                <span>به گروه</span>
                &nbsp;
                <span>{item.data.toGroup}</span>
              </p>
            </div>
            <div className="d-flex flex-row">
              <p
                className="operationEvent editBtn"
                onClick={() => {
                  if (!fromSelectRef.current) return;
                  if (!toSelectRef.current) return;

                  fromSelectRef.current.selectedIndex =
                    allGroups.content
                      .map((_item) => _item.value)
                      .findIndex((_item) => _item === item.data.fromGroup) + 1;

                  toSelectRef.current.selectedIndex =
                    allGroups.content
                      .map((_item) => _item.value)
                      .findIndex((_item) => _item === item.data.toGroup) + 1;

                  initializeData.current.fromGroup =
                    allGroups.content[
                      allGroups.content
                        .map((_item) => _item.value)
                        .findIndex((_item) => _item === item.data.fromGroup)
                    ].value;
                  initializeData.current.toGroup =
                    allGroups.content[
                      allGroups.content
                        .map((_item) => _item.value)
                        .findIndex((_item) => _item === item.data.toGroup)
                    ].value;

                  setGroupsToAdd((prevState) =>
                    prevState.filter((_item) => _item.id !== item.id)
                  );
                }}
              >
                <span className="icon-edit"></span>
              </p>
              <p
                className="operationEvent deleteBtn"
                onClick={() => {
                  Swal.fire({
                    icon: "question",
                    text: "آیا مطمعن هستید ؟",
                    title: "این رابطه حذف شود ؟",
                    showCancelButton: true,
                    cancelButtonText: "لغو",
                    confirmButtonText: "تایید",
                    customClass: isNightOn ? "swalNight" : "",
                  }).then(({ isConfirmed }) => {
                    if (!isConfirmed) return;
                    setGroupsToAdd((prevState) =>
                      prevState.filter((_item) => _item.id !== item.id)
                    );
                  });
                }}
              >
                <span className="icon-trash"></span>
              </p>
            </div>
          </div>
        ))}
      </div>

      <button
        className="operationEvent submitBtn align-self-start mt-3"
        onClick={() => {
          dispatch(
            editAsyncSingleIndirectGroupMembership({
              userToken,
              _data: {
                tenantId: selectedTenant.tenantId,
                addList: groupsToAdd.map((item) => ({
                  fromGroup: item.data.fromGroup,
                  toGroup: item.data.toGroup,
                })),
              },
            })
          );
        }}
      >
        {!isPending ? "تایید" : <Spinner />}
      </button>
    </div>
  );
};

export default AddIndirectMembership;
