// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./NewServer.module.css";
// CSS

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
// Utils

// Models
import { I_SendingNewServerData } from "../../../Models/sendingDataSchema";
// Models

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  addAsyncServerToDataServer,
  serverManagementSlice,
} from "../../../Features/ServerManagementSlice/ServerManagementSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

const NewServer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isNightOn = useContext(IsNightModeOnContext);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [data, setData] = useState<I_SendingNewServerData>({
    serverAddress: "",
    setAsCentralServer: false,
    tenantId: selectedTenant.tenantId,
    useCentralDataServer: false,
  });

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isPending, isDone } = useAppSelector(
    (state) => state.servers.serverAdding
  );

  useEffect(() => {
    if (!isDone) return;

    navigate("/servers");

    return () => {
      dispatch(serverManagementSlice.actions.resetServerAddingState());
    };
  }, [isDone, navigate, dispatch]);

  return (
    <div
      className={`${
        styles.newServerContainer
      } w-100 d-flex flex-column align-items-center  ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          افزودن سرور
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#8392AB",
            fontSize: "16px",
          }}
        >
          برای افزودن سرور موارد پایین را تکمیل کنید
        </p>
      </>

      <div
        className="d-flex flex-row-reverse align-items-center justify-content-between mt-4 flex-wrap w-100"
        style={{
          gap: "16px",
        }}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={String(data.serverAddress)}
          defaultValue={String(data.serverAddress)}
          onChange={(e) => {
            setData((prevState) => ({
              ...prevState,
              serverAddress: e.target.value,
            }));
          }}
          placeHolder="آدرس سرور"
          //  keyForShowOnTop="آدرس سرور"
          containerStyle={{
            width: "45%",
          }}
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {
            return false;
          }}
          onChange={(e) => {
            return false;
          }}
          placeHolder="نام سازمان"
          // keyForShowOnTop="نام سازمان"
          value={selectedTenant.tenantTitle}
          defaultValue={selectedTenant.tenantTitle}
          dontShowSearchIcon
          containerStyle={{
            width: "45%",
          }}
          disabled
        />
        <CustomCheckbox
          isChecked={data.setAsCentralServer}
          onChange={(e) => {
            e.preventDefault();
            setData((prevState) => ({
              ...prevState,
              setAsCentralServer: !prevState.setAsCentralServer,
            }));
          }}
          onClick={(e) => {
            e.preventDefault();
            setData((prevState) => ({
              ...prevState,
              setAsCentralServer: !prevState.setAsCentralServer,
            }));
          }}
          title="تنظیم به عنوان سرور مرکزی"
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 "
          }`}
          titleClassName={"mb-0"}
        />

        <CustomCheckbox
          isChecked={data.useCentralDataServer}
          onChange={(e) => {
            e.preventDefault();
            setData((prevState) => ({
              ...prevState,
              useCentralDataServer: !prevState.useCentralDataServer,
            }));
          }}
          onClick={(e) => {
            e.preventDefault();
            setData((prevState) => ({
              ...prevState,
              useCentralDataServer: !prevState.useCentralDataServer,
            }));
          }}
          title=" استفاده به عنوان سرور مرکزی"
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 mt-2"
          }`}
          titleClassName={"mb-0"}
        />
      </div>
      <button
        className="operationEvent submitBtn align-self-start mt-5"
        onClick={() => {
          dispatch(
            addAsyncServerToDataServer({
              userToken,
              _data: data,
            })
          );
        }}
      >
        {!isPending ? <>تایید</> : <Spinner />}
      </button>
    </div>
  );
};

export default NewServer;
