// React
import React, { useCallback, useEffect, useState } from "react";
// React

// FormIo
import { Form } from "@formio/react";
// FormIo

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
// Modules

// Utils
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Utils

// Redux
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// Urls
import { mainUrl } from "../../../Services/urls";
import usePersianDatePickerEventHandler from "../../../Hooks/usePersianDatePickerEventHandler";
// Urls

// Hooks
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { getFormsByProcessKeyService } from "../../../Services/FormManagement/get/getFormsByProcessKeyService";
import Pending from "../../../Components/Pending/Pending";
// Hooks

const ShowSelectedForm = () => {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState<any>({ display: "form", components: [] });
  const [isFormPending, setIsFormPending] = useState<boolean>(true);
  const [isFormError, setIsFromError] = useState<boolean>(false);

  const [isFormFileLoadPending, setIsFormFileLoadPending] =
    useState<boolean>(true);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const handleShow = useCallback(() => {
    if (typeof searchParams.get("id") !== "string") {
      navigate("/processes");
      return;
    }

    setIsFromError(false);
    setIsFormPending(true);
    setIsFormFileLoadPending(true);

    getFormsByProcessKeyService({
      id: String(searchParams.get("id")),
      processKey: String(searchParams.get("_processKey")),
      tenant: selectedTenant.tenantId,
      userToken,
      page: 1,
      search: "",
      size: 1,
    })
      .then(({ data }) => data.result.content[0])
      .then((data) => {
        setIsFormFileLoadPending(true);
        fetch(`${mainUrl}${data.url}`)
          .then((res) => res.json())
          .then((res) => {
            setForm(res);
          })
          .then(() => setIsFromError(false))
          .catch((err) => {
            console.log(err);
            setIsFromError(true);
          })
          .finally(() => {
            setIsFormPending(false);
            setIsFormFileLoadPending(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsFromError(true);
      })
      .finally(() => {
        setIsFormPending(false);
      });
  }, [searchParams, navigate, selectedTenant.tenantId, userToken]);

  useEffect(handleShow, [handleShow]);

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("کاربران"));
  }, [dispatch]);

  usePersianDatePickerEventHandler(isFormPending);

  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormPending,
  });

  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormPending,
  });

  useMapRenderInLittleModal(isFormPending);

  return (
    <div>
      {(isFormPending && !isFormError) || isFormFileLoadPending ? (
        <Pending className="mt-4" />
      ) : null}
      {!isFormPending && isFormError && !isFormFileLoadPending ? (
        <div
          className="w-100 d-flex flex-column align-items-center justify-content-between"
          onClick={handleShow}
        >
          مشکلی پیش آمده
        </div>
      ) : null}

      {!isFormPending && !isFormError && !isFormFileLoadPending ? (
        <Form
          form={form}
          onError={() => {
            customizedToast("مشکلی در هنگام دریافت فرم به وجود آمد", "error");
          }}
          submission={{}}
        />
      ) : null}
    </div>
  );
};

export default ShowSelectedForm;
// me74242@gmail.com
