// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./Pagination.module.css";
// CSS

// Icons
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPaginate from "react-paginate";
// Icons

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

type PaginationProps = {
  dataForPagination: {
    totalPages: number;
    size: number;
    currPage: number;
  };

  search: string;

  onPageNumbersClick: (pageNumber: number) => void;
  onPageSizeChange: (size: number) => void;
  onPageNumberChange: (pageNumber: number) => void;

  contaienrClassName?: string;
};

const Pagination: React.FunctionComponent<PaginationProps> = ({
  dataForPagination: { totalPages, currPage, size },
  onPageNumbersClick,
  onPageSizeChange,
  onPageNumberChange,
  contaienrClassName,
  search,
}) => {
  //
  //
  const [currentPage, setCurrentPage] = useState(1);

  const selectRef = useRef<HTMLSelectElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const handlePageChange = useCallback(
    (selectedPage: { selected: number }) => {
      setCurrentPage(selectedPage.selected + 1);
      onPageNumbersClick(selectedPage.selected + 1);
    },
    [onPageNumbersClick]
  );

  useEffect(() => {
    if (!selectRef.current) return;
    selectRef.current.selectedIndex = [5, 10, 15, 20, 999].findIndex(
      (item) => item === size
    );
  }, [size]);

  const prevSearch = useRef<string>(search);

  useEffect(() => {
    if (prevSearch.current === search) return;

    onPageNumberChange(1);

    return () => {
      prevSearch.current = search;
    };
  }, [search, onPageNumberChange]);

  console.log("currPage", currentPage);

  return (
    <>
      <div
        className={`${styles.paginationContainer}  w-100 d-flex flex-column align-items-center justify-content-between `}
      >
        <div
          className={`${styles.paginationInputContainer} ${
            isNightModeOn ? "nightBg2 mb-1" : ""
          } w-100 mt-2 d-flex align-content-center justify-content-between  `}
        >
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              width: "14rem",
            }}
          >
            <p
              className="mt-3"
              style={{
                color: "#8392AB",
                marginBottom: "1rem",
              }}
            >
              مورد در هر صفحه
            </p>
            <select
              ref={selectRef}
              className="form-select "
              style={{
                width: "5rem",
              }}
              aria-label="Default select example"
              defaultValue={10}
              onChange={(e) => {
                if (currPage === Number(e.target.value)) return;
                onPageNumberChange(1);
                onPageSizeChange(Number(e.target.value));
              }}
            >
              <option value="5">5</option>
              <option
                value={"10"}
                onClick={() => {
                  onPageNumbersClick(1);
                }}
              >
                10
              </option>
              <option
                value="15"
                onClick={() => {
                  onPageNumbersClick(1);
                }}
              >
                15
              </option>
              <option
                value="20"
                onClick={() => {
                  onPageNumbersClick(1);
                }}
              >
                20
              </option>
              <option
                value="999"
                onClick={() => {
                  onPageNumbersClick(1);
                }}
              >
                همه
              </option>
            </select>
          </div>
          <div className="w-50 d-flex align-items-center pt-3 justify-content-end">
            <div className="d-flex align-items-center ">
              <ReactPaginate
                previousLabel={
                  <IoIosArrowBack
                    style={{
                      border: "1px solid #E9ECEF",
                      borderRadius: "50vw",
                      color: "#8392AB",
                      width: "24px",
                      height: "24px",
                      marginRight: "4px",
                    }}
                    onClick={() => {
                      if (Number(currPage) <= 1) {
                        return;
                      }
                      onPageNumberChange(currPage - 1);
                    }}
                  />
                }
                onPageChange={handlePageChange}
                nextLabel={
                  <IoIosArrowForward
                    style={{
                      border: "1px solid #E9ECEF",
                      borderRadius: "50vw",
                      color: "#8392AB",
                      width: "24px",
                      height: "24px",
                      marginLeft: "4px",
                    }}
                    onClick={() => {
                      if (Number(currPage) >= totalPages) {
                        return;
                      }
                      onPageNumberChange(currPage + 1);
                    }}
                  />
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                pageClassName={`${styles.otherEl}`}
                pageLinkClassName={``}
                previousClassName={`${styles.nextAndBackEl}`}
                previousLinkClassName={``}
                nextClassName={`${styles.nextAndBackEl}`}
                nextLinkClassName={``}
                breakLabel="..."
                breakClassName={`${styles.otherEl}`}
                breakLinkClassName={`${styles.otherEl}`}
                containerClassName={`${styles.paginationContainer}`}
                activeClassName={` ${styles.activeEl}`}
                renderOnZeroPageCount={null}
                forcePage={currPage - 1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
