import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import {
  I_GetProcessResponseResult,
  I_Message,
} from "../../../Models/interfaces";

export const getProcessesService = (
  userToken: string,
  _data: {
    page: number;
    size: number;
    tenant: string;
    search: string;
    startable: boolean;
  }
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_GetProcessResponseResult;
  }>
> => {
  return _axios.get(
    `/processes?page=${_data.page}&size=${_data.size}&tenant=${_data.tenant}&search=${_data.search}&startable=${_data.startable}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
