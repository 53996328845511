// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./UserGroupsTab.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
// Redux

// Modules
import { useParams } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../../../Modules/permitionCheckerWithoutToast";
// Modules

// Redux
import { singleAccessibleUserSlice } from "../../../../Features/SingleAccessibleUserSlice/singleAccessibleUserSlice";
import { searchAndBackSlice } from "../../../../Features/SearchAndBackSlice/searchAndBackSlice";
import {
  getAsyncPossibleGroupMembershipSlice,
  possibleGroupMemberShipsSlice,
} from "../../../../Features/PossibleGroupMemberShipsSlice/possibleGroupMemberShipsSlice";
// Redux

// Utils
import { Spinner } from "reactstrap";
import Pagination from "../../../../Utils/Pagination/Pagination";
import CustomInput1 from "../../../../Utils/CustomInput1/CustomInput1";
// Utils

// Models
import { I_GetSingleUserAccessibleGroupsSendingData } from "../../../../Models/sendingDataSchema";
// Models

// Context
import { UserGroupsContext } from "../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import UserGroupsGrantingModal from "./UserGroupsGrantingModal/UserGroupsGrantingModal";
import CustomCheckbox from "../../../../Utils/CustomCheckbox/CustomCheckbox";
// Context

const GroupsTab = () => {
  const { userId: userName } = useParams();
  //
  const dispatch = useAppDispatch();
  //
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const singleUser = useAppSelector((state) => state.singleAccessibleUser);

  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);
  //

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [paginationData, setPaginationData] =
    useState<I_GetSingleUserAccessibleGroupsSendingData>({
      page: 1,
      size: 10,
      search: "",
      tenantId: selectedTenant.tenantId,
    });

  const isFullRenderd = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);

  const masterMemberShip = useAppSelector(
    (state) => state.possibleGroupMembership
  );
  const { groupMembership: singleUserMemberShips } = useAppSelector(
    (state) => state.singleAccessibleUser
  );

  const [groupsForModify, setGroupsForModify] = useState<{
    addGroups: { title: string; value: string }[];
    removeGroups: { title: string; value: string }[];
  }>({
    addGroups: [],
    removeGroups: [],
  });

  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);

  const [commonPermissions, setCommonPermissions] = useState<
    { title: string; value: string }[]
  >([]);

  useEffect(() => {
    const common = masterMemberShip.content.filter((permission1) =>
      singleUserMemberShips.some(
        (permission2) => permission2.value === permission1.value
      )
    );
    setCommonPermissions(common);
  }, [masterMemberShip, singleUserMemberShips]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !permitionCheckerWithoutToast(
          allGroups,
          [
            "Full_Manager",
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "User_Editor",
          ],
          isFullRenderd
        )
      )
        return;
      dispatch(
        getAsyncPossibleGroupMembershipSlice({
          searchQuery: paginationData,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, paginationData, userToken, allGroups, isFullRenderd]);

  useEffect(() => {
    return () => {
      dispatch(possibleGroupMemberShipsSlice.actions.resetPossiblePermition());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(singleAccessibleUserSlice.actions.resetEditingUserData());
  });

  return (
    <div
      className={`w-100 d-flex flex-row flex-wrap flex-row-reverse justify-content-between align-items-center ${
        styles.userGroupsTabContainer
      }  ${isNightOn ? "nightBg1" : ""} `}
    >
      {isModalInScreen ? (
        <UserGroupsGrantingModal
          addGroups={groupsForModify.addGroups}
          removeGroups={groupsForModify.removeGroups}
          setIsModalInScreen={setIsModalInScreen}
          setGroupsForModify={setGroupsForModify}
          userName={String(userName)}
        />
      ) : null}
      {singleUser.isUserDataChangePending ? (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          <span>در حال اعمال تغیرات</span>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="d-flex flex-row-reverse w-100 align-items-center justify-content-between">
            <p
              style={{
                textAlign: "right",
                color: "#111D64",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              ویرایش کاربر
            </p>

            <button
              className="operationEvent submitBtn"
              onClick={() => {
                setIsModalInScreen(true);
              }}
            >
              اعمال تغییرات
            </button>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "right",
              color: "#8392AB",
              fontSize: "16px",
            }}
          >
            انتخاب گروه ها
          </p>
          <>
            <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-4 flex-wrap">
              {permitionCheckerWithoutToast(
                allGroups,
                permitions.canSeeTenantTextBox,
                isFullRenderd
              ) && (
                <CustomInput1
                  inputType="text"
                  onBlur={() => {
                    return false;
                  }}
                  onChange={(e) => {
                    return false;
                  }}
                  placeHolder="نام سازمان ..."
                  //   keyForShowOnTop=""
                  containerStyle={{
                    width: "45%",
                  }}
                  value={selectedTenant.tenantTitle}
                  defaultValue={selectedTenant.tenantTitle}
                  dontShowSearchIcon
                  disabled
                />
              )}
              <CustomInput1
                inputType="text"
                value={paginationData.search}
                defaultValue={paginationData.search}
                onBlur={() => {}}
                onChange={(e) => {
                  setPaginationData((prevState) => ({
                    ...prevState,
                    search: e.target.value,
                  }));
                }}
                placeHolder="جست و جو کنید ..."
                //  keyForShowOnTop=""
                containerStyle={{
                  width: "45%",
                }}
              />
            </div>
            {masterMemberShip.isPending ? (
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <Spinner />
                <p>در حال آماده سازی لیست گروه ها</p>
              </div>
            ) : (
              <>
                {masterMemberShip.content.length === 0 ? (
                  <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                    <p>موردی وجود ندارد</p>
                  </div>
                ) : (
                  <>
                    {masterMemberShip.content.map((item) => {
                      return (
                        <div
                          key={item.value}
                          className="d-flex flex-row-reverse align-items-center justify-content-start mt-2"
                          style={{
                            width: "45%",
                          }}
                          onClick={() => {
                            dispatch(
                              searchAndBackSlice.actions.setSingleUserEditPaginationData(
                                {
                                  currPage: paginationData.page,
                                  pageSize: paginationData.size,
                                  searchQuery: paginationData.search,
                                }
                              )
                            );
                            if (
                              !commonPermissions
                                .map((item) => item.value)
                                .includes(item.value) === true
                            ) {
                              // dispatch(
                              //   editAsyncUserInformation({
                              //     userToken,
                              //     userName: String(userName),
                              //     newData: {
                              //       groupMembershipToDelete: (() => {
                              //         return [{ groupId: item.value }];
                              //       })(),
                              //     },
                              //   })
                              // ).then(() => {
                              //   if (
                              //     !permissionChecker(
                              //       allGroups,
                              //       [
                              //         "Full_Manager",
                              //         "Tenant_Full_Manager",
                              //         "Tenant_Basic_Manager",
                              //         "User_Editor",
                              //       ],
                              //       isFullRenderd
                              //     )
                              //   )
                              //     return;
                              //   dispatch(
                              //     getAsyncSingleUserAccessibleDataWithoutPending(
                              //       {
                              //         userName: String(userName),
                              //         userToken,
                              //       }
                              //     )
                              //   );
                              // });
                              // return;
                              if (
                                groupsForModify.addGroups
                                  .map((_item) => _item.value)
                                  .includes(item.value)
                              ) {
                                setGroupsForModify((prevState) => ({
                                  ...prevState,
                                  addGroups: [
                                    ...prevState.addGroups.filter(
                                      (_item) => _item.value !== item.value
                                    ),
                                  ],
                                }));
                                return;
                              } else {
                                setGroupsForModify((prevState) => ({
                                  ...prevState,
                                  addGroups: [
                                    ...prevState.addGroups,
                                    { title: item.title, value: item.value },
                                  ],
                                }));
                                return;
                              }
                            } else {
                              // dispatch(
                              //   editAsyncUserInformation({
                              //     userToken,
                              //     userName: String(userName),
                              //     newData: {
                              //       groupMembershipToAdd: (() => {
                              //         return [{ groupId: item.value }];
                              //       })(),
                              //     },
                              //   })
                              // ).then(() => {
                              //   if (
                              //     !permissionChecker(
                              //       allGroups,
                              //       [
                              //         "Full_Manager",
                              //         "Tenant_Full_Manager",
                              //         "Tenant_Basic_Manager",
                              //         "User_Editor",
                              //       ],
                              //       isFullRenderd
                              //     )
                              //   )
                              //     return;
                              //   dispatch(
                              //     getAsyncSingleUserAccessibleDataWithoutPending(
                              //       {
                              //         userName: String(userName),
                              //         userToken,
                              //       }
                              //     )
                              //   );
                              // });
                              // return;

                              if (
                                groupsForModify.removeGroups
                                  .map((_item) => _item.value)
                                  .includes(item.value)
                              ) {
                                setGroupsForModify((prevState) => ({
                                  ...prevState,
                                  removeGroups: [
                                    ...prevState.removeGroups.filter(
                                      (_item) => _item.value !== item.value
                                    ),
                                  ],
                                }));
                                return;
                              } else {
                                setGroupsForModify((prevState) => ({
                                  ...prevState,
                                  removeGroups: [
                                    ...prevState.removeGroups,
                                    { title: item.title, value: item.value },
                                  ],
                                }));
                                return;
                              }
                            }
                          }}
                        >
                          <CustomCheckbox
                            isChecked={commonPermissions
                              .map((item) => item.value)
                              .includes(item.value)}
                            onChange={() => {}}
                            onClick={() => {}}
                            title=""
                          />

                          {groupsForModify.addGroups
                            .map((_item) => _item.value)
                            .includes(item.value) ? (
                            <input
                              type="checkbox"
                              checked={true}
                              className={`mb-3 ${styles.addCheckBox}`}
                              style={{
                                marginLeft: "6px",
                                cursor: "pointer",
                              }}
                            />
                          ) : null}
                          {groupsForModify.removeGroups
                            .map((_item) => _item.value)
                            .includes(item.value) ? (
                            <p
                              className={`mb-3 ${styles.removeCheckBox}`}
                              style={{
                                marginLeft: "6px",
                                cursor: "pointer",
                              }}
                              onChange={() => {}}
                            >
                              X
                            </p>
                          ) : null}
                          <p style={{ marginRight: "8px", cursor: "pointer" }}>
                            {item.title}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </>
        </>
      )}
      <Pagination
        dataForPagination={{
          currPage: paginationData.page,
          size: paginationData.size,
          totalPages: masterMemberShip.totalPages,
        }}
        onPageNumberChange={(page) =>
          setPaginationData((prevState) => ({ ...prevState, page }))
        }
        onPageNumbersClick={(page) =>
          setPaginationData((prevState) => ({ ...prevState, page }))
        }
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({ ...prevState, size }));
          setPaginationData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={paginationData.search}
      />
    </div>
  );
};

export default GroupsTab;
