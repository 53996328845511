// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// React

// CSS
import styles from "./HistoryDetail.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getAsyncHistoryDetail,
  processInstanceHistorySlice,
} from "../../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
// Redux

// Icons
import { MdNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
// Icons

// Components
import HistoryTabItem from "../HistoryTab/HistoryTabItem/HistoryTabItem";
// Components

// Utils
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
import PendingManager from "../../../PendingManager/PendingManager";
// Utils

// Contexts
import {
  ProcessHistoryContext,
  SetProcessHistoryContext,
} from "../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import { withoutInformation } from "../HistoryTab/HistoryTabItem/TaskInformation/TaskInformation";
// Contexts

type HistoryTabProps = {
  instanceId: string;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
  selectedStep: string;
  setExtraChild: React.Dispatch<React.SetStateAction<React.ReactElement>>;
};

const HistoryDetail: React.FunctionComponent<HistoryTabProps> = ({
  instanceId,
  selectedStep,
  setExtraChild,
  setSelectedStep,
}) => {
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [, setSelectedHistoryItem] = useState<{
    selectedHistoryIndex: number;
  }>({ selectedHistoryIndex: 0 });

  const historyData = useContext(ProcessHistoryContext);
  const setProcessHistory = useContext(SetProcessHistoryContext);

  const { data, isDone, isError, isPending } = useAppSelector(
    (state) => state.processInstanceHistorySlice.historyDetail
  );

  const handleNextHistory = useCallback((): void => {
    const hasNextStep = historyData.allHistory.findIndex(
      (_i) => _i.id === historyData.selectedHistoryId
    );

    if (hasNextStep === -1) {
      console.clear();
      console.log(historyData);
      customizedToast("آخرین تاریخچه", "info");
      return;
    }

    setSelectedHistoryItem((prevState) => {
      const nextItem = historyData.allHistory[hasNextStep + 1];
      if (typeof nextItem === "undefined") {
        customizedToast("آخرین تاریخچه در این قسمت", "info");
        return prevState;
      }
      customizedToast("تاریخچه ی بعدی", "info");
      setProcessHistory((prevState) => ({
        ...prevState,
        selectedHistoryId: nextItem.id,
      }));

      return {
        selectedHistoryIndex: nextItem.stepNumber,
      };
    });
  }, [historyData, setProcessHistory]);

  const handlePrevHistory = useCallback((): void => {
    const hasNextStep = historyData.allHistory.findIndex(
      (_i) => _i.id === historyData.selectedHistoryId
    );

    if (hasNextStep === -1) {
      console.clear();
      console.log(historyData);
      customizedToast("اولین تاریخچه", "info");
      return;
    }

    setSelectedHistoryItem((prevState) => {
      const prevItem = historyData.allHistory[hasNextStep - 1];
      if (typeof prevItem === "undefined") {
        customizedToast("اولین تاریخچه در این قسمت", "info");
        return prevState;
      }
      customizedToast("تاریخچه ی قبلی", "info");
      setProcessHistory((prevState) => ({
        ...prevState,
        selectedHistoryId: prevItem.id,
      }));

      return {
        selectedHistoryIndex: prevItem.stepNumber,
      };
    });
  }, [historyData, setProcessHistory]);

  const memoBtns = useMemo(() => {
    const currIndex = historyData.allHistory.findIndex(
      (_i) => _i.id === historyData.selectedHistoryId
    );

    if (historyData.allHistory.length === 1) return <></>;

    return (
      <>
        <button
          className={`meloBorder bgTransparent textMeloMainColor py-1 px-2 d-flex 
            flex-row align-items-center justify-content-center rounded-2`}
          onClick={
            isPending
              ? () => {
                  customizedToast("لطفا منتظر پاسخ بمانید", "info");
                }
              : handlePrevHistory
          }
        >
          <MdNavigateBefore />
          تاریخچه قبلی
        </button>
        {currIndex === -1 ? withoutInformation : currIndex + 1}
        <button
          className="meloBorder  bgTransparent textMeloMainColor py-1 px-2 d-flex flex-row align-items-center justify-content-center rounded-2"
          onClick={
            isPending
              ? () => {
                  customizedToast("لطفا منتظر پاسخ بمانید", "info");
                }
              : handleNextHistory
          }
        >
          تاریخچه بعدی
          <MdOutlineNavigateNext />
        </button>
      </>
    );
  }, [handleNextHistory, handlePrevHistory, isPending, historyData]);

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncHistoryDetail({
        userToken,
        _data: {
          historyId: historyData.selectedHistoryId,
          processInstanceId: instanceId,
        },
      })
    );
  }, [dispatch, userToken, historyData, instanceId]);

  useEffect(() => {
    setExtraChild(memoBtns);
  }, [memoBtns, setExtraChild]);

  useEffect(() => {
    setSelectedHistoryItem({
      selectedHistoryIndex: historyData?.allHistory?.find((_i) =>
        _i?.id?.includes(historyData.selectedHistoryId)
      )!?.stepNumber,
    });
  }, [historyData]);

  useEffect(() => {
    return () => {
      setExtraChild(<></>);
    };
  }, [setExtraChild]);

  useEffect(() => {
    dispatch(processInstanceHistorySlice.actions.resetHistoryDetail());
    const timeout = setTimeout(() => {
      dataGetter();
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [dataGetter, dispatch]);

  useEffect(() => {
    return () => {};
  }, [dispatch]);

  return (
    <div className={`${styles.historyDetailContainer} w-100`}>
      <PendingManager
        isDone={isDone}
        isError={isError}
        isPending={isPending}
        onError={dataGetter}
        showAfterDone={<HistoryTabItem data={data} />}
      />
    </div>
  );
};

export default HistoryDetail;
