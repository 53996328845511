import { _axios } from "../../Base_Axios_Config/_axios";
import { I_AddUser } from "../../../Models/sendingDataSchema";

export const addUserService = async (_data: {
  userToken: string;
  _data: I_AddUser;
}) => {
  return _axios.post("/users/add", _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
