// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../../Models/interfaces";
import { I_AddingNewReportSendingData } from "../../../Models/sendingDataSchema";
// Models

export const addReportToProcessService = async (_data: {
  _data: I_AddingNewReportSendingData;
  userToken: string;
  processKey: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      id: number;
      processKey: string;
      reportIdentifier: string;
      readonly: boolean;
    };
  }>
> => {
  return _axios.post(
    `/processes/${_data.processKey}/reports/add`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
