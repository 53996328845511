// React
import React, { useContext, useState } from "react";
// React

// CSS
import styles from "./ShowSinglePost.module.css";
// CSS

// Modules
import { useSearchParams } from "react-router-dom";
import parse from "html-react-parser";
// Modules

// Utils
import KeyValuePairShow from "../../../Utils/KeyValuePairShow/KeyValuePairShow";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const ShowSinglePost = () => {
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [searchParams] = useSearchParams();

  const [formsData] = useState<{
    placementType: string;
    active: boolean;
    postData: string;
    id: string | number;
  }>({
    placementType: searchParams.get("placementType") || "LOGIN_PAGE",
    active: Boolean(searchParams.get("active")),
    postData: JSON.parse(searchParams.get("data") || JSON.stringify("")),
    id: searchParams.get("id") || "",
  });

  return (
    <div
      className={`${styles.showSinglePostContainer} ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div
        className={`${styles.singleItemsDetails} w-100 d-flex flex-column align-items-center`}
      >
        <KeyValuePairShow
          _key={{
            title: "محل نمایش",
          }}
          _value={{
            title: formsData.placementType,
          }}
        />
        <KeyValuePairShow
          _key={{
            title: "وضعیت",
          }}
          _value={{
            title: formsData.active ? "فعال" : "غیرفعال",
          }}
        />
      </div>
      <p
        className={`${styles.dataContainer} mt-5 w-100 d-flex flex-row align-items-center justify-content-end`}
      >
        : دیتای اعلان
      </p>

      <div
        className="w-100 "
        style={{
          direction: "rtl",
        }}
      >
        {parse(String(formsData.postData))}
      </div>
    </div>
  );
};

export default ShowSinglePost;
