// React
import React from "react";
// React

// CSS
import styles from "./CustomInput2.module.css";
// CSS

type CustomInput2Props = {
  title: string;
  type: "number" | "text" | "email" | "password" | "file";

  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  plaeHolder: string;
  defaultValue?: string;

  isDisabled?: boolean;

  containerClassName?: string;

  imageRenderingOptions?: {
    src: string;
    buttonForChangeOnClick: () => void;
    buttonInnerText: string;
  };

  fileInputReference?: any;

  containerStyle?: React.CSSProperties;

  required?: boolean;

  accept?: string;

  errors?: {
    errorMessage: string[];
  };
};

const CustomInput2: React.FunctionComponent<CustomInput2Props> = ({
  defaultValue,
  onBlur,
  plaeHolder,
  title,
  type,
  isDisabled,
  onChange,
  containerClassName,
  imageRenderingOptions,
  fileInputReference,
  containerStyle,
  required,
  accept,
  errors,
}) => {
  return (
    <div
      className={`${styles.customInput2Container} ${containerClassName} d-flex flex-column align-items-end justify-content-between`}
      style={containerStyle}
    >
      <p>
        {title} {required && <span style={{ color: "red" }}>*</span>}
      </p>
      {isDisabled ? (
        <p className="mt-1">{plaeHolder}</p>
      ) : (
        <>
          {type !== "file" ? (
            <input
              type={type}
              onBlur={(e) => {
                if (!onBlur) return;
                onBlur(e);
              }}
              onChange={(e) => {
                if (!onChange) return;
                onChange(e);
              }}
              className={`${styles.inputClassName}`}
              placeholder={plaeHolder}
              defaultValue={defaultValue}
              style={{
                direction: type === "email" ? "ltr" : "rtl",
              }}
            />
          ) : (
            <>
              <input
                type={type}
                ref={fileInputReference}
                onBlur={(e) => {
                  if (!onBlur) return;
                  onBlur(e);
                }}
                onChange={(e) => {
                  if (!onChange) return;
                  onChange(e);
                }}
                className={`${styles.inputClassName}`}
                placeholder={plaeHolder}
                defaultValue={defaultValue}
                style={{
                  display: "none",
                }}
                accept={accept}
              />
              {type === "file" ? (
                <div
                  className="d-flex align-items-center justify-content-between w-100"
                  style={{
                    minWidth: "300px",
                  }}
                >
                  <button
                    onClick={() => {
                      if (imageRenderingOptions?.buttonForChangeOnClick) {
                        imageRenderingOptions.buttonForChangeOnClick();
                      }
                    }}
                    className="btn btn-secondary"
                  >
                    {imageRenderingOptions?.buttonInnerText}
                  </button>
                  <img src={imageRenderingOptions?.src} alt="" />
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      <p
        style={{
          fontSize: "0.6rem !important",
          color: "red",
          fontWeight: "400",
          width: "250px",
          direction: "rtl",
        }}
      >
        {errors &&
          errors.errorMessage &&
          Array.isArray(errors.errorMessage) &&
          errors.errorMessage.map((item) => (
            <React.Fragment key={item}>
              <span>{item}</span>
              <br />
            </React.Fragment>
          ))}
      </p>
    </div>
  );
};

export default CustomInput2;
