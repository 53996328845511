import { _axios } from "../../Base_Axios_Config/_axios";

export const removeServerService = (userToken: string, serverId: string) => {
  return _axios.post(
    `/data-servers/${serverId}/delete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
