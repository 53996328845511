// React
import React, { useContext, useEffect, useState } from "react";
// React

// Utils
import { CSVLink } from "react-csv";
// Utils

// CSS
import styles from "./IndirectMembership.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// Modules
import { permissionChecker } from "../../../Modules/permissionChecker";
// Modules

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Features
import {
  getAsyncIndirectMemberships,
  groupSlice,
  removeAsyncSingleIndirectGroupMembership,
} from "../../../Features/GroupSlice/groupSlice";
// Features

// Components
// Components

// Utils
import { Spinner } from "reactstrap";
import Pagination from "../../../Utils/Pagination/Pagination";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import Swal from "sweetalert2";
// Utils

// Models
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
import { I_SendingPaginationData } from "../../../Models/sendingDataSchema";
// Models

// Modules
import { useNavigate } from "react-router-dom";
// Modules

const IndirectMembership = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const language = useAppSelector((state) => state.currLanguage.language);
  const indirectMembershipData = useAppSelector(
    (state) => state.groups.indirectMembership
  );
  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [paginationData, setPaginationData] = useState<I_SendingPaginationData>(
    {
      page: 1,
      size: 10,
      search: "",
      tenant: selectedTenant.tenantId,
    }
  );

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "id",
      },
      {
        label: "از گروه",
        key: "fromGroup",
      },
      {
        label: "به گروه",
        key: "toGroup",
      },
    ],

    data: indirectMembershipData.content.map((item) => ({
      id: item.id,
      fromGroup:
        item.fromGroup.categoryType.title + " : " + item.fromGroup.title,
      toGroup: item.toGroup.categoryType.title + " : " + item.toGroup.title,
    })),
    filename: "indirectMembership.csv",
  };

  useEffect(() => {
    if (
      !permissionChecker(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "All_Group_Manager",
          "Public_Group_Manager",
        ],
        isFullRenderd
      )
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncIndirectMemberships({
          _data: paginationData,
          userToken,
        })
      );
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [allGroups, isFullRenderd, dispatch, paginationData, userToken]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetIndirectMembershipsData());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.indirectMembershipContainer} w-100 ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          گروه ها
        </p>
        <div className="d-flex flex-col align-items-center">
          <button
            className="operationEvent submitBtn"
            onClick={() => {
              navigate(`/groups/new-group/indirect-membership`);
            }}
          >
            افزودن
            <span className="icon-Plus"></span>
          </button>
          <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
            csv خروجی
          </CSVLink>
        </div>
      </div>

      <div
        className={`${styles.inputsContainer} w-100 d-flex flex-row align-items-center mt-2 justify-content-end flex-wrap `}
      >
        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={(e) => {
              return false;
            }}
            placeHolder="  نام سازمان"
            //  keyForShowOnTop="  نام سازمان"
            containerStyle={{
              width: "45%",
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={paginationData.search}
          defaultValue={paginationData.search}
          onChange={(e) => {
            setPaginationData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="نام گروه"
          //  keyForShowOnTop="نام گروه"
          containerStyle={{
            width: "45%",
          }}
        />
      </div>
      {indirectMembershipData.isPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center ">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {indirectMembershipData.content.length === 0 ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center ">
              <p>موردی وجود ندارد </p>
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightModeOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.fromCategoryTab}`}>گروه مبدا</p>
                    <p className={`${styles.toGroupTab}`}>گروه مقصد</p>
                    <p className={`${styles.id}`}> شمارنده </p>
                  </div>

                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {indirectMembershipData.content.map((item) => (
                      <div
                        key={item.id}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <div className={`${styles.operationTab}`}>
                          <button
                            className={`operationEvent deleteBtn`}
                            onClick={() => {
                              Swal.fire({
                                icon: "question",
                                title: "آیا این گروه حذف شود ؟",
                                text: "عملیات غیر قابل بازگشت میباشد",
                                showCancelButton: true,
                                cancelButtonText: "لغو",
                                confirmButtonText: "حذف",
                                customClass: isNightModeOn ? "swalNight" : "",
                              }).then(({ isConfirmed }) => {
                                if (!isConfirmed) return;
                                dispatch(
                                  removeAsyncSingleIndirectGroupMembership({
                                    userToken,
                                    _data: {
                                      tenantId: selectedTenant.tenantId,
                                      deleteList: [
                                        {
                                          id: item.id,
                                        },
                                      ],
                                    },
                                  })
                                ).then(() => {
                                  dispatch(
                                    getAsyncIndirectMemberships({
                                      _data: paginationData,
                                      userToken,
                                    })
                                  );
                                });
                              });
                            }}
                          >
                            <span className="icon-trash"></span>
                          </button>
                          {/* <button
                        className={`operationEvent editBtn`}
                        onClick={() => {
                          navigate(
                            `/groups/edit-indirect-membership-groups/${item.id}`
                          );
                        }}
                      >
                        <span className="icon-edit"></span>
                      </button> */}
                        </div>
                        <p
                          className={`${styles.fromCategoryTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.fromGroup.title.length >= 16
                              ? item.fromGroup.title
                              : ""
                          }
                        >
                          {item.fromGroup.title.length >= 16
                            ? "..." + item.fromGroup.title.substring(0, 15)
                            : item.fromGroup.title}
                        </p>
                        <p
                          className={`${styles.toGroupTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.toGroup.title.length >= 16
                              ? item.toGroup.title
                              : ""
                          }
                        >
                          {item.toGroup.title.length >= 16
                            ? "..." + item.toGroup.title.substring(0, 15)
                            : item.toGroup.title}
                        </p>
                        <p
                          className={`${styles.id}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            String(item.id).length >= 16 ? String(item.id) : ""
                          }
                        >
                          {String(item.id).length >= 16
                            ? "..." + String(item.id).substring(0, 15)
                            : String(item.id)}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {indirectMembershipData.content.map((item) => (
                    <div key={item.id} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.id}</p>
                        <p>شمارنده</p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightModeOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span> نام گروه مبدا</span>
                          <span>{item.fromGroup.title}</span>
                        </p>
                        <p>
                          <span>نام گروه مقصد</span>
                          <span>{item.toGroup.title}</span>
                        </p>
                        <div className={`${styles.operationTab}`}>
                          <button
                            data-tooltip-content={language.delete}
                            data-tooltip-id="my-tooltip"
                            className={`operationEvent deleteBtn`}
                            onClick={() => {
                              Swal.fire({
                                icon: "question",
                                title: "آیا این گروه حذف شود ؟",
                                text: "عملیات غیر قابل بازگشت میباشد",
                                showCancelButton: true,
                                cancelButtonText: "لغو",
                                confirmButtonText: "حذف",
                                customClass: isNightModeOn ? "swalNight" : "",
                              }).then(({ isConfirmed }) => {
                                if (!isConfirmed) return;
                                dispatch(
                                  removeAsyncSingleIndirectGroupMembership({
                                    userToken,
                                    _data: {
                                      tenantId: selectedTenant.tenantId,
                                      deleteList: [
                                        {
                                          id: item.id,
                                        },
                                      ],
                                    },
                                  })
                                ).then(() => {
                                  dispatch(
                                    getAsyncIndirectMemberships({
                                      _data: paginationData,
                                      userToken,
                                    })
                                  );
                                });
                              });
                            }}
                          >
                            <span className="icon-trash"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: paginationData.page,
          size: paginationData.size,
          totalPages: indirectMembershipData.totalPages,
        }}
        onPageNumberChange={(page) => {
          setPaginationData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageNumbersClick={(page) => {
          setPaginationData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            size,
          }));
        }}
        search={paginationData.search}
      />
    </div>
  );
};

export default IndirectMembership;
