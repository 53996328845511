// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  I_AllocatedProcessData,
  I_RecevedDataFromAllocatedProcessStartingFormRequest,
} from "../../Models/interfaces";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import {
  I_GettingAllocatedProcessesRequestData,
  I_SendingDataForStartNewAllocatedProcessInstance,
} from "../../Models/sendingDataSchema";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { getAllAllocatedProcessesService } from "../../Services/AllocatedProcesses/get/getAllAllocatedProcessesService";
import { getSingleAllocatedProcessStartingFormService } from "../../Services/AllocatedProcesses/get/getSingleAllocatedProcessStartingFormService";
import { startNewSingleAllocatedProcessInstanceService } from "../../Services/AllocatedProcesses/post/startNewSingleAllocatedProcessInstanceService";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Redux

const initialState: {
  allocatedProcessData: {
    data: I_AllocatedProcessData;

    // Added By Developer
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    // Added By Developer
  };
} & {
  startingFormData: {
    data: I_RecevedDataFromAllocatedProcessStartingFormRequest;

    // Added By Developer
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    // Added By Developer
  };
} & {
  allocatedProcessStartingNewInstanceState: {
    data: {
      trackingCode: string;
    };
    // Added By Developer
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    // Added By Developer
  };
} = {
  allocatedProcessData: {
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 10,
      totalElements: 1,
      totalPages: 1,
    },
    isPending: true,
    isDone: true,
    isError: false,
  },
  startingFormData: {
    data: {
      formType: {
        title: "",
        value: "",
      },
      id: 1,
      key: "",
      processKey: "",
      title: "",
      url: "",
    },
    isPending: true,
    isDone: false,
    isError: false,
  },
  allocatedProcessStartingNewInstanceState: {
    data: {
      trackingCode: "",
    },
    isPending: false,
    isDone: false,
    isError: false,
  },
};

export const getAsyncAllocatedProcesses = createAsyncThunk(
  "allocatedProcesses",
  async (_data: {
    userToken: string;
    _data: I_GettingAllocatedProcessesRequestData;
  }) => {
    try {
      const { data } = await getAllAllocatedProcessesService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncSingleAllocatedProcessStartingFormByProcessKey =
  createAsyncThunk(
    "allocatedProcesses/getSingleAllocatedProcessStartingForm",
    async (_data: {
      userToken: string;
      _data: {
        processKey: string | number;
      };
    }) => {
      try {
        const { data } = await getSingleAllocatedProcessStartingFormService(
          _data
        );
        return data;
      } catch (err: any) {
        console.log(err);
        if (err?.response.data.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }
          throw err;
        }
        throw err;
      }
    }
  );

export const startAsyncNewAllocatedProcessInstance = createAsyncThunk(
  "allocatedProcesses/startNewAllocatedInstance",
  async (_data: {
    userToken: string;
    _data: {
      processKey: string | number;
      _data: I_SendingDataForStartNewAllocatedProcessInstance;
      onDone: (tc: string) => void;
    };
  }) => {
    try {
      const { data } = await startNewSingleAllocatedProcessInstanceService(
        _data
      );
      if (typeof _data._data.onDone === "function") {
        _data._data.onDone(data.result.trackingCode);
      }
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const allocatedProcessesSlice = createSlice({
  name: "allocatedProcesses",
  initialState,
  reducers: {
    resetAllocatedProcessesState: (state) => {
      state.allocatedProcessData = resetReferenceOfObject(
        initialState.allocatedProcessData
      );
      state.allocatedProcessData.isPending = true;
    },
    resetRecevedAllocatedProcessStartingFormData: (state) => {
      state.startingFormData = resetReferenceOfObject(
        initialState.startingFormData
      );
      state.startingFormData.isPending = true;
    },
    resetStartingNewAllocatedProcessInstance: (state) => {
      state.allocatedProcessStartingNewInstanceState = resetReferenceOfObject(
        initialState.allocatedProcessStartingNewInstanceState
      );
      state.allocatedProcessStartingNewInstanceState.isPending = false;
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    builder.addCase(getAsyncAllocatedProcesses.pending, (state, action) => {
      state.allocatedProcessData.isPending = true;
      state.allocatedProcessData.isError = false;
      state.allocatedProcessData.isDone = false;
    });
    builder.addCase(getAsyncAllocatedProcesses.fulfilled, (state, action) => {
      state.allocatedProcessData.isPending = false;
      state.allocatedProcessData.isError = false;
      state.allocatedProcessData.isDone = true;

      if (!Boolean(action?.payload?.result)) {
        state.allocatedProcessData.isError = true;
        return;
      }
      for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
      state.allocatedProcessData.data = action.payload!.result;
    });
    builder.addCase(getAsyncAllocatedProcesses.rejected, (state, action) => {
      state.allocatedProcessData.isPending = false;
      state.allocatedProcessData.isError = true;
      state.allocatedProcessData.isDone = false;
    });
    //
    //
    //
    //
    //
    builder.addCase(
      getAsyncSingleAllocatedProcessStartingFormByProcessKey.pending,
      (state, action) => {
        state.startingFormData.isPending = true;
        state.startingFormData.isError = false;
        state.startingFormData.isDone = false;
      }
    );
    builder.addCase(
      getAsyncSingleAllocatedProcessStartingFormByProcessKey.fulfilled,
      (state, action) => {
        state.startingFormData.isPending = false;
        state.startingFormData.isError = false;
        state.startingFormData.isDone = true;

        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }

        state.startingFormData.data = action.payload.result;
      }
    );
    builder.addCase(
      getAsyncSingleAllocatedProcessStartingFormByProcessKey.rejected,
      (state, action) => {
        state.startingFormData.isPending = false;
        state.startingFormData.isError = true;
        state.startingFormData.isDone = false;
      }
    );
    //
    //
    //
    //
    //
    builder.addCase(
      startAsyncNewAllocatedProcessInstance.pending,
      (state, action) => {
        state.allocatedProcessStartingNewInstanceState.isPending = true;
        state.allocatedProcessStartingNewInstanceState.isError = false;
        state.allocatedProcessStartingNewInstanceState.isDone = false;
      }
    );
    builder.addCase(
      startAsyncNewAllocatedProcessInstance.fulfilled,
      (state, action) => {
        state.allocatedProcessStartingNewInstanceState.isPending = false;
        state.allocatedProcessStartingNewInstanceState.isError = false;
        state.allocatedProcessStartingNewInstanceState.isDone = true;

        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }

        state.allocatedProcessStartingNewInstanceState.data =
          action.payload!.result;
      }
    );
    builder.addCase(
      startAsyncNewAllocatedProcessInstance.rejected,
      (state, action) => {
        state.allocatedProcessStartingNewInstanceState.isPending = false;
        state.allocatedProcessStartingNewInstanceState.isError = true;
        state.allocatedProcessStartingNewInstanceState.isDone = false;
      }
    );
  },
});
