// React
import React, { useCallback, useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./UserGroupsGrantingModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  editAsyncUserInformation,
  getAsyncSingleUserAccessibleDataWithoutPending,
  singleAccessibleUserSlice,
} from "../../../../../Features/SingleAccessibleUserSlice/singleAccessibleUserSlice";
// Redux

// Images
import { separator } from "../../../../../Images/images";
// Images

// Contexts
import { IsNightModeOnContext } from "../../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
// Utils

type UserGroupsGrantingModalProps = {
  addGroups: { title: string; value: string }[];
  removeGroups: { title: string; value: string }[];

  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;

  setGroupsForModify: React.Dispatch<
    React.SetStateAction<{
      addGroups: { title: string; value: string }[];
      removeGroups: { title: string; value: string }[];
    }>
  >;

  userName: string;
};

const UserGroupsGrantingModal: React.FunctionComponent<
  UserGroupsGrantingModalProps
> = ({
  addGroups,
  removeGroups,
  setIsModalInScreen,
  setGroupsForModify,
  userName,
}) => {
  const dispatch = useAppDispatch();

  const { isUserChangeDataDone: isDone, isEditingGroupsPending: isPending } =
    useAppSelector((state) => state.singleAccessibleUser);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const closeModal = useCallback(() => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  }, [setIsModalInScreen]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "unset";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    if (!isDone) return;

    closeModal();

    return () => {};
  }, [isDone, closeModal, dispatch]);

  useEffect(() => {
    dispatch(singleAccessibleUserSlice.actions.resetEditingUserData());
  });

  return (
    <div className={`${styles.userGroupsModalContainer} w-100 `}>
      <div className={`${styles.bg}`} ref={bgRef} onClick={closeModal}></div>
      <div
        className={`${styles.content} d-flex flex-column align-items-center ${
          isNightModeOn ? "nightBg2" : ""
        }`}
        ref={contentRef}
      >
        <div
          className={`${styles.groupShowContainer} w-100 d-flex flex-column align-items-center`}
        >
          <p className="w-100 d-flex flex-row align-items-center justify-content-end">
            گروه هایی که افزوده میشود
          </p>
          <div className={`${styles.groupsContainer}`}>
            {addGroups.map((item) => (
              <span
                key={item.value}
                onClick={() => {
                  Swal.fire({
                    icon: "question",
                    title: "این گروه از لیست انتخابی حذف شود ؟",
                    text: "آیا مطمعن هستید ؟",
                    showCancelButton: true,
                    cancelButtonText: "لغو",
                    confirmButtonText: "تایید",
                    customClass: isNightModeOn ? "swalNight" : "",
                  }).then(({ isConfirmed }) => {
                    if (!isConfirmed) return;

                    setGroupsForModify((prevState) => ({
                      ...prevState,
                      addGroups: prevState.addGroups.filter(
                        (_item) => _item.value !== item.value
                      ),
                    }));
                  });
                }}
              >
                {item.title}
              </span>
            ))}
          </div>
        </div>

        <img src={separator} alt="separator" className="w-100 my-5" />
        <div
          className={`${styles.groupShowContainer} w-100 d-flex flex-column align-items-center`}
        >
          <p className="w-100 d-flex flex-row align-items-center justify-content-end">
            گروه هایی که حذف میشود
          </p>
          <div className={`${styles.groupsContainer}`}>
            {removeGroups.map((item) => (
              <span
                key={item.value}
                onClick={() => {
                  Swal.fire({
                    icon: "question",
                    title: "این گروه از لیست انتخابی حذف شود ؟",
                    text: "آیا مطمعن هستید ؟",
                    showCancelButton: true,
                    cancelButtonText: "لغو",
                    confirmButtonText: "تایید",
                    customClass: isNightModeOn ? "swalNight" : "",
                  }).then(({ isConfirmed }) => {
                    if (!isConfirmed) return;
                    setGroupsForModify((prevState) => ({
                      ...prevState,
                      removeGroups: prevState.removeGroups.filter(
                        (_item) => _item.value !== item.value
                      ),
                    }));
                  });
                }}
              >
                {item.title}
              </span>
            ))}
          </div>
        </div>

        <button
          className="operationEvent submitBtn align-self-start mt-4"
          onClick={() => {
            dispatch(
              editAsyncUserInformation({
                userToken,
                userName: String(userName),
                newData: {
                  groupMembershipToDelete: removeGroups.map((item) => ({
                    groupId: item.value,
                  })),
                  groupMembershipToAdd: addGroups.map((item) => ({
                    groupId: item.value,
                  })),
                },
              })
            )
              .then(() => {
                dispatch(
                  getAsyncSingleUserAccessibleDataWithoutPending({
                    userName: String(userName),
                    userToken,
                  })
                );
              })
              .then(() => {
                setGroupsForModify({
                  addGroups: [],
                  removeGroups: [],
                });
              });
            return;
          }}
        >
          {isPending ? <Spinner /> : <>تایید</>}
        </button>
      </div>
    </div>
  );
};

export default UserGroupsGrantingModal;
