import { AxiosResponse } from "axios";
import { I_NewGroupSendingData } from "../../../Models/sendingDataSchema";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const addNewGroupService = async (_data: {
  _data: I_NewGroupSendingData;
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      value: string;
      title: string;
      categoryType: {
        value: string;
        title: string;
      };
    };
  }>
> => {
  return _axios.post(`/groups/add`, _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
