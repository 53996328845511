import { AxiosResponse } from "axios";
import { I_SendingServerManagementData } from "../../../Models/sendingDataSchema";
import { I_Message, I_ServerManagementData } from "../../../Models/interfaces";
import { _axios } from "../../Base_Axios_Config/_axios";

export const getAllServersManagementService = async (_data: {
  userToken: string;
  _data: I_SendingServerManagementData;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_ServerManagementData;
  }>
> => {
  return _axios.get(
    `/data-servers?page=${_data._data.page}&size=${_data._data.size}&tenant=${_data._data.tenant}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
