// React
import React from "react";
// React

// CSS
import styles from "./Footer.module.css";
// CSS
const Footer = () => {
  return (
    <div className={`${styles.footerContainer}`}>
      <a href="https://samfona.ir">
        سیستم مدیریت هوشمند فرایندهای کسب و کار سمفونا
      </a>
    </div>
  );
};

export default Footer;
