// React
import React from "react";
// React

// Map
import { Circle, Marker, Popup, useMapEvents } from "react-leaflet";
// Map

// Constants
import {
  defaultLat,
  defaultLon,
  themeColor,
} from "../../../../../Constants/constants";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
// Constants

type LocationMarkerProps = {
  setMapDetails: React.Dispatch<
    React.SetStateAction<{
      circleData: {
        isActive: boolean;
        lat: string;
        lon: string;
        circleRadius: number;
      };
      mapData: {
        lat: string;
        lon: string;
        markerText: string;
      };
    }>
  >;
  mapDetails: {
    circleData: {
      isActive: boolean;
      lat: string;
      lon: string;
      circleRadius: number;
    };
    mapData: {
      lat: string;
      lon: string;
      markerText: string;
    };
  };
};

export const LocationMarker: React.FunctionComponent<LocationMarkerProps> = ({
  setMapDetails,
  mapDetails,
}) => {
  const map = useMapEvents({
    click(e) {
      console.log(e);
      setMapDetails((prevState) => ({
        ...prevState,
        mapData: {
          ...prevState.mapData,
          lat: String(e.latlng.lat),
          lon: String(e.latlng.lng),
        },
        circleData: {
          ...prevState.circleData,
          lat: String(e.latlng.lat),
          lon: String(e.latlng.lng),
        },
      }));
      map.locate();
    },
  });

  return (
    <>
      {mapDetails.circleData.isActive ? (
        <Circle
          center={[
            Number(mapDetails.mapData.lat),
            Number(mapDetails.mapData.lon),
          ]}
          radius={mapDetails.circleData.circleRadius}
          color={themeColor}
        ></Circle>
      ) : null}

      <Marker
        position={[
          Number(mapDetails.mapData.lat) || defaultLat,
          Number(mapDetails.mapData.lon) || defaultLon,
        ]}
        icon={
          new Icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          })
        }
      >
        <Popup>{mapDetails.mapData.markerText}</Popup>
      </Marker>
    </>
  );
};
