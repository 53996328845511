import { AxiosResponse } from "axios";
import { I_UsersListResponse } from "../../../Models/interfaces";
import { _axios } from "../../Base_Axios_Config/_axios";

export const getAccessableUsersService = (serchParams: {
  page: number;
  size: number;
  editing: boolean;
  search: string;
  group: string;
  tenant: string;
  usernames: string[];
  userToken: string;
}): Promise<AxiosResponse<I_UsersListResponse>> => {
  const { editing, group, page, search, size, tenant, usernames, userToken } =
    serchParams;

  return _axios.get(
    `/users?page=${page}&size=${size}&editing=${editing}&search=${search}&group=${group}&tenant=${tenant}${
      Array.isArray(usernames)
        ? usernames
            .map((item) => `&usernames=${item}`)
            .toString()
            .replaceAll(",", "")
        : "usernames=&"
    }`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
