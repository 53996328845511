import { AxiosResponse } from "axios";
import { I_Message } from "../../../Models/interfaces";
import { _axios } from "../../Base_Axios_Config/_axios";

export const editServerDataService = async (_data: {
  userToken: string;
  serverId: string;
  _data: {
    serverAddress: string;
    setAsCentralServer: boolean;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(`/data-servers/${_data.serverId}/edit`, _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
