// React
import React, { useEffect, useState } from "react";
// React

// CSS
import styles from "./ProfileIdentitiesTab.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncAllIdentityAttributeWithOptions,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
import {
  editAsyncUserData,
  getAsyncUserData,
} from "../../../Features/UserInformationSlice/userInformationSlice";
// Redux

// Utils
import CustomInput2 from "../../../Utils/CustomInput2/CustomInput2";
import { Spinner } from "reactstrap";
// Utils

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Models
import { I_UserData } from "../../../Models/interfaces";
import { CustomFormData } from "../../../Models/types";
// Models

// Themes
import { themeColor } from "../../../Constants/constants";
import { BsChevronLeft } from "react-icons/bs";
// Themes

type ProfileIdentitiesTabProps = {
  userDataForOverWrite: I_UserData;
};

const ProfileIdentitiesTab: React.FunctionComponent<
  ProfileIdentitiesTabProps
> = ({ userDataForOverWrite }) => {
  const dispatch = useAppDispatch();

  const [additionalFormData, setAdditionalFormData] = useState<CustomFormData>(
    {}
  );

  const userInformation = useAppSelector((state) => state.userInformation);
  const userIdentities = useAppSelector(
    (state) => state.userIdentities.userIdentitiesWithOptions
  );
  const { extraIdentityInformation: userProfileExtraData } = useAppSelector(
    (state) => state.userInformation
  );
  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { currLanguage } = useAppSelector((state) => state.currLanguage);

  const { isPending } = useAppSelector(
    (state) => state.userIdentities.userIdentitiesWithOptions
  );

  useEffect(() => {
    dispatch(
      getAsyncAllIdentityAttributeWithOptions({
        userToken,
        tenantId: selectedTenant.tenantId,
      })
    );
  }, [dispatch, userToken, selectedTenant]);

  useEffect(() => {
    const identityKeys = userIdentities.data.map((item) => item.key);
    const resultObject: CustomFormData = {};

    for (let i = 0; i <= identityKeys.length - 1; i++) {
      const selcetedItem = userIdentities.data.find(
        (item) => item.key === identityKeys[i]
      );
      console.log(userProfileExtraData[identityKeys[i]]);
      resultObject[String(identityKeys[i])] = {
        type: String(selcetedItem?.type?.value),
        persianName: String(selcetedItem?.title),
        value: userProfileExtraData[identityKeys[i]]?.value || "",
        objectName: identityKeys[i],
        otherOptions: selcetedItem?.identityOptions
          ? selcetedItem?.identityOptions?.map((item) => ({
              id: String(item?.id),
              title: String(item?.title),
            }))
          : [],
        helperData: new Blob(),
        isTouched: false,
        ref: React.createRef(),
      };
    }

    setAdditionalFormData(resultObject);
  }, [userIdentities, userProfileExtraData]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetUserIdentitiesWithOptions());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.profileIdentitiesContainer} w-100 d-flex flex-column align-items-center`}
    >
      {isPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          <div
            className="w-100 d-flex flex-row-reverse align-items-center justify-content-between flex-wrap"
            style={{
              direction: currLanguage === "fa" ? "ltr" : "rtl",
            }}
          >
            {(() => {
              const keys = Object.keys(additionalFormData);

              return keys.map((item) => {
                const selectedItem = additionalFormData[item];
                if (selectedItem.type === "IMAGE") {
                  return (
                    <React.Fragment key={selectedItem.objectName}>
                      <CustomInput2
                        onChange={(e) => {
                          if (!e.target.files) return;
                          const fileReader = new FileReader();

                          setAdditionalFormData((prevState) => ({
                            ...prevState,
                            [item]: {
                              ...prevState[item],
                              helperData: e.target.files
                                ? e.target.files[0]
                                : prevState[item].helperData,
                              isTouched: true,
                            },
                          }));
                          fileReader.onload = () => {
                            setAdditionalFormData((prevState) => ({
                              ...prevState,
                              [item]: {
                                ...prevState[item],
                                value: fileReader.result,
                                isTouched: true,
                              },
                            }));
                          };
                          fileReader.readAsDataURL(e.target?.files[0]);
                        }}
                        plaeHolder={selectedItem.persianName}
                        title={selectedItem.persianName}
                        type="file"
                        containerClassName="mt-2 "
                        imageRenderingOptions={{
                          src: selectedItem.isTouched
                            ? URL.createObjectURL(selectedItem.helperData)
                            : `${mainUrl}${selectedItem.value}`,
                          buttonForChangeOnClick: () => {
                            if (!selectedItem.ref.current) return;
                            selectedItem.ref.current.click();
                          },
                          buttonInnerText: `${selectedItem.persianName} تعویض عکس `,
                        }}
                        fileInputReference={selectedItem.ref}
                        containerStyle={{
                          direction: currLanguage !== "fa" ? "rtl" : "ltr",
                        }}
                      />
                    </React.Fragment>
                  );
                } else if (
                  selectedItem.type === "TEXT" ||
                  selectedItem.type === "DOUBLE" ||
                  selectedItem.type === "INTEGER"
                ) {
                  return (
                    <React.Fragment key={selectedItem.objectName}>
                      <CustomInput2
                        onChange={(e) => {
                          setAdditionalFormData((prevState) => ({
                            ...prevState,
                            [item]: {
                              ...prevState[item],
                              isTouched: true,
                              value: e.target.value,
                            },
                          }));
                        }}
                        defaultValue={selectedItem.value}
                        plaeHolder={selectedItem.persianName}
                        title={selectedItem.persianName}
                        type="text"
                        containerClassName="mt-2"
                      />
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment
                    key={selectedItem.objectName}
                  ></React.Fragment>
                );
              });
            })()}
          </div>
          <button
            className=" mt-5 py-2 btn btn-primary align-self-start"
            style={{
              background: themeColor,
            }}
            onClick={() => {
              console.log(additionalFormData);
              dispatch(
                editAsyncUserData({
                  userToken,
                  _data: {
                    firstname: userDataForOverWrite.firstname,
                    lastname: userDataForOverWrite.lastname,
                    email: userDataForOverWrite.email,
                    extraIdentityInformation: (() => {
                      const keys = Object.keys(additionalFormData);
                      let result: { [key: string]: any } = {};
                      for (let i = 0; i < keys.length; i++) {
                        if (additionalFormData[keys[i]].isTouched) {
                          result[additionalFormData[keys[i]].objectName] =
                            additionalFormData[keys[i]].value;
                        }
                      }
                      return result;
                    })(),
                  },
                })
              ).then(() => {
                dispatch(
                  getAsyncUserData({
                    userToken,
                  })
                );
              });
            }}
          >
            {userInformation.isUserInformationChangePending ? (
              <>
                <Spinner />
              </>
            ) : (
              <>
                <BsChevronLeft
                  style={{
                    marginRight: "8px",
                  }}
                />
                <span>تایید </span>
              </>
            )}
          </button>
        </>
      )}
    </div>
  );
};

export default ProfileIdentitiesTab;
