// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./ServerManagement.module.css";
// CSS

// Modules
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
import { useNavigate, useSearchParams } from "react-router-dom";
// Modules

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { ExecutingPermissionsContext } from "../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import { I_SendingServerManagementData } from "../../Models/sendingDataSchema";
import {
  getAsyncNewTokenForDataServer,
  getAsyncServerData,
  removeAsyncServerDataItem,
  serverManagementSlice,
} from "../../Features/ServerManagementSlice/ServerManagementSlice";
// Redux

// Utils
import { CSVLink } from "react-csv";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { Spinner } from "reactstrap";
import Pagination from "../../Utils/Pagination/Pagination";
import Swal from "sweetalert2";
// Utils

// Icons
import { BsDashLg } from "react-icons/bs";
import { MdDone } from "react-icons/md";
import { FiRefreshCcw } from "react-icons/fi";
// Icons

// Components
import EditServerModal from "./EditServerModal/EditServerModal";
import { copyToClipboard } from "../../Utils/copyToClipboard";
// Components

// Themes
import { themeColor } from "../../Constants/constants";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
// Themes

const ServerManagement = () => {
  const allGroups = useContext(UserGroupsContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const serverData = useAppSelector((state) => state.servers);

  const [selectedServerToEditData, setSelectedServerToEditData] = useState<{
    serverName: string;
    isCentral: boolean;
    serverId: string;
  }>({
    serverName: "",
    isCentral: false,
    serverId: "",
  });
  const [isEditModalInScreen, setIsEditModalInScreen] =
    useState<boolean>(false);

  const currLang = useAppSelector((state) => state.currLanguage.language);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [searchParam, setSearchParam] = useSearchParams();

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const [paginationData, setPaginationData] =
    useState<I_SendingServerManagementData>({
      tenant: selectedTenant.tenantId,
      page: Number(searchParam.get("page")) || 1,
      size: Number(searchParam.get("size")) || 10,
    });

  const csvReport = {
    headers: [
      {
        label: "آدرس سرور",
        key: "server",
      },
    ],
    data: serverData.content.map((item) => ({ server: item.serverAddress })),
    filename: "servers.csv",
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (
      permitionCheckerWithoutToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Settings_Editor"],
        isFullRenderd
      ) === false
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncServerData({
          userToken,
          _data: paginationData,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    dispatch,
    userToken,
    paginationData,
    allGroups,
    isFullRenderd,
    isMounted,
  ]);

  useEffect(() => {
    return () => {
      dispatch(serverManagementSlice.actions.resetServerData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setServersDataForPages({
        pageNumber: paginationData.page,
        size: paginationData.size,
      })
    );
  }, [dispatch, paginationData]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(paginationData.page) || 1 + "",
        size: String(paginationData.size) || 10 + "",
      },
      {
        replace: true,
      }
    );
  }, [paginationData, setSearchParam, searchParam]);

  return (
    <div
      className={`${styles.serverManagementContainer} ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      {isEditModalInScreen ? (
        <EditServerModal
          setIsModalInScreen={setIsEditModalInScreen}
          isCentral={selectedServerToEditData.isCentral}
          serverName={selectedServerToEditData.serverName}
          serverId={selectedServerToEditData.serverId}
          afterOrationCallback={() => {
            dispatch(
              getAsyncServerData({
                userToken,
                _data: paginationData,
              })
            );
          }}
        />
      ) : null}
      <div className="w-100 d-flex flex-row-reverse flex-wrap align-items-center justify-content-between">
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          لیست پایگاه داده
        </p>
        <div className="d-flex flex-col align-items-center">
          <p
            style={{
              alignSelf: "flex-start",
              marginBottom: "1rem",
              padding: "6px 10px",
              fontSize: "14px",
              borderRadius: "8px",
              background: themeColor,
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/servers/new-server");
            }}
            className="d-flex align-items-center"
          >
            افزودن سرور
            <span className="icon-Plus" style={{ marginLeft: "6px" }}></span>
          </p>

          <CSVLink
            {...csvReport}
            style={{
              textDecoration: "none",
              color: "#fff",
              background: "#3AC150",
              marginBottom: "1rem",
              padding: "6px 10px",
              marginLeft: "8px",
              borderRadius: "8px",
            }}
          >
            csv خروجی
          </CSVLink>
        </div>
      </div>
      {permitionCheckerWithoutToast(
        allGroups,
        permitions.canSeeTenantTextBox,
        isFullRenderd
      ) && (
        <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between mt-4">
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={() => {
              return false;
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            disabled
            placeHolder="نام سازمان"
          />
        </div>
      )}

      {serverData.isPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {serverData.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.isCentralContainer}`}>سرور مرکزی</p>
                    <p className={`${styles.isDataServerContainer}`}>
                      سرور دیتا
                    </p>
                    <p className={`${styles.tokenContainer}`}>توکن</p>
                    <p className={`${styles.serverAddress}`}>آدرس</p>
                    <p className={`${styles.id}`}>شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {serverData.content.map(
                      ({
                        credential,
                        id,
                        serverAddress,
                        useCentralDataServer,
                        usingAsCentralDataServer,
                      }) => (
                        <div
                          key={id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p className={`${styles.operationTab}`}>
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Settings_Editor",
                              ],
                              isFullRenderd
                            ) ? (
                              <button
                                className="operationEvent deleteBtn"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.delete}
                                onClick={() => {
                                  Swal.fire({
                                    icon: "question",
                                    title: "آیا از حذف سرور اطمینان دارید ؟",
                                    text: "این عملیات بدون بازگشت میباشد",
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "پاک کن",
                                    showCancelButton: true,
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      removeAsyncServerDataItem({
                                        serverId: String(id),
                                        userToken,
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncServerData({
                                          userToken,
                                          _data: paginationData,
                                        })
                                      );
                                    });
                                  });
                                }}
                              >
                                <span className="icon-trash"></span>
                              </button>
                            ) : null}

                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Settings_Editor",
                              ],
                              isFullRenderd
                            ) ? (
                              <button
                                className="operationEvent taskBtn"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.refreshToken}
                                onClick={() => {
                                  Swal.fire({
                                    icon: "question",
                                    title:
                                      "آیا از حذف توکن قبلی اطمینان دارید ؟",
                                    text: "این عملیات بدون بازگشت میباشد",
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "پاک کن",
                                    showCancelButton: true,
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;

                                    if (
                                      !permitionCheckerWithoutToast(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Settings_Editor",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                    customizedToast(
                                      "درخواست توکن جدید ارسال شد",
                                      "info"
                                    );
                                    dispatch(
                                      getAsyncNewTokenForDataServer({
                                        userToken,
                                        dataServerId: String(id),
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncServerData({
                                          userToken,
                                          _data: paginationData,
                                        })
                                      );
                                    });
                                  });
                                }}
                              >
                                <FiRefreshCcw />
                              </button>
                            ) : null}

                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Settings_Editor",
                              ],
                              isFullRenderd
                            ) ? (
                              <button
                                className="operationEvent editBtn"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.edit}
                                onClick={() => {
                                  setSelectedServerToEditData({
                                    isCentral: useCentralDataServer,
                                    serverName: serverAddress,
                                    serverId: String(id),
                                  });
                                  setIsEditModalInScreen(true);
                                  // Swal.fire({
                                  //   icon: "question",
                                  //   title:
                                  //     "آیا از تعویض آدرس سرور اطمینان دارید ؟",
                                  //   text: "این عملیات بدون بازگشت میباشد",
                                  //   cancelButtonText: "لغو",
                                  //   confirmButtonText: "ادامه",
                                  //   showCancelButton: true,
                                  //   customClass: isNightOn ? "swalNight" : "",
                                  // }).then(({ isConfirmed }) => {
                                  //   if (!isConfirmed) return;

                                  // });
                                }}
                              >
                                <span className="icon-edit"></span>
                              </button>
                            ) : null}
                          </p>
                          <p className={`${styles.isCentralContainer}`}>
                            {useCentralDataServer ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.isDataServerContainer}`}>
                            {usingAsCentralDataServer ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p
                            className={`${styles.tokenContainer} `}
                            onClick={() => {
                              copyToClipboard(credential);
                            }}
                            data-tooltip-content={
                              credential.length >= 18
                                ? credential.substring(0, 17) + "..."
                                : credential
                            }
                            data-tooltip-id="my-tooltip"
                          >
                            <button className="operationEvent submitBtn">
                              کپی
                            </button>
                          </p>
                          <a
                            className={`${styles.serverAddress}`}
                            href={
                              serverAddress?.startsWith("https://")
                                ? serverAddress
                                : `https://${serverAddress}`
                            }
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              serverAddress?.length >= 18 ? serverAddress : ""
                            }
                            target="_blank"
                            rel="noreferrer"
                            style={{}}
                          >
                            {serverAddress?.length >= 18
                              ? serverAddress?.substring(0, 17) + "..."
                              : serverAddress}
                          </a>
                          <p className={`${styles.id}`}>{id}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
                >
                  {serverData.content.map(
                    ({
                      credential,
                      id,
                      serverAddress,
                      useCentralDataServer,
                      usingAsCentralDataServer,
                    }) => (
                      <div key={id} className="w-100 mt-5">
                        <div
                          className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>{id}</p>
                          <p>شمارنده</p>
                        </div>
                        <div
                          className={`${styles.tableRow} ${
                            isNightOn ? "nightBg2" : ""
                          } w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            <span>سرور مرکزی </span>
                            {useCentralDataServer ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>

                          <p>
                            <span>توکن</span>
                            <span
                              onClick={() => {
                                copyToClipboard(credential);
                              }}
                            >
                              کپی
                            </span>
                          </p>

                          <p>
                            <span>آدرس</span>
                            <span
                              onClick={() => {
                                if (serverAddress.startsWith("https://")) {
                                  window.location.href = serverAddress;
                                } else {
                                  window.location.href = `https://${serverAddress}`;
                                }
                              }}
                            >
                              {serverAddress}
                            </span>
                          </p>
                          <p>
                            <span>عملیات</span>
                            <span className="d-flex flex-row align-items-center">
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Settings_Editor",
                                ],
                                isFullRenderd
                              ) ? (
                                <button
                                  className="operationEvent deleteBtn"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.delete}
                                  onClick={() => {
                                    Swal.fire({
                                      icon: "question",
                                      title: "آیا از حذف سرور اطمینان دارید ؟",
                                      text: "این عملیات بدون بازگشت میباشد",
                                      cancelButtonText: "لغو",
                                      confirmButtonText: "پاک کن",
                                      showCancelButton: true,
                                      customClass: isNightOn ? "swalNight" : "",
                                    }).then(({ isConfirmed }) => {
                                      if (!isConfirmed) return;
                                      dispatch(
                                        removeAsyncServerDataItem({
                                          serverId: String(id),
                                          userToken,
                                        })
                                      ).then(() => {
                                        dispatch(
                                          getAsyncServerData({
                                            userToken,
                                            _data: paginationData,
                                          })
                                        );
                                      });
                                    });
                                  }}
                                >
                                  <span className="icon-trash"></span>
                                </button>
                              ) : null}

                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Settings_Editor",
                                ],
                                isFullRenderd
                              ) ? (
                                <button
                                  className="operationEvent taskBtn"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.refreshToken}
                                  onClick={() => {
                                    Swal.fire({
                                      icon: "question",
                                      title:
                                        "آیا از حذف توکن قبلی اطمینان دارید ؟",
                                      text: "این عملیات بدون بازگشت میباشد",
                                      cancelButtonText: "لغو",
                                      confirmButtonText: "پاک کن",
                                      showCancelButton: true,
                                      customClass: isNightOn ? "swalNight" : "",
                                    }).then(({ isConfirmed }) => {
                                      if (!isConfirmed) return;

                                      if (
                                        !permitionCheckerWithoutToast(
                                          allGroups,
                                          [
                                            "Tenant_Full_Manager",
                                            "Tenant_Basic_Manager",
                                            "Settings_Editor",
                                          ],
                                          isFullRenderd
                                        )
                                      )
                                        return;
                                      customizedToast(
                                        "درخواست توکن جدید ارسال شد",
                                        "info"
                                      );
                                      dispatch(
                                        getAsyncNewTokenForDataServer({
                                          userToken,
                                          dataServerId: String(id),
                                        })
                                      ).then(() => {
                                        dispatch(
                                          getAsyncServerData({
                                            userToken,
                                            _data: paginationData,
                                          })
                                        );
                                      });
                                    });
                                  }}
                                >
                                  <FiRefreshCcw />
                                </button>
                              ) : null}

                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Settings_Editor",
                                ],
                                isFullRenderd
                              ) ? (
                                <button
                                  className="operationEvent editBtn"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.edit}
                                  onClick={() => {
                                    Swal.fire({
                                      icon: "question",
                                      title:
                                        "آیا از تعویض آدرس سرور اطمینان دارید ؟",
                                      text: "این عملیات بدون بازگشت میباشد",
                                      cancelButtonText: "لغو",
                                      confirmButtonText: "ادامه",
                                      showCancelButton: true,
                                      customClass: isNightOn ? "swalNight" : "",
                                    }).then(({ isConfirmed }) => {
                                      if (!isConfirmed) return;
                                      setSelectedServerToEditData({
                                        isCentral: useCentralDataServer,
                                        serverName: serverAddress,
                                        serverId: String(id),
                                      });
                                      setIsEditModalInScreen(true);
                                    });
                                  }}
                                >
                                  <span className="icon-edit"></span>
                                </button>
                              ) : null}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: paginationData.page,
          size: paginationData.size,
          totalPages: serverData.totalPages,
        }}
        onPageNumberChange={(page) => {
          setPaginationData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setPaginationData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({ ...prevState, size }));
        }}
        search={""}
      />
    </div>
  );
};

export default ServerManagement;
