import React, { useEffect, useState } from "react";

const Spinner: React.FC = () => (
  <div
    style={{
      display: "block",
      position: "fixed",
      right: 15,
      top: 15,
      zIndex: 1031,
    }}
  >
    <div
      style={{
        animation: "400ms linear infinite spinner",
        borderBottom: "2px solid transparent",
        borderLeft: "2px solid #29d",
        borderRadius: "50%",
        borderRight: "2px solid transparent",
        borderTop: "2px solid #29d",
        boxSizing: "border-box",
        height: 18,
        width: 18,
      }}
    />
  </div>
);
const Container: React.FC<any> = ({
  animationDuration,
  children,
  isFinished,
}: any) => (
  <div
    style={{
      opacity: isFinished ? 0 : 1,
      pointerEvents: "none",
      transition: `opacity ${animationDuration}ms linear`,
    }}
  >
    {children}
  </div>
);
const Bar: React.FC<any> = ({ animationDuration }) => {
  const [currrPercentage, setCurrPercentage] = useState<number>(0);

  useEffect(() => {
    if (currrPercentage >= 98) return;
    const timeOut = setTimeout(() => {
      setCurrPercentage((prevState) => (prevState += 1));
    }, 10);

    return () => {
      clearTimeout(timeOut);
    };
  }, [currrPercentage]);

  return (
    <div
      style={{
        background: "#f5f5dc",
        height: "4px",
        left: 0,
        position: "fixed",
        top: 0,
        transition: `width ${animationDuration}ms linear`,
        width: `${currrPercentage}%`,
        zIndex: 30,
      }}
    >
      <div
        style={{
          boxShadow: "0 0 10px #29d, 0 0 5px #29d",
          display: "block",
          height: "100%",
          opacity: 1,
          position: "absolute",
          right: 0,
          transform: "rotate(3deg) translate(0px, -4px)",
          width: 100,
        }}
      />
    </div>
  );
};

const TopProgressBar: React.FC<any> = ({
  isFinished,
  progress,
  animationDuration,
}) => {
  return (
    <Container animationDuration={1} isFinished={false}>
      <Bar animationDuration={1} progress={progress} />
      <Spinner />
    </Container>
  );
};

export default TopProgressBar;
