// React
import React, { useContext, useEffect } from "react";
// React

// CSS
import styles from "./SingleCallOfRegistrationDetails.module.css";
// CSS

// Modules
import { useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncAllDetailsForSingleCallOfRegistration,
  callOfRegistrationSlice,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { permissionChecker } from "../../../Modules/permissionChecker";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import KeyValuePairShow from "../../../Utils/KeyValuePairShow/KeyValuePairShow";
import { Spinner } from "reactstrap";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Redux

const SingleCallOfRegistrationDetails = () => {
  const dispatch = useAppDispatch();

  const { callOfRegistrationId } = useParams();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const singleCallOfRegistrationData = useAppSelector(
    (state) => state.callOfRegistration.singleCallOfRegistrationDetails
  );

  const isFullRendered = useContext(IsFullRenderdContext);
  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (!isFullRendered) return;
    if (
      !permissionChecker(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Call_Of_Registration_Manager",
        ],
        isFullRendered
      )
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllDetailsForSingleCallOfRegistration({
          userToken,
          callOfRegistrationId: String(callOfRegistrationId),
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, callOfRegistrationId, isFullRendered, allGroups]);

  useEffect(() => {
    return () => {
      dispatch(
        callOfRegistrationSlice.actions.resetRecevedDetailsOfSingleCallOfRegistration()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.singleCallOfRegistrationDetailContainer}  ${
        isNightOn ? "nightBg1" : ""
      } `}
    >
      {singleCallOfRegistrationData.isPending &&
      !singleCallOfRegistrationData.isError ? (
        <div
          className={`${
            styles.loadingContainer
          } w-100 d-flex flex-column align-items-center justify-content-center mt-4 ${
            isNightOn ? "nightBg1" : ""
          }`}
        >
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : null}

      {!singleCallOfRegistrationData.isPending &&
      !singleCallOfRegistrationData.isError ? (
        <>
          <KeyValuePairShow
            _key={{
              title: "شمارنده",
              className: styles.keyClassName,
            }}
            _value={{
              title: String(singleCallOfRegistrationData.data.id),
              className: styles.valueClassName,
            }}
            index="odd"
          />
          <KeyValuePairShow
            _key={{
              title: "نام",
              className: styles.keyClassName,
            }}
            _value={{
              title: singleCallOfRegistrationData.data.title,
              className: styles.valueClassName,
            }}
            index="even"
          />
          <KeyValuePairShow
            _key={{
              title: "توضیحات",
              className: styles.keyClassName,
            }}
            _value={{
              title: singleCallOfRegistrationData.data.description,
              className: styles.valueClassName,
            }}
            index="odd"
          />
          <KeyValuePairShow
            _key={{
              title: "وضعیت",
              className: styles.keyClassName,
            }}
            _value={{
              title: singleCallOfRegistrationData.data.active
                ? "فعال"
                : "غیرفعال",
              className: styles.valueClassName,
            }}
            index="even"
          />
          <KeyValuePairShow
            _key={{
              title: "شروع",
              className: styles.keyClassName,
            }}
            _value={{
              title: singleCallOfRegistrationData.data.startTime.jalaliDate,
              className: styles.valueClassName,
            }}
            index="odd"
          />
          <KeyValuePairShow
            _key={{
              title: "پایان",
              className: styles.keyClassName,
            }}
            _value={{
              title: singleCallOfRegistrationData.data.endTime.jalaliDate,
              className: styles.valueClassName,
            }}
            index="even"
          />

          <p
            className={`${
              styles.connectedGroups
            } w-100 d-flex flex-row align-items-center justify-content-end mt-5 ${
              isNightOn ? "nightBg1" : ""
            }`}
          >
            گروه های متصل
          </p>

          {singleCallOfRegistrationData.data.groups.map((item, index) => (
            <KeyValuePairShow
              _key={{
                title: `گروه شماره ${index + 1}`,
                className: styles.keyClassName,
              }}
              _value={{
                title: item.title,
                className: styles.valueClassName,
              }}
              index={index % 2 === 0 ? "even" : "odd"}
            />
          ))}
        </>
      ) : null}

      {!singleCallOfRegistrationData.isPending &&
      singleCallOfRegistrationData.isError ? (
        <div
          className={` w-100 d-flex flex-column align-items-center justify-content-center mt-4 ${
            isNightOn ? "nightBg1" : ""
          }`}
        >
          <button
            onClick={() => {
              dispatch(
                getAsyncAllDetailsForSingleCallOfRegistration({
                  userToken,
                  callOfRegistrationId: String(callOfRegistrationId),
                })
              );
            }}
          >
            تلاش دوباره
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default SingleCallOfRegistrationDetails;
