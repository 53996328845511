// React
import React from "react";
// React

// CSS
import styles from "./AddTenant.module.css";
// CSS

const AddTenant = () => {
  return (
    <div className={`${styles.addNewTenantContainer}`}>
      😉 به محض تکمیل سرویس افزودن سازمان ، در دسترس فزار میگیرد
    </div>
  );
};

export default AddTenant;
