// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Profile.module.css";
// CSS

// Models
import { I_UserData } from "../../Models/interfaces";
// Models

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import {
  loginSlice,
  postAsyncLogOutCookie,
} from "../../Features/LoginSlice/loginSlice";
// Redux

// Components
import PersonTab from "./PersonTab/PersonTab";
import GroupTab from "./GroupTab/GroupTab";
// import AccessibleGroups from "./AccessibleGroups/AccessibleGroups";
// Components

// Contexts
import { SetUserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import Swal from "sweetalert2";
// Utils

const Profile = () => {
  const dispatch = useAppDispatch();

  const setUserGroups = useContext(SetUserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const userInformation = useAppSelector((state) => state.userInformation);

  const [userDataForOverWrite, setUserDataForOverWrite] =
    useState<I_UserData>(userInformation);
  const [selectedTab, setSelectedTab] = useState<
    "person" | "groups" | "accessibleGroups"

    // | "identityAttributeForProfile"
  >("person");
  const [selectedInnerPersonTab, setSelectedInnerPersonTab] = useState<
    "userInformation" | "password"
  >("userInformation");
  const [passwordData, setPasswordData] = useState<{
    oldPassword: string;
    newPassword: string;
  }>({
    oldPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("پروفایل"));
  }, [dispatch]);

  useEffect(() => {
    setUserDataForOverWrite(userInformation);
  }, [userInformation]);

  return (
    <div
      className={`d-flex flex-column align-align-items-center   ${styles.profileContainer} `}
    >
      {/* Tab Selector  */}
      <div
        className={`d-flex flex-row-reverse align-align-items-center justify-content-center mb-5 p-2 ${
          styles.topButtonsContainer
        } ${isNightOn ? "nightBg2" : ""}`}
      >
        <button
          className={` p-2 ${styles.topButtons} ${
            isNightOn ? ` nightBg1` : ""
          }`}
          style={{
            boxShadow:
              selectedTab === "person"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
            border:
              selectedTab === "person" && isNightOn ? "1px solid #fff" : "",
          }}
          onClick={() => {
            setSelectedTab("person");
          }}
        >
          مشخصات کاربر
        </button>
        <button
          className={` p-2 ${isNightOn ? "nightBg1" : ""}`}
          onClick={() => {
            setSelectedTab("groups");
          }}
          style={{
            boxShadow:
              selectedTab === "groups"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
            border:
              selectedTab === "groups" && isNightOn ? "1px solid #fff" : "",
          }}
        >
          مشاهده گروه های تخصیص داده شده
        </button>
        {/* <button
          className={` p-2 ${isNightOn ? "nightBg1" : ""}`}
          onClick={() => {
            setSelectedTab("identityAttributeForProfile");
          }}
          style={{
            boxShadow:
              selectedTab === "identityAttributeForProfile"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
        >
          فیلد های هویتی پروفایل
        </button> */}
        {/* <button
          className={` p-2 `}
          onClick={() => {
            setSelectedTab("accessibleGroups");
          }}
          style={{
            boxShadow:
              selectedTab === "accessibleGroups"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
        >
          مشاهده گروه های قابل تخصیص به دیگران
        </button> */}
      </div>
      {/* Tab Selector  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      <div className={`${styles.containers} ${isNightOn ? "nightBg1" : ""}`}>
        {selectedTab === "person" ? (
          <PersonTab
            passwordData={passwordData}
            selectedInnerPersonTab={selectedInnerPersonTab}
            setPasswordData={setPasswordData}
            setSelectedInnerPersonTab={setSelectedInnerPersonTab}
            setUserDataForOverWrite={setUserDataForOverWrite}
            userDataForOverWrite={userDataForOverWrite}
          />
        ) : null}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {selectedTab === "groups" ? <GroupTab /> : null}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/* {selectedTab === "accessibleGroups" ? <AccessibleGroups /> : null} */}

        {/* {selectedTab === "identityAttributeForProfile" ? (
          <ProfileIdentitiesTab userDataForOverWrite={userDataForOverWrite} />
        ) : null} */}
      </div>
      <button
        className="operationEvent deleteBtn mt-4"
        onClick={() => {
          Swal.fire({
            text: "آیا میخواهید از سیستم خارج شوید ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#00A36C",
            confirmButtonText: "بله ، خروج",
            cancelButtonText: "خیر",
            customClass: isNightOn ? "swalNight" : "",
          }).then((result) => {
            if (result.isConfirmed) {
              customizedToast("در حال خروج شما از سیستم", "info");

              dispatch(
                postAsyncLogOutCookie({
                  onDone: () => {
                    setTimeout(() => {
                      setUserGroups([]);
                      dispatch(loginSlice.actions.logout());
                    }, 1000);
                  },
                })
              );
            }
          });
        }}
      >
        خروج
      </button>
    </div>
  );
};

export default Profile;
