// React
import React from "react";
// React

// CSS
import styles from "./CustomCheckbox.module.css";
import "./customCheckboxAnimStyles.css";
import { themeColor } from "../../Constants/constants";
// CSS

type CustomCheckboxProps = {
  isChecked: boolean;

  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClick: React.MouseEventHandler<HTMLInputElement>;

  title: string;

  checkboxStyles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;

  checkboxClassName?: string;
  titleClassName?: string;
  containerClassName?: string;

  disabled?: boolean;

  containerId?: string;
};

const CustomCheckbox: React.FunctionComponent<CustomCheckboxProps> = ({
  isChecked,
  onChange,
  onClick,
  title,
  checkboxClassName,
  checkboxStyles,
  containerClassName,
  containerStyles,
  titleClassName,
  titleStyles,
  containerId,
}) => {
  return (
    <div
      className={`${styles.customCheckboxContainer} d-flex flex-row align-items-center justify-content-end ${containerClassName}`}
      style={containerStyles}
      onClick={onClick}
      id={containerId}
    >
      {title && (
        <p className={`${styles.label} ${titleClassName}`} style={titleStyles}>
          {title}
        </p>
      )}

      <input
        type="checkbox"
        id="myCheckbox01"
        onChange={() => {}}
        checked={isChecked}
        style={checkboxStyles}
        className={`${styles.checkboxElement} checkbox__input ${checkboxClassName}`}
      />
      <svg
        className="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#757575"
          rx="3"
        />
        <path
          className="tick"
          stroke={themeColor}
          fill="none"
          strokeLinecap="round"
          strokeWidth="3"
          d="M4 10l5 5 9-9"
        />
      </svg>
    </div>
  );
};

export default CustomCheckbox;
