// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_SecurityEventRequestInterface } from "../../../Models/sendingDataSchema";
import { I_Message, I_SecurityEvent } from "../../../Models/interfaces";
// Models

export const getAllSecurityEventsService = (_data: {
  _data: I_SecurityEventRequestInterface;
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_SecurityEvent;
  }>
> => {
  return _axios.get(
    `/security-events?page=${_data._data.page}&size=${_data._data.size}&search=${_data._data.search}&from=${_data._data.from}&to=${_data._data.to}&type=${_data._data.type}&effect=${_data._data.effect}&risk=${_data._data.risk}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
