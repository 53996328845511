// Axios
import axios, { AxiosResponse } from "axios";
// Axios

// Models
import { I_Message } from "../../../Models/interfaces";
// Models

// Urls
import { baseUrl } from "../../urls";
// Urls

// Constants
import { isCookieMode } from "../../Base_Axios_Config/_axios";
// Constants

export const loginService = async (userData: {
  userName: string;
  password: string;
  cookieModeRememberMe: boolean;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      accessToken: string;
      refreshToken: string;
    };
  }>
> => {
  return axios.post(
    `${baseUrl}/authorization/login`,
    {
      username: userData.userName,
      password: userData.password,
      cookieMode: isCookieMode,
      cookieModeRememberMe: userData.cookieModeRememberMe,
    },
    {}
  );
};
