// React
import React from "react";
// React

type CDN_Props = {
  children: React.ReactNode;
};

const CDN: React.FunctionComponent<CDN_Props> = ({ children }) => {
  return <>{children}</>;
};

export default CDN;
