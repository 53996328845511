// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./ProcessStartModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncProcessesList,
  processesSlice,
  startAsyncSingleProcessInstance,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
// Redux

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Modules
import { permissionChecker } from "../../../Modules/permissionChecker";
// Modules

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
import { convertToPersianDateFromGeorgianDateInString } from "../../../Utils/FormioUtils/convertToPersianDateFromGeorgianDateInString";
import { resetReferenceOfObject } from "../../../Utils/resetReferenceOfObject";
// Utils

// DayJs
import dayJs from "dayjs";
import faLocale from "dayjs/locale/fa";
import jalaliday from "jalaliday";
// DayJs

dayJs.extend(jalaliday);

type ProcessStartModalProps = {
  processKey: string;
  tenant: string;
  persianName: string;

  formsData: {
    page: number;
    size: number;
    tenant: string;
    search: string;
    startable: boolean;
  };

  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;

  formIoData: any;
};

const ProcessStartModal: React.FunctionComponent<ProcessStartModalProps> = ({
  processKey,
  tenant,
  formsData,
  setIsModalInScreen,
  formIoData,
  persianName,
}) => {
  const dispatch = useAppDispatch();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [startProcessData, setStartProcessData] = useState<{
    businessKey: string;
    title: string;
  }>({
    businessKey: `${processKey}_businessKey_${dayJs()
      .calendar("jalali")
      .locale(faLocale)
      .format("YYYY-MM-DD")}`,
    title: `${persianName}_${dayJs()
      .calendar("jalali")
      .locale(faLocale)
      .format("YYYY-MM-DD")}`,
  });

  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const { isStartNewInstancePending } = useAppSelector(
    (state) => state.processes
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!outerContentRef.current) return;
      if (!innerContentRef.current) return;

      outerContentRef.current.style.opacity = "0.75";
      innerContentRef.current.style.top = "0px";
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetSendingFormStatus());
    };
  }, [dispatch]);

  const closeModal = () => {
    if (!outerContentRef.current) return;
    if (!innerContentRef.current) return;

    outerContentRef.current.style.opacity = "0";
    innerContentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  };

  const { processPage } = useAppSelector((state) => state.searchAndBack);

  return (
    <div
      className={`${styles.startFomModal}  w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.outerContent}`}
        ref={outerContentRef}
        onClick={closeModal}
      ></div>
      <div
        className={`${styles.innerContent}  ${isNightOn ? "nightBg1" : ""} `}
        ref={innerContentRef}
      >
        <div
          className={`${styles.inputsContainer}  w-100 d-flex flex-column align-items-center justify-content-between flex-wrap`}
        >
          <CustomInput1
            inputType="text"
            onBlur={() => {}}
            value={startProcessData.businessKey}
            onChange={(e) => {
              setStartProcessData((prevState) => ({
                ...prevState,
                businessKey: e.target.value,
              }));
            }}
            containerClassName=""
            placeHolder={"کدفرایند"}
            defaultValue={startProcessData.businessKey}
            //  keyForShowOnTop={`کدفرایند`}
            dontShowSearchIcon
            required
          />
          <CustomInput1
            inputType="text"
            onBlur={() => {}}
            value={startProcessData.title}
            onChange={(e) => {
              setStartProcessData((prevState) => ({
                ...prevState,
                title: e.target.value,
              }));
            }}
            containerClassName="mt-4"
            placeHolder={`عنوان فرآیند`}
            defaultValue={startProcessData.title}
            // keyForShowOnTop={`عنوان فرآیند`}
            dontShowSearchIcon
            required
          />
        </div>

        <div
          className={` w-100 d-flex flex-row align-items-cell-align-center justify-content-between  mt-4 `}
        >
          <button
            className={`${styles.startBtn} operationEvent`}
            onClick={() => {
              // if (
              //   !latinLetterWithoutSpaceFormat.test(
              //     startProcessData.businessKey
              //   ) ||
              //   startProcessData.businessKey.includes(" ")
              // ) {
              //   customizedToast("کد فرآیند باید لاتین و بدون فاصله باشد", "info");
              //   return;
              // }
              // if (startProcessData.title.length <= 4) {
              //   customizedToast("طول عنوان فرآیند کمتر از 5 کاراکتر است", "info");
              //   return;
              // }

              if (!Boolean(formIoData)) {
                dispatch(
                  startAsyncSingleProcessInstance({
                    userToken,
                    _data: {
                      processKey,
                      tenant,
                      _data: {
                        businessKey: startProcessData.businessKey,
                        title: startProcessData.title,
                        formData: {},
                      },
                    },
                  })
                )
                  .then(() => {
                    if (
                      !permissionChecker(
                        allGroups,
                        [
                          "Tenant_Full_Manager",
                          "Tenant_Basic_Manager",
                          "Process_Full_Manager",
                          "Process_Staff_Editor",
                          "Process_Editor",
                          "Process_Starter",
                          "Process_Schema_Analyzer",
                          "Process_Form_Editor",
                        ],
                        isFullRenderd
                      )
                    )
                      return;
                    dispatch(
                      getAsyncProcessesList({
                        _data: {
                          page: processPage.pageNumber,
                          search: "",
                          size: processPage.size,
                          startable: processPage.isStartable,
                          tenant,
                        },
                        userToken,
                      })
                    );
                  })
                  .then(() => {
                    closeModal();
                  });
                return;
              }

              dispatch(
                startAsyncSingleProcessInstance({
                  userToken,
                  _data: {
                    processKey,
                    tenant,
                    _data: {
                      businessKey: startProcessData.businessKey,
                      title: startProcessData.title,
                      formData: !!convertToPersianDateFromGeorgianDateInString(
                        resetReferenceOfObject(formIoData)
                      )
                        ? convertToPersianDateFromGeorgianDateInString(
                            resetReferenceOfObject(formIoData)
                          )
                        : formIoData,
                    },
                  },
                })
              )
                .then(() => {
                  dispatch(
                    getAsyncProcessesList({
                      _data: {
                        page: processPage.pageNumber,
                        search: "",
                        size: processPage.size,
                        startable: processPage.isStartable,
                        tenant,
                      },
                      userToken,
                    })
                  );
                })
                .then(() => {
                  closeModal();
                });
            }}
          >
            {isStartNewInstancePending ? (
              <Spinner />
            ) : (
              `
            شروع
            `
            )}
          </button>

          <button
            className={`${styles.cancelBtn} operationEvent  px-6`}
            onClick={closeModal}
          >
            انصراف
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProcessStartModal;
