// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../Models/interfaces";
import { I_NewIdentitySendingData } from "../../Models/sendingDataSchema";
// Models

export const addNewUserIdentityService = async <
  T extends I_NewIdentitySendingData
>(_data: {
  userToken: string;
  tenant: string;
  _data: T;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: T;
  }>
> => {
  return _axios.post(
    `/users/identity/attributes/add?tenant=${_data.tenant}`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
